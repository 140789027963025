import { Divider, Layout, Text, useTheme } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { FlatList, Platform, TouchableOpacity } from 'react-native';
import { Entypo, FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { BottomSheetMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useGetUserInfoQuery } from '../api/usersApi';
import { selectUserKey } from '../../app/appDataSlice';
import {
  useRemoveClassMutation,
  useRemoveStarredClassMutation
} from '../api/classPagesApi';
import { classSelected } from './classPagesSlice';

type StarredClassesProps = {
  setAddedClassesVisible: (visible: boolean) => void;
  courseTerm: string;
  darkMode: boolean;
  bottomSheetRef: React.MutableRefObject<BottomSheetMethods>;
};
export default function StarredClasses({
  setAddedClassesVisible,
  courseTerm,
  darkMode,
  bottomSheetRef
}: StarredClassesProps) {
  const isWeb = Platform.OS === 'web';
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const userKey = useAppSelector(selectUserKey);
  const [starredCourses, setStarredCourses] = useState([]);
  const { data: userData } = useGetUserInfoQuery(userKey, {
    skip: !userKey
  });
  const allClassesList = useAppSelector(
    (state) => state.appData.classPages.classes
  );
  const [removeStarredClass] = useRemoveStarredClassMutation();

  useEffect(() => {
    setStarredCourses(userData.starredClasses);
  }, [userData]);

  const handleRemoveStarredClass = (courseCode: string) => {
    try {
      removeStarredClass({
        userKey,
        courseCode,
        courseTerm,
        userName: userData.userName,
        masterCourseCode: courseCode.slice(0, -3)
      }).unwrap();
    } catch (e) {
      console.warn('Add class error: ', e);
    }
  };

  function handleClassSelected(courseCode: string) {
    dispatch(classSelected(courseCode));
    setAddedClassesVisible(false);
    if (Platform.OS === 'web') {
      navigation.navigate('SingleClassPage');
    } else {
      bottomSheetRef.current?.snapToIndex(0);
    }
  }

  function renderItem({ item: course }) {
    const courseData = allClassesList[course.courseCode.slice(0, -3)];
    return (
      <Layout
        key={course.courseCode}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingVertical: 7,
          backgroundColor: darkMode
            ? theme['color-basic-900']
            : theme['color-basic-100']
        }}
      >
        <Layout
          style={{ backgroundColor: 'transparent', flexDirection: 'row' }}
        >
          <Layout
            style={{
              backgroundColor: 'transparent',
              justifyContent: 'center',
              marginRight: isWeb ? 10 : 5
            }}
          >
            <FontAwesome name="star" color="gold" size={15} />
          </Layout>
          <Layout
            style={{ flexDirection: 'column', backgroundColor: 'transparent' }}
          >
            <TouchableOpacity
              onPress={() => {
                handleClassSelected(course.courseCode.slice(0, -3));
              }}
            >
              <Text category="s1">{courseData.courseName}</Text>
              <Text category="p2">{course.courseCode}</Text>
            </TouchableOpacity>
          </Layout>
        </Layout>

        <TouchableOpacity
          onPress={() => handleRemoveStarredClass(course.courseCode)}
        >
          <Entypo name="cross" size={28} color="red" />
        </TouchableOpacity>
      </Layout>
    );
  }

  return (
    <Layout
      style={{
        flex: Platform.OS === 'web' ? undefined : 1,
        paddingVertical: 10,
        backgroundColor: darkMode
          ? theme['color-basic-900']
          : theme['color-basic-100']
      }}
    >
      {starredCourses.length > 0 && (
        <Text category="h5" style={{ textAlign: 'center', marginBottom: 10 }}>
          Starred Classes
        </Text>
      )}
      <FlatList
        contentContainerStyle={{ width: 300 }}
        automaticallyAdjustsScrollIndicatorInsets
        indicatorStyle={darkMode ? 'white' : 'black'}
        showsVerticalScrollIndicator
        ItemSeparatorComponent={() => <Divider />}
        data={
          starredCourses.length > 0
            ? starredCourses.filter(
                (course) => course.courseTerm === courseTerm
              )
            : []
        }
        renderItem={renderItem}
      />
    </Layout>
  );
}
