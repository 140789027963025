import {
  CheckBox,
  IndexPath,
  Input,
  Layout,
  Select,
  SelectItem,
  Spinner,
  Text
} from '@ui-kitten/components';
import React, { useRef, useState } from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity
} from 'react-native';

import { useAppDispatch } from '../../../app/hooks';
import { isValidEmail, showToast } from '../../../functions';
import {
  useGetCollegeListQuery,
  useSignupUserMutation
} from '../../api/usersApi';

function Signup({ navigation }) {
  const [realFirstName, setRealFirstName] = useState('');
  const [realLastName, setRealLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [major, setMajor] = useState('');
  const dispatch = useAppDispatch();
  const [selectedIndex, setSelectedIndex] = useState(new IndexPath(0));
  const [simplifiedSignup, setSimplifiedSignUp] = useState(false);
  const [userCollege, setUserCollege] = useState({
    collegeName: '',
    humanReadableCollegeName: null
  });

  const { data: collegeList } = useGetCollegeListQuery();
  const [signupUser, { isLoading: signupLoading }] = useSignupUserMutation();

  const firstNameInputRef = useRef<Input>();
  const lastNameInputRef = useRef<Input>();
  const passwordInputRef = useRef<Input>();
  const majorInputRef = useRef<Input>();

  const validateInput = () => {
    if (!simplifiedSignup) {
      if (!realFirstName) {
        alert('Please fill First Name');
        return false;
      }
      if (!realLastName) {
        alert('Please fill Last Name');
        return false;
      }

      if (!userCollege.collegeName) {
        alert('Please fill College Name');
        return false;
      }

      if (!major) {
        alert('Please fill Major');
        return false;
      }
    }

    if (!email) {
      alert('Please fill Email');
      return false;
    }

    if (!isValidEmail(email)) {
      alert('Please enter a valid email');
      return false;
    }

    if (!password) {
      alert('Please fill Password');
      return false;
    }

    return true;
  };

  const handleSubmitButton = async () => {
    if (validateInput()) {
      try {
        const body = {
          email,
          password,
          realFirstName,
          realLastName,
          collegeName: userCollege.collegeName,
          major
        };

        // Uncomment this stuff when we get the email working again
        await signupUser(body).unwrap();
        navigation.navigate('VerifyEmail');
      } catch (e) {
        if (e?.data?.message) {
          showToast(`Signup failed. ${e.data.message}`, 'red');
        } else {
          showToast('Signup failed', 'red');
        }
        console.warn('Signup error: ', e);
      }
    }
  };

  const renderCollegeDropDown = () => {
    function handleSelection(index) {
      setSelectedIndex(index);
      const newObject = {
        collegeName: collegeList[index.row].id,
        humanReadableCollegeName: collegeList[index.row].label
      };
      setUserCollege(newObject);
    }

    return (
      <Layout style={styles.containerDrop}>
        <Select
          placeholder="Choose A College"
          value={
            !userCollege.humanReadableCollegeName
              ? 'Selection'
              : userCollege.humanReadableCollegeName
          }
          onFocus={Keyboard.dismiss}
          selectedIndex={selectedIndex}
          onSelect={handleSelection}
        >
          {collegeList &&
            collegeList.map((college, index) => (
              <SelectItem title={college.label} key={index} />
            ))}
        </Select>
      </Layout>
    );
  };

  const fields = [];
  fields.push(
    <Layout key="1">
      <Layout style={styles.SectionStyle}>
        <Layout style={styles.labelSection}>
          <Text style={styles.label}>Email</Text>
        </Layout>
        <Input
          style={styles.inputStyle}
          onChangeText={(email) => setEmail(email)}
          underlineColorAndroid="#f000"
          placeholder="Enter Email"
          placeholderTextColor="#8b9cb5"
          defaultValue=""
          autoCapitalize="none"
          returnKeyType="next"
          onSubmitEditing={() =>
            passwordInputRef.current ? passwordInputRef.current.focus() : null
          }
          blurOnSubmit={false}
        />
      </Layout>
      <Layout style={styles.SectionStyle}>
        <Layout style={styles.labelSection}>
          <Text style={styles.label}>Password</Text>
        </Layout>
        <Input
          style={styles.inputStyle}
          onChangeText={(password) => setPassword(password)}
          underlineColorAndroid="#f000"
          placeholder="Enter Password"
          placeholderTextColor="#8b9cb5"
          defaultValue=""
          ref={passwordInputRef}
          returnKeyType="next"
          secureTextEntry
          onSubmitEditing={() =>
            firstNameInputRef.current ? firstNameInputRef.current.focus() : null
          }
          blurOnSubmit={false}
        />
      </Layout>
    </Layout>
  );

  if (!simplifiedSignup) {
    fields.push(
      <Layout key="2">
        <Layout style={styles.SectionStyle}>
          <Layout style={styles.labelSection}>
            <Text style={styles.label}>First Name</Text>
          </Layout>
          <Input
            style={styles.inputStyle}
            onChangeText={setRealFirstName}
            ref={firstNameInputRef}
            underlineColorAndroid="#f000"
            placeholder="Enter First Name"
            placeholderTextColor="#8b9cb5"
            defaultValue=""
            autoCapitalize="sentences"
            returnKeyType="next"
            onSubmitEditing={() =>
              lastNameInputRef?.current
                ? lastNameInputRef.current.focus()
                : null
            }
            blurOnSubmit={false}
          />
        </Layout>

        <Layout style={styles.SectionStyle}>
          <Layout style={styles.labelSection}>
            <Text style={styles.label}>Last Name</Text>
          </Layout>
          <Input
            style={styles.inputStyle}
            onChangeText={setRealLastName}
            underlineColorAndroid="#f000"
            placeholder="Enter Last Name"
            placeholderTextColor="#8b9cb5"
            defaultValue=""
            ref={lastNameInputRef}
            autoCapitalize="sentences"
            returnKeyType="next"
            onSubmitEditing={() =>
              majorInputRef.current ? majorInputRef.current.focus() : null
            }
            blurOnSubmit={false}
          />
        </Layout>

        <Layout style={styles.SectionStyle}>
          <Layout style={styles.labelSection}>
            <Text style={styles.label}>Intended Major</Text>
          </Layout>
          <Input
            style={styles.inputStyle}
            onChangeText={setMajor}
            underlineColorAndroid="#f000"
            placeholder="Enter Intended Major"
            placeholderTextColor="#8b9cb5"
            defaultValue=""
            ref={majorInputRef}
            blurOnSubmit
          />
        </Layout>

        <Layout style={styles.SectionStyle}>
          <Layout style={styles.labelSection}>
            <Text style={styles.label}>College Name</Text>
          </Layout>
          {renderCollegeDropDown()}
        </Layout>
      </Layout>
    );
  }

  return (
    // Registration Page
    <Layout style={{ flex: 1 }}>
      <ScrollView style={{ flex: 1 }}>
        {/* Spinner */}
        {signupLoading && (
          <Layout
            style={{
              flex: 1,
              position: 'absolute',
              zIndex: 1,
              top: '50%',
              left: '50%',
              backgroundColor: 'transparent'
            }}
          >
            <Spinner />
          </Layout>
        )}

        <Layout style={{ paddingVertical: 30, paddingHorizontal: 40 }}>
          <CheckBox
            checked={simplifiedSignup}
            onChange={(nextChecked) => setSimplifiedSignUp(nextChecked)}
          >
            Simplified Signup
          </CheckBox>
        </Layout>

        <KeyboardAvoidingView
          style={{ flex: 1 }}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        >
          {fields}

          <TouchableOpacity
            style={styles.buttonStyle}
            onPress={handleSubmitButton}
          >
            <Text style={styles.buttonTextStyle}>Register</Text>
          </TouchableOpacity>
        </KeyboardAvoidingView>
      </ScrollView>
    </Layout>
  );
}
export default Signup;

const styles = StyleSheet.create({
  SectionStyle: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10
  },
  buttonStyle: {
    backgroundColor: '#b42bba',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#7DE24E',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 20
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16
  },
  inputStyle: {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#dadae8'
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14
  },
  successTextStyle: {
    color: 'black',
    textAlign: 'center',
    fontSize: 18,
    padding: 30
  },
  label: {
    textAlign: 'center',
    fontSize: 15,
    paddingLeft: 10,
    paddingRight: 10
  },
  labelSection: {
    justifyContent: 'center'
  },
  container: {
    flex: 1,
    alignItems: 'center'
  },

  text: {
    fontSize: 20,
    margin: 20
  },
  input: {
    width: '80%',
    margin: 20,
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 20,
    height: 40,
    padding: 10
  },
  button: {
    width: '80%',
    margin: 20,
    alignItems: 'center',
    backgroundColor: '#b42bba',
    height: 40,
    borderRadius: 20,
    borderColor: '#b42bba',
    justifyContent: 'center'
  },
  buttonText: {
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white'
  },
  containerDrop: {
    minHeight: 128,
    minWidth: 200
  }
});
