import { FontAwesome5 } from '@expo/vector-icons';
import { Layout, Text } from '@ui-kitten/components';
import { useMemo } from 'react';
import {
  LayoutAnimation,
  Platform,
  StyleSheet,
  TouchableOpacity,
  UIManager
} from 'react-native';

import ClassListItemItem from './ClassListItemItem';

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export default function Item({
  courseName,
  masterCourseCode,
  schedule,
  menuOpen,
  setIsMenuOpen,
  darkMode,
  handleClassSelected,
  handleAddClass,
  handleRemoveClass,
  inItem,
  isMobile
}) {
  const memoizedItem = useMemo(() => {
    return (
      <Layout
        style={[
          {
            borderRadius: 20,
            opacity: 1,
            marginTop: 7,
            marginHorizontal: 9
          },
          !isMobile && { marginTop: 2 }
        ]}
      >
        {/* Name and master coursecode */}
        <Layout style={{ marginHorizontal: 20 }}>
          <Layout>
            <TouchableOpacity
              onPress={() => {
                handleClassSelected(masterCourseCode);
              }}
            >
              <Layout style={isMobile ? styles.item : styles.itemWeb}>
                <Text
                  style={[
                    styles.title,
                    !isMobile && { fontSize: 12, marginBottom: 0 }
                  ]}
                >
                  {courseName}
                </Text>
                <Text style={!isMobile && { fontSize: 12 }}>
                  {masterCourseCode}
                </Text>
              </Layout>
            </TouchableOpacity>

            {/* Dropdown Menu Toggle */}
            <TouchableOpacity
              style={styles.buttonContainer}
              onPress={() => {
                if (menuOpen === masterCourseCode) {
                  setIsMenuOpen('');
                  LayoutAnimation.configureNext({
                    duration: 730,
                    create: { type: 'easeInEaseOut', property: 'opacity' },
                    update: { type: 'spring', springDamping: 0.8 }
                  });
                } else {
                  setIsMenuOpen(masterCourseCode);
                  LayoutAnimation.configureNext({
                    duration: Platform.OS === 'android' ? 500 : 730,
                    create: { type: 'easeInEaseOut', property: 'opacity' },
                    update: { type: 'spring', springDamping: 0.8 }
                  });
                }
              }}
            >
              <Layout style={styles.button}>
                <FontAwesome5
                  name={
                    menuOpen === masterCourseCode
                      ? 'chevron-up'
                      : 'chevron-down'
                  }
                  size={20}
                  color={darkMode ? 'white' : 'black'}
                />
              </Layout>
            </TouchableOpacity>
          </Layout>

          {/* Dropdown Menu */}
          {menuOpen === masterCourseCode && (
            <Layout>
              {schedule.map(
                ({ _id, courseCode, seatsAvailable, seatsFilled, added }) => (
                  <ClassListItemItem
                    key={_id}
                    courseCode={courseCode}
                    seatsAvailable={seatsAvailable}
                    seatsFilled={seatsFilled}
                    handleAddClass={handleAddClass}
                    handleRemoveClass={handleRemoveClass}
                    darkMode={darkMode}
                    added={added}
                    masterCourseCode={masterCourseCode}
                    handleClassSelected={handleClassSelected}
                  />
                )
              )}
            </Layout>
          )}
        </Layout>
      </Layout>
    );
  }, [menuOpen, inItem, darkMode]);

  // useTraceUpdate(memoizedItem);
  return memoizedItem;
}

const styles = StyleSheet.create({
  list__container: {
    margin: 10,
    marginLeft: 0,
    height: '90%',
    width: '100%'
  },
  addedClassesContainer: {
    position: 'absolute',
    zIndex: 1,
    width: '60%',
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    borderColor: '#c2c2c2',
    backgroundColor: '#f7f7f7',
    paddingTop: 5,
    borderTopWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRightWidth: 1.5
  },
  addedClasses: {
    position: 'absolute',
    right: 3,
    top: 3,
    padding: 7,
    margin: 5,
    backgroundColor: '#c9c9c9',
    borderRadius: 100
  },
  item: {
    marginVertical: 10
  },
  itemWeb: {
    marginVertical: 5
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5,
    marginRight: 30
  },
  buttonContainer: {
    right: 0,
    position: 'absolute',
    width: 50,
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '100%'
  },
  button: {
    backgroundColor: 'transparent',
    padding: 5,
    borderColor: 'grey'
  },
  dropDown: {
    backgroundColor: '#f7f7f7',
    paddingLeft: 30,
    paddingBottom: 10,
    paddingTop: 10
  },
  dropDownItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 54
  },
  isAvailable: {
    color: 'green'
  },
  notAvailable: {
    color: 'red'
  },
  loadingMessage: {
    fontSize: 20,
    color: 'white',
    alignSelf: 'center',
    fontStyle: 'italic'
  },
  loadingMessageRefresh: {
    fontSize: 15,
    paddingTop: 20,
    color: 'white',
    alignSelf: 'center',
    fontWeight: 'bold'
  },
  addClassButton: {
    padding: 15
  },
  filters: {
    backgroundColor: 'white',
    alignItems: 'center',
    padding: 10
  }
});
