import Loader from 'react-native-three-dots-loader';
import { FontAwesome5, Ionicons } from '@expo/vector-icons';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { Layout, Text } from '@ui-kitten/components';
import * as ImagePicker from 'expo-image-picker';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Dimensions,
  FlatList,
  Image,
  Keyboard,
  KeyboardAvoidingView,
  LogBox,
  Platform,
  SafeAreaView,
  ScrollView,
  TextInput,
  TouchableOpacity,
  View
} from 'react-native';
import Toast from 'react-native-root-toast';
import { io } from 'socket.io-client';
import { selectUserKey } from '../../../app/appDataSlice';

import { useAppSelector } from '../../../app/hooks';
import SearchBar from '../../../components/SearchBar';
import { base_URL } from '../../../constants/admin';
import { useGetCurrentSectionQuery } from '../../api/classPagesApi';
import {
  useAddOrRemoveUserFromChatRoomMutation,
  useEditUserMutation,
  useGetUserInfoQuery
} from '../../api/usersApi';
import ModalContainer from '../../../components/ModalContainer';

export default function SingleChatRoom({
  route: {
    params: { data }
  }
}) {
  const [imagePickerStatus, requestImagePickerPermission] =
    ImagePicker.useMediaLibraryPermissions();
  const [cameraStatus, requestCameraPermission] =
    ImagePicker.useCameraPermissions();
  const [filter, setFilter] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [photoSelected, setPhotoSelected] = useState({
    date: null,
    id: null,
    contentImage: null
  });
  const [addOrRemoveUser] = useAddOrRemoveUserFromChatRoomMutation();
  const [editUser] = useEditUserMutation();
  const isCustomRoom = data.userList !== undefined;
  const [keyboardShown, setKeyboardShown] = useState(false);
  const [retryNumber, setRetryNumber] = useState(0);
  const [failedConnection, setFailedConnection] = useState(false);
  const [hardDisableSend, setHardDisableSend] = useState(false);
  const navigation = useNavigation();
  const [showUserList, setShowUserList] = useState(false);
  const [message, setMessage] = useState('');
  const [filteredMessageList, setFilteredMessageList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const userKey = useAppSelector(selectUserKey);
  // const { data: userData } = useGetUserInfoQuery(userKey, {
  //   skip: !userKey
  // });
  const [showDeletion, setShowDeletion] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);
  const { data: userData } = useGetUserInfoQuery(userKey, {
    skip: !userKey
  });
  const { data: currentSection, isLoading: isCurrentSectionLoading } =
    useGetCurrentSectionQuery();
  const [messageList, setMessageList] = useState([]);
  const socket = io(`${base_URL}/realTimeChat/${data.id}`, {
    auth: {
      userKey
    }
  });

  useEffect(() => {
    // Bug with some component i guess cause i didn't use animated anything, yet expo has a seizure and spams warnings
    LogBox.ignoreLogs(['Animated: `useNativeDriver`']);

    // Keyboard listeners
    const showListener = Keyboard.addListener('keyboardWillShow', () => {
      setKeyboardShown(true);
    });
    const hideListener = Keyboard.addListener('keyboardWillHide', () => {
      setKeyboardShown(false);
    });

    return () => {
      showListener.remove();
      hideListener.remove();
    };
  }, []);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: data.title
        ? data.title
        : data.id === 'public'
        ? 'Public'
        : data.id === 'admin'
        ? 'Admin'
        : data.id,
      headerRight: () => (
        <Layout style={{ flexDirection: 'row', marginRight: 30 }}>
          <TouchableOpacity
            style={{ marginRight: isCustomRoom ? 25 : undefined }}
            onPress={() => {
              setFilter('');
              setShowSearch(!showSearch);
            }}
          >
            <FontAwesome5 name="search" size={24} color="black" />
          </TouchableOpacity>
          {isCustomRoom && (
            <TouchableOpacity
              onPress={() => {
                setShowUserList(true);
              }}
            >
              <Ionicons name="person" size={24} color="black" />
            </TouchableOpacity>
          )}
        </Layout>
      )
    });
  }, [showSearch]);

  useEffect(() => {
    // maintenance

    // socket logic
    socket.connect();
    const timer = setTimeout(() => {
      socket.off('connect');
      socket.off('newMessage');
      socket.disconnect();
      socket.close();
      setFailedConnection(true);
    }, 10000);
    socket.on('connect', () => {
      clearTimeout(timer);
      getMessages();
    });
    return () => {
      setLoaded(false);
      socket.disconnect();
      socket.off('connect');
      socket.off('newMessage');
    };
  }, [useIsFocused(), retryNumber]);

  // Add all new message changes here
  useEffect(() => {
    socket.on('imageMessageServer', (msg) => {
      addMessage(msg);
    });
    socket.on('newMessage', (msg) => {
      addMessage(msg);
    });
    socket.on('deleteMessage', (msg) => {
      const index = messageList.findIndex((mess) => isEqualTo(mess, msg));
      if (index !== -1) {
        // @ts-ignore
        setMessageList([...messageList].toSpliced(index, 1));
      }
    });
    return () => {
      socket.off('imageMessageServer');
      socket.off('newMessage');
      socket.off('deleteMessage');
    };
  }, [messageList]);

  function getMessages() {
    fetch(`${base_URL}/chatRooms/getMessages?roomId=${data.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        userKey
      }
    }).then(async (data) => {
      if (!data.ok) {
        setFailedConnection(true);
      } else {
        data.json().then(async (array) => {
          setMessageList(array);
          setLoaded(true);
        });
      }
    });
  }

  function isUserBlocked(userName: string, dataToUse = userData) {
    return dataToUse.blockedUsers.includes(userName);
  }

  useEffect(() => {
    setFilteredMessageList(
      messageList.filter((mess) =>
        (mess.user + mess.message).toLowerCase().includes(filter.toLowerCase())
      )
    );
  }, [filter]);

  function addMessage(messageToAdd) {
    const myMessage = messageList.find((mess) => isEqualTo(mess, messageToAdd));
    if (myMessage) {
      delete myMessage.isUnsent;
      setMessageList(
        [...messageList]
          .filter((mess) => !isEqualTo(mess, messageToAdd))
          .concat(myMessage)
      );
    } else {
      setMessageList([...messageList].concat(messageToAdd));
    }
  }

  function isEqualTo(message1, message2) {
    try {
      return message1.user === message2.user && message1.date === message2.date;
    } catch (e) {
      return false;
    }
  }

  async function activateCamera() {
    const date = new Date().toISOString();
    const response = await requestCameraPermission();
    if (response.granted || cameraStatus.granted) {
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images
      });
      if (!result.canceled) {
        setMessage('');
        setPhotoSelected({
          id: userData.userName + date + data.id + currentSection,
          contentImage: result.assets[0],
          date
        });
      }
    } else {
      Alert.alert(
        "Permission to access camera is required. Please enable it in your phone's settings."
      );
    }
  }

  async function selectPhoto() {
    const date = new Date().toISOString();
    const response = await requestImagePickerPermission();
    if (response.granted || imagePickerStatus.granted) {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: false
      });
      if (!result.canceled) {
        setMessage('');
        setPhotoSelected({
          id: userData.userName + date + data.id + currentSection,
          contentImage: result.assets[0],
          date
        });
      }
    } else {
      Alert.alert(
        "Permission to access camera roll is required. Please enable it in your phone's settings."
      );
    }
  }

  function deleteTheMessage() {
    const messageToDelete = itemToRemove;
    setShowDeletion(false);
    if (messageToDelete.user === userData.userName) {
      socket.emit('deleteMyMessage', messageToDelete);
      setMessageList(
        messageList.filter((mess) => !isEqualTo(mess, messageToDelete))
      );
      Toast.show('Message Deleted', {
        duration: Toast.durations.SHORT,
        opacity: 0.7,
        position: Toast.positions.CENTER,
        backgroundColor: 'grey',
        shadow: false
      });
    }
  }

  async function sendNewMessage() {
    setHardDisableSend(true); // basically a semaphore lol
    if (message !== '') {
      const newMessage = {
        message,
        date: new Date().toISOString(),
        user: userData.userName,
        isUnsent: true
      };
      setMessageList([...messageList].concat(newMessage));
      delete newMessage.isUnsent;
      socket.emit('newMessageClient', newMessage);
      setMessage('');
    }
    if (photoSelected.id !== null) {
      const { date } = photoSelected;
      setMessageList(
        [...messageList].concat({
          isImage: true,
          isUnsent: true,
          message: photoSelected.contentImage.uri,
          user: userData.userName,
          date
        })
      );

      const photo = photoSelected;
      setPhotoSelected({ date: null, id: null, contentImage: null });

      const formdata = new FormData();
      formdata.append(
        'picture',
        JSON.stringify({
          // @ts-ignore
          uri: photo.contentImage.uri,
          name: 'picture',
          type: 'image/jpg'
        }).substring(1, -1)
      );
      formdata.append('id', photo.id);
      formdata.append('date', date);
      const res = await fetch(`${base_URL}/chatRooms/uploadImage`, {
        method: 'POST',
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json',
          userKey
        },
        body: formdata
      });
      if (res.ok) {
        const newMessage = {
          date,
          user: userData.userName,
          message: photo.id
        };
        socket.emit('imageMessageClient', newMessage);
      }
    }
    setHardDisableSend(false);
  }

  function handleLeaveChat() {
    addOrRemoveUser({
      userKey,
      chatRoomId: data.id,
      userName: userData.userName
    })
      .then(() => navigation.goBack())
      .catch(() => {
        setShowUserList(false);
        Toast.show('Error leaving chat, try again later');
      });
  }

  const renderItem = ({ item }) => (
    <TouchableOpacity
      disabled={item?.isUnsent}
      onLongPress={() => {
        if (item.user === userData.userName) {
          setItemToRemove(item);
          setShowDeletion(true);
        }
      }}
      style={{
        opacity: item?.isUnsent ? 0.4 : 1,
        flexDirection: 'row',
        marginVertical: 5,
        minHeight: item.isImage ? windowHeight * 0.25 : undefined,
        width: '100%'
      }}
    >
      <ModalContainer
        children={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              width: '100%'
            }}
          >
            <Button
              onPress={() => setShowDeletion(false)}
              color="red"
              title="Cancel"
            />
            <Button
              onPress={() => deleteTheMessage()}
              color="blue"
              title="Remove"
            />
          </div>
        }
        backDropVisible={showDeletion}
        setBackDropVisible={setShowDeletion}
        title="Delete Message"
      />
      <Text style={{ paddingRight: 10, fontWeight: 'bold' }}>{item.user}:</Text>
      {item.isImage ? (
        <Image
          source={{ uri: item.message }}
          style={{ flex: 1, resizeMode: 'contain' }}
        />
      ) : (
        <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item.message}</Text>
      )}
    </TouchableOpacity>
  );
  const windowHeight = Dimensions.get('screen').height;

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <KeyboardAvoidingView style={{ flex: 1 }}>
        <View style={[styles.messagingScreen, { paddingHorizontal: 10 }]}>
          {isCustomRoom && (
            <ModalContainer
              backDropVisible={showUserList}
              setBackDropVisible={setShowUserList}
              title="Users"
              children={
                <ScrollView style={{ flex: 1, width: '80%' }}>
                  {data.userList.toSorted().map((userName) => (
                    <Layout
                      style={{
                        flexDirection: 'row',
                        marginBottom: 15,
                        justifyContent: 'space-between'
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 17
                        }}
                      >
                        {userName}
                      </Text>
                      <Layout style={{ flexDirection: 'row' }}>
                        {userName !== userData.userName && (
                          <TouchableOpacity
                            onPress={() => {
                              let body = {};
                              if (isUserBlocked(userName)) {
                                body = {
                                  unblockUser: userName
                                };
                              } else {
                                body = {
                                  blockUser: userName
                                };
                              }
                              editUser({
                                userKey,
                                userName: userData.userName,
                                fields: JSON.stringify(body)
                              })
                                .unwrap()
                                .then((response) => {
                                  const newUserData = response.userData;
                                  Toast.show(
                                    isUserBlocked(userName, newUserData)
                                      ? 'User Blocked'
                                      : 'User un-Blocked',
                                    {
                                      duration: Toast.durations.LONG,
                                      opacity: 0.7,
                                      position: Toast.positions.CENTER,
                                      backgroundColor: 'black',
                                      shadow: false
                                    }
                                  );
                                  setShowUserList(false);
                                })
                                .catch((error) => console.warn(error));
                            }}
                          >
                            {isUserBlocked(userName) ? (
                              <Ionicons
                                name="add-circle"
                                size={25}
                                color="black"
                              />
                            ) : (
                              <Ionicons
                                name="remove-circle"
                                size={25}
                                color="black"
                              />
                            )}
                          </TouchableOpacity>
                        )}
                      </Layout>
                    </Layout>
                  ))}
                  <Layout
                    style={{
                      backgroundColor: 'transparent',
                      alignContent: 'center'
                    }}
                  >
                    <Button title="Leave Chat" onPress={handleLeaveChat} />
                  </Layout>
                </ScrollView>
              }
            />
          )}
          {messageList.length > 0 ? (
            <View style={{ flex: 1 }}>
              {showSearch && (
                <SearchBar
                  searchTerm={filter}
                  onChangeText={setFilter}
                  inputProps={{ autoCorrect: false }}
                  placeholder="Search"
                />
              )}
              <FlatList
                style={{}}
                data={
                  filter === ''
                    ? [...messageList].reverse()
                    : [...filteredMessageList].reverse()
                }
                renderItem={renderItem}
                keyExtractor={(item) => item?.date + item?.user}
                inverted
              />
            </View>
          ) : failedConnection ? (
            <TouchableOpacity
              onPress={() => {
                setFailedConnection(false);
                setRetryNumber(retryNumber + 1);
              }}
            >
              <Text style={{ marginTop: '10%', textAlign: 'center' }}>
                Connection Failed
                {'\n'}
                Press to Reload
              </Text>
            </TouchableOpacity>
          ) : loaded ? (
            <Text style={{ textAlign: 'center' }}>No chats yet :/</Text>
          ) : (
            <View style={{ marginTop: '20%' }}>
              <Loader />
            </View>
          )}
        </View>

        <View style={styles.messagingInputContainer}>
          {!keyboardShown && (
            <TouchableOpacity
              onPress={activateCamera}
              disabled={
                hardDisableSend ||
                !loaded ||
                messageList.some((msg) => msg.isUnsent)
              }
              style={{
                alignSelf: 'center',
                paddingRight: 5
              }}
            >
              <FontAwesome5
                name="camera"
                size={20}
                color={
                  !hardDisableSend &&
                  loaded &&
                  !messageList.some((msg) => msg.isUnsent)
                    ? 'black'
                    : 'gray'
                }
              />
            </TouchableOpacity>
          )}
          {!photoSelected.contentImage ? (
            <View style={styles.messagingInput}>
              <TextInput
                placeholder="Message"
                placeholderTextColor="gray"
                editable={loaded}
                value={message}
                multiline
                onChangeText={(value) => setMessage(value)}
              />
            </View>
          ) : (
            <View
              style={{
                ...styles.messagingInput,
                paddingHorizontal: 10,
                height: 150
              }}
            >
              <Image
                source={{ uri: photoSelected.contentImage?.uri }}
                style={{ flex: 1, borderRadius: 10 }}
              />
              <TouchableOpacity
                onPress={() => {
                  setPhotoSelected({
                    id: null,
                    contentImage: null,
                    date: null
                  });
                }}
                style={{ position: 'absolute', marginLeft: 15, marginTop: 10 }}
              >
                <FontAwesome5
                  name="times"
                  size={25}
                  color="#ff2e2e"
                  style={{}}
                />
              </TouchableOpacity>
            </View>
          )}
          {!keyboardShown && Platform.OS !== 'web' && (
            <TouchableOpacity
              onPress={selectPhoto}
              disabled={
                hardDisableSend ||
                !loaded ||
                messageList.some((msg) => msg.isUnsent)
              }
              style={{
                width: '15%',
                alignSelf: 'center',
                paddingRight: 10,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <FontAwesome5
                name="image"
                size={30}
                color={
                  !hardDisableSend &&
                  loaded &&
                  !messageList.some((msg) => msg.isUnsent)
                    ? 'black'
                    : 'gray'
                }
              />
            </TouchableOpacity>
          )}
          <TouchableOpacity
            style={{
              ...styles.messagingButtonContainer,
              backgroundColor:
                !hardDisableSend &&
                loaded &&
                !messageList.some((msg) => msg.isUnsent)
                  ? '#a245ee'
                  : 'rgba(162,69,238,0.34)'
            }}
            onPress={sendNewMessage}
            disabled={
              hardDisableSend ||
              !loaded ||
              messageList.some((msg) => msg.isUnsent)
            }
          >
            <View>
              <Text style={{ color: '#f2f0f1', fontSize: 15 }}>Send</Text>
            </View>
          </TouchableOpacity>
        </View>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}

const styles = {
  messagingScreen: {
    flex: 1
  },
  messagingInputContainer: {
    width: '100%',
    backgroundColor: 'white',
    paddingVertical: 15,
    paddingHorizontal: 15,
    justifyContent: 'center',
    flexDirection: 'row'
  },
  messagingInput: {
    borderWidth: 1,
    padding: 10,
    paddingHorizontal: 15,
    flex: 1,
    marginHorizontal: 10,
    borderRadius: 20
  },
  messagingButtonContainer: {
    width: '20%',
    alignSelf: 'center',
    height: 40,
    borderRadius: 3,
    alignItems: 'center',
    justifyContent: 'center'
  }
};
