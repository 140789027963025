import React from 'react';
import { Dimensions, Text, TouchableOpacity } from 'react-native';
import Toast from 'react-native-root-toast';

import CalendarFunctions from './CalendarFunctions';

export default function renderCustomEvent(
  event,
  userData,
  userKey,
  editUser,
  setMenuEvent,
  disableLongPress: boolean = false,
  extraTransparency = false,
  dimensions: any
) {
  const universalHeight = (dimensions.height - 50) / 12;
  const minuteHeight = universalHeight / 60;
  const calendarFun = new CalendarFunctions();
  const [startHour, startMinute] = event.startTime.split(':');
  const [endHour, endMinute] = event.endTime.split(':');
  const color = calendarFun.stringToColour(event.name);

  function removeEvent(event: any) {
    editUser({
      userKey,
      userName: userData.userName,
      fields: JSON.stringify({
        removeCustomEvent: {
          name: event.name,
          startTime: event.startTime,
          endTime: event.endTime,
          days: event.days
        }
      })
    })
      .unwrap()
      .catch(() => {
        Toast.show('Failed to remove event.\nPlease try again later', {
          duration: Toast.durations.SHORT,
          opacity: 1,
          position: -100,
          backgroundColor: 'grey',
          shadow: false
        });
      });
  }

  return (
    <TouchableOpacity
      onLongPress={() => {
        if (!disableLongPress) {
          setMenuEvent({ key: event.name, event });
        }
        // Alert.alert(
        //   event.name,
        //   `${calendarFun.convertTo12HourTime(
        //     event.startTime
        //   )} - ${calendarFun.convertTo12HourTime(event.endTime)}`,
        //   [
        //     {
        //       text: 'Remove',
        //       onPress: () => {
        //         removeEvent(event);
        //       }
        //     },
        //     {
        //       text: 'Back',
        //       onPress: () => {},
        //       style: 'cancel'
        //     }
        //   ],
        //   { cancelable: true }
        // );
      }}
      key={event.name + event.days}
      style={{
        backgroundColor: extraTransparency ? 'rgba(0, 0, 0, 0.5)' : color,
        top: universalHeight * startHour + startMinute * minuteHeight,
        height:
          universalHeight * (endHour - startHour) +
          calendarFun.getMinutesDiff(startMinute, endMinute, minuteHeight),
        width: '100%',
        position: 'absolute',
        justifyContent: 'center'
      }}
    >
      <Text
        adjustsFontSizeToFit
        numberOfLines={endHour - startHour + 2}
        style={{ color: 'white', textAlign: 'center' }}
      >
        {event.name}
      </Text>
    </TouchableOpacity>
  );
}
