import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { Layout, Text } from '@ui-kitten/components';
import { useCallback, useRef } from 'react';
import { StyleSheet, Animated, TouchableOpacity, Platform } from 'react-native';
import { useAppSelector } from '../../app/hooks';
import {
  useAddStarredClassMutation,
  useRemoveStarredClassMutation
} from '../api/classPagesApi';
import { useGetUserInfoQuery } from '../api/usersApi';

export default function ClassListItemItem({
  courseCode,
  seatsAvailable,
  seatsFilled,
  handleAddClass,
  handleRemoveClass,
  darkMode,
  added,
  masterCourseCode,
  handleClassSelected
}) {
  const [removeStarredClass] = useRemoveStarredClassMutation();
  const [addStarredClass] = useAddStarredClassMutation();
  const userKey = useAppSelector((state) => state.appData.secure.userKey);
  const isMobile = useAppSelector((state) => state.appData.secure.isMobile);
  const { data: userData } = useGetUserInfoQuery(userKey, {
    skip: !userKey
  });
  const selectedAnim = useRef(new Animated.Value(1)).current;
  const currentSection = useAppSelector(
    (state) => state.appData.classPages.currentSection
  );

  const isStarred = useCallback(
    (courseCodeToSearch: string) => {
      return userData.starredClasses.some(
        (course) => course.courseCode === courseCodeToSearch
      );
    },
    [userData.starredClasses]
  );

  function toggleStarCourse(courseCode: string) {
    if (isStarred(courseCode)) {
      removeStarredClass({
        userKey,
        courseCode,
        courseTerm: currentSection,
        userName: userData.userName
      }).unwrap();
    } else {
      addStarredClass({
        userKey,
        courseCode,
        courseTerm: currentSection,
        userName: userData.userName
      }).unwrap();
    }
  }

  return (
    <TouchableOpacity
      style={{ flex: 1 }}
      onPress={() => {
        handleClassSelected(masterCourseCode);
      }}
    >
      <Layout style={styles.dropDownItem}>
        <Text style={[{ flex: 2 }, !isMobile && { fontSize: 12 }]}>
          {`\u2022 ${courseCode}`}
        </Text>
        <Text
          style={[
            seatsAvailable > 0 ? styles.isAvailable : styles.notAvailable,
            { flex: 1, textAlign: 'center' },
            !isMobile && { fontSize: 12 }
          ]}
        >
          {seatsFilled} /{seatsFilled + seatsAvailable}
        </Text>
        {/* Add / Remove Class */}
        <TouchableOpacity
          style={{ flex: 1 }}
          onPress={() => {
            if (added) {
              handleRemoveClass(courseCode, masterCourseCode);
            } else {
              handleAddClass(courseCode, masterCourseCode);
            }
          }}
        >
          <Animated.View
            style={{
              alignItems: 'flex-end',
              justifyContent: 'center',
              transform: [
                {
                  scale: selectedAnim
                }
              ]
            }}
          >
            <FontAwesome5
              style={{ padding: 5 }}
              size={24}
              name={added ? 'times' : 'plus'}
              color={darkMode ? 'white' : 'black'}
            />
          </Animated.View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            toggleStarCourse(courseCode);
          }}
          style={{ flex: 1, alignItems: 'flex-end' }}
        >
          <FontAwesome
            style={{ padding: 5 }}
            size={24}
            name={isStarred(courseCode) ? 'star' : 'star-o'}
            color={darkMode ? 'white' : 'black'}
          />
        </TouchableOpacity>
      </Layout>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  list__container: {
    margin: 10,
    marginLeft: 0,
    height: '90%',
    width: '100%'
  },
  addedClassesContainer: {
    position: 'absolute',
    zIndex: 1,
    width: '60%',
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    borderColor: '#c2c2c2',
    backgroundColor: '#f7f7f7',
    paddingTop: 5,
    borderTopWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRightWidth: 1.5
  },
  addedClasses: {
    position: 'absolute',
    right: 3,
    top: 3,
    padding: 7,
    margin: 5,
    backgroundColor: '#c9c9c9',
    borderRadius: 100
  },
  item: {
    marginBottom: 10,
    marginTop: 10
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5
  },
  buttonContainer: {
    right: 0,
    position: 'absolute',
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  button: {
    padding: 5,
    borderColor: 'grey'
  },
  dropDown: {
    backgroundColor: '#f7f7f7',
    paddingLeft: 30,
    paddingBottom: 10,
    paddingTop: 10
  },
  dropDownItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 54
  },
  isAvailable: {
    color: 'green'
  },
  notAvailable: {
    color: 'red'
  },
  loadingMessage: {
    fontSize: 20,
    color: 'white',
    alignSelf: 'center',
    fontStyle: 'italic'
  },
  loadingMessageRefresh: {
    fontSize: 15,
    paddingTop: 20,
    color: 'white',
    alignSelf: 'center',
    fontWeight: 'bold'
  },
  filters: {
    backgroundColor: 'white',
    alignItems: 'center',
    padding: 10
  }
});
