import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Card,
  Input,
  Layout,
  Text
} from '@ui-kitten/components';
import { useCallback, useEffect, useState } from 'react';
import { Keyboard, TouchableWithoutFeedback } from 'react-native';

import synonyms from './synonyms.json';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import WrapItems from '../../../components/WrapItems';
import { departments } from '../../../constants/electiveGenerator';
import { showToast } from '../../../functions';
import {
  electiveGeneratorSelected,
  selectClassesList,
  suggestedClassesUpdated
} from '../classPagesSlice';
import { MAX_WIDTH_WEB } from '../../../constants/discover';

export default function ElectiveGenerator({ navigation }: any) {
  const dispatch = useAppDispatch();
  const [departmentQuery, setDepartmentQuery] = useState('');
  const [departmentOptions, setDepartmentOptions] = useState(departments);
  const [description, setDescription] = useState('');

  const classes = useAppSelector(selectClassesList);

  const getClassesByDepartment = useCallback((department: string) => {
    if (department === 'All Departments') return Object.entries(classes);
    return Object.entries(classes).filter(([key, _]) => {
      const classDepartment = key.split(' ')[0];
      return classDepartment === department;
    });
  }, []);

  useEffect(() => {
    dispatch(electiveGeneratorSelected());
  }, []);

  const onChangeDepartmentText = useCallback((query: string): void => {
    setDepartmentQuery(query);
    setDepartmentOptions(
      departments.filter((department) =>
        department.includes(query.toUpperCase())
      )
    );
  }, []);

  const onSubmit = () => {
    Keyboard.dismiss();
    if (!departmentQuery || !description) {
      showToast('Please fill out all fields', 'red');
      return;
    }
    const cleanedDescription = description
      .toLowerCase()
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
      .replace(/\s{2,}/g, ' ');
    const classesByDepartment = getClassesByDepartment(departmentQuery);
    // Compare text in user input to class descriptions and return top 5 matches
    classesByDepartment.sort((a, b) => {
      const aWords = getWords(`${a[1].summary} ${a[1].courseName}`);
      const bWords = getWords(`${b[1].summary} ${b[1].courseName}`);

      const aMatches = aWords.filter((word) =>
        cleanedDescription.includes(word)
      );
      const bMatches = bWords.filter((word) =>
        cleanedDescription.includes(word)
      );
      return bMatches.length - aMatches.length;
    });

    const matches = Object.fromEntries(classesByDepartment.slice(0, 5));
    dispatch(suggestedClassesUpdated(matches));
    navigation.goBack();
    showToast('Suggested classes updated!', 'green');
  };

  // Helper functions
  const getWords = (text: string) => {
    const wordList = text
      .toLowerCase()
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
      .replace(/\s{2,}/g, ' ')
      .split(' ');

    let wordListWithSynonyms = [];

    for (const word of wordList) {
      if (synonyms[word]) {
        wordListWithSynonyms.push(word);
        wordListWithSynonyms = wordListWithSynonyms.concat(synonyms[word]);
      }
    }

    return wordListWithSynonyms;
  };

  return (
    <TouchableWithoutFeedback
      onPress={Keyboard.dismiss}
      style={{ flex: 1, alignItems: 'center' }}
    >
      <Layout style={{ maxWidth: MAX_WIDTH_WEB, alignSelf: 'center', flex: 1 }}>
        <WrapItems childStyle={{ margin: 20 }}>
          <Card activeOpacity={1} disabled>
            <Text category="s1" style={{ textAlign: 'center' }}>
              Choose a department and write a description about what inspires
              you.
              {`\n\n`}
              Then let our powerful AI choose the best electives for you!
            </Text>
          </Card>
          <Autocomplete
            label="Department"
            onSelect={(index) => setDepartmentQuery(departmentOptions[index])}
            onChangeText={onChangeDepartmentText}
            value={departmentQuery}
          >
            {departmentOptions.map((option) => (
              <AutocompleteItem title={option} key={option} />
            ))}
          </Autocomplete>
          <Input
            label="Description"
            multiline
            textStyle={{ minHeight: 100 }}
            value={description}
            onChangeText={(text) => setDescription(text)}
          />
          <Button onPress={onSubmit}>Submit</Button>
        </WrapItems>
      </Layout>
    </TouchableWithoutFeedback>
  );
}
