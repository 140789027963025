import DateTimePicker from '@react-native-community/datetimepicker';
import { useNavigation } from '@react-navigation/native';
import {
  Button,
  Text,
  CheckBox,
  Input,
  Layout,
  Select,
  SelectItem
} from '@ui-kitten/components';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Keyboard,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View
} from 'react-native';
import Toast from 'react-native-root-toast';
import Loader from 'react-native-three-dots-loader';

import { selectUserKey } from '../../app/appDataSlice';
import { useAppSelector } from '../../app/hooks';
import WrapItems from '../../components/WrapItems';
import { useEditUserMutation, useGetUserInfoQuery } from '../api/usersApi';

export default function CreateCustomEvent() {
  const [editUser] = useEditUserMutation();
  const [showDots, setShowDots] = useState(false);
  const navigation = useNavigation();
  const dateToUse = new Date();
  const [selectedDate, setSelectedDate] = useState(dateToUse);
  const [isOneDay, setIsOneDay] = useState(false);
  const [allValuesPopulated, setAllValuesPopulated] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [daysArray, setDaysArray] = useState([]);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [eventTitle, setEventTitle] = useState('');
  const userKey = useAppSelector(selectUserKey);
  const { data: userData } = useGetUserInfoQuery(userKey, {
    skip: !userKey
  });

  const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  useEffect(() => {
    setAllValuesPopulated(
      (isOneDay
        ? selectedDate.toISOString() !== dateToUse.toISOString()
        : daysArray.length >= 1) &&
        eventTitle !== '' &&
        startTime < endTime
    );
  }, [daysArray, eventTitle, startTime, endTime, selectedDate, isOneDay]);

  function addEvent() {
    setShowDots(true);
    editUser({
      userKey,
      userName: userData.userName,
      fields: JSON.stringify({
        addCustomEvent: {
          name: eventTitle,
          startTime: startTime.toTimeString().slice(0, 5),
          endTime: endTime.toTimeString().slice(0, 5),
          days: daysArray,
          date:
            selectedDate.toISOString() !== dateToUse.toISOString()
              ? selectedDate.toISOString()
              : null
        }
      })
    })
      .unwrap()
      .then(async () => {
        setTimeout(() => {
          setShowDots(false);
          setDaysArray([]);
          navigation.goBack();
        }, 2000);
      })
      .catch(() => {
        setShowDots(false);
        Toast.show('Failed to add event.\nPlease try again later', {
          duration: Toast.durations.LONG,
          opacity: 1,
          position: -100,
          backgroundColor: 'grey',
          shadow: false
        });
      });
    /*
        fetch(base_URL + '/users/' + userData.userName, {
            method: 'PATCH',
            body: JSON.stringify({
                "addCustomEvent": {
                    "name": eventTitle,
                    "startTime": startTime.toTimeString().slice(0, 5),
                    "endTime": endTime.toTimeString().slice(0, 5),
                    "days": daysArray
                },
            }),
            headers: {
                'content-type': 'application/json',
                userKey: userKey
            }
        }).then((res) => {
            if (res.ok) {
                navigation.goBack()
            } else {
                Toast.show(
                    "Failed to add event\nStatus: " + res.status,
                    {
                        duration: Toast.durations.LONG,
                        opacity: 1,
                        position: -100,
                        backgroundColor: 'grey',
                        shadow: false,
                    }
                );
            }
        }) */
  }

  // Needed because Android date picker uses a modal
  const [showTimePickerAndroid, setShowTimePickerAndroid] = useState(false);

  const timePickerOptions = useMemo(
    () => ({
      'Start Time': { time: startTime, setTime: setStartTime },
      'End Time': { time: endTime, setTime: setEndTime }
    }),
    [startTime, setStartTime, endTime, setEndTime]
  );

  const formatAMPM = (date: Date) => {
    let hours = date.getHours();
    let minutes: string | number = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes} ${ampm}`;
  };

  const Title = (
    <Layout
      style={{
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        marginTop: 15
      }}
    >
      <Input
        label="Title"
        disabled={showDots}
        placeholder="Title of Event"
        value={eventTitle}
        style={{ flex: 5 }}
        onChangeText={(title) => {
          setEventTitle(title);
        }}
      />
    </Layout>
  );

  function TimePicker() {
    if (Platform.OS === 'android') {
      return (
        <Layout
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around'
          }}
        >
          {Object.entries(timePickerOptions).map(
            ([label, { time, setTime }], i) => (
              <Layout key={i} style={{ alignItems: 'center' }}>
                <Text category="label" style={{ marginBottom: 5 }}>
                  {label}
                </Text>
                <Button
                  onPress={() => {
                    setShowTimePickerAndroid(true);
                  }}
                >
                  {formatAMPM(time)}
                </Button>
                {showTimePickerAndroid && (
                  <DateTimePicker
                    mode="time"
                    onChange={(change, date) => {
                      setTime(date);
                      setShowTimePickerAndroid(false);
                    }}
                    value={time}
                  />
                )}
              </Layout>
            )
          )}
        </Layout>
      );
    }
    if (Platform.OS === 'ios') {
      return (
        <Layout
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around'
          }}
        >
          {Object.entries(timePickerOptions).map(
            ([label, { time, setTime }], i) => (
              <Layout key={i} style={{ alignItems: 'center' }}>
                <Text category="label" style={{ marginBottom: 5 }}>
                  {label}
                </Text>

                <DateTimePicker
                  mode="time"
                  onChange={(change, date) => {
                    setTime(date);
                  }}
                  value={time}
                />
              </Layout>
            )
          )}
        </Layout>
      );
    }
  }

  if (Platform.OS === 'web') {
    return (
      <Text>
        Creating Events is not supported on the web. Download the app on the App
        Store or Play Store to make a new Event.
      </Text>
    );
  }

  const renderMultiSelectDropDown = () => {
    function renderEachDropItem() {
      const final = [];
      for (const option of daysOfWeek) {
        final.push(<SelectItem title={option} />);
      }
      return final;
    }
    function toggleValue(index) {
      setSelectedIndex(index);
      const newSelection = index.map((littleIndex) => {
        return daysOfWeek[littleIndex - 1];
      });
      setDaysArray(newSelection);
    }
    function calculateSelectedValues() {
      const finalArray = selectedIndex.map((littleIndex) => {
        return `${daysOfWeek[littleIndex - 1]}, `;
      });
      if (finalArray.length > 0) {
        let finalObject = finalArray.at(-1);
        finalObject = finalObject.replace(', ', '');
        finalArray.splice(-1, 1);
        finalArray.push(finalObject);
        return finalArray;
      }
    }
    return (
      <Layout
        style={{
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          marginTop: 15
        }}
        level="1"
      >
        <Select
          style={{ flex: 5 }}
          multiSelect
          label="Days"
          disabled={showDots}
          value={daysArray.length > 0 ? calculateSelectedValues() : null}
          selectedIndex={selectedIndex}
          onSelect={(index) => toggleValue(index)}
        >
          {renderEachDropItem()}
        </Select>
      </Layout>
    );
  };

  return (
    <Layout style={{ flex: 1 }}>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <WrapItems childStyle={{ marginVertical: 10, marginHorizontal: 15 }}>
          {Title}
          <TimePicker />
          <CheckBox
            style={{ alignSelf: 'center', marginTop: 20 }}
            checked={isOneDay}
            onChange={(nextChecked) => {
              setDaysArray([]);
              setSelectedIndex([]);
              setSelectedDate(dateToUse);
              setIsOneDay(nextChecked);
            }}
          >
            One Time Event
          </CheckBox>
          {isOneDay ? (
            <DateTimePicker
              testID="datePicker"
              value={selectedDate}
              mode="date"
              style={{ alignSelf: 'center', marginVertical: 15 }}
              onChange={(event, date) => setSelectedDate(date)}
            />
          ) : (
            renderMultiSelectDropDown()
          )}
          <TouchableOpacity
            style={{
              backgroundColor: allValuesPopulated
                ? '#a245ee'
                : 'rgba(162,69,238,0.34)',
              width: '35%',
              alignSelf: 'center',
              height: 45,
              borderRadius: 3,
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onPress={addEvent}
            disabled={!allValuesPopulated || showDots}
          >
            <View>
              <Text style={{ color: '#f2f0f1', fontSize: 15 }}>
                Create Event
              </Text>
            </View>
          </TouchableOpacity>
          {showDots && (
            <Loader
              style={{
                position: 'absolute',
                marginTop: '30%',
                marginHorizontal: 'auto'
              }}
            />
          )}
        </WrapItems>
      </TouchableWithoutFeedback>
    </Layout>
  );
}
