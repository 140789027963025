import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.athenaserver.com:5678/api'
    // baseUrl: 'http://localhost:1234/api'
  }),
  tagTypes: ['User', 'Posts'],
  endpoints: (builder) => ({
    getCurrentVersion: builder.query<any, void>({
      query: () => '/randomShit/getCurrentVersion'
    })
  })
});

export const { useLazyGetCurrentVersionQuery } = apiSlice;
