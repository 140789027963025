import {
  Feather,
  FontAwesome5,
  Foundation,
  Ionicons
} from '@expo/vector-icons';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Layout, Text, useTheme } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { Image, Platform, TouchableOpacity } from 'react-native';

import {
  selectDarkMode,
  selectIsGuest,
  selectUserKey
} from '../../app/appDataSlice';
import { useAppSelector } from '../../app/hooks';
import Header from '../../components/Header';
import ModalContainer from '../../components/ModalContainer';
import WrapItems from '../../components/WrapItems';
import { exportToICal } from '../../functions';
import { useGetUserInfoQuery } from '../api/usersApi';
import CalendarPage from '../calendar/CalendarPage';
import CreateCustomEvent from '../calendar/CreateCustomEvent';
import renderModal from '../calendar/exportModal';
import ElectiveGenerator from '../classPages/ElectiveGenerator';
import SelectTerm from '../classPages/SelectTerm';
import NotificationsNavigation from '../notifications/Notifications';
import { base_URL } from '../notifications/constants';
import Settings from '../settings/index';
import { SponsorPage, SponsorsList } from '../sponsors/SponsorsList';
import { useGetTermDatesMutation } from '../api/classPagesApi';

const Stack = createNativeStackNavigator();

export default function CombinedClassPages({ navigation }) {
  const [numberOfNotifications, setNumberOfNotifications] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showGuestModal, setShowGuestModal] = useState(false);
  const [showWebModal, setShowWebModal] = useState(false);

  const theme = useTheme();

  const hasSelectedElectiveGenerator = useAppSelector(
    (state) => state.appData.classPages.hasSelectedElectiveGen
  );
  const isMobile = useAppSelector((state) => state.appData.secure.isMobile);
  const userKey = useAppSelector(selectUserKey);
  const currentSection = useAppSelector(
    (state) => state.appData.classPages.currentSection
  );
  const classes = useAppSelector((state) => state.appData.classPages.classes);
  const isGuest = useAppSelector(selectIsGuest);
  const darkMode = useAppSelector(selectDarkMode);

  const { data: userData } = useGetUserInfoQuery(userKey);
  const [getTermDates] = useGetTermDatesMutation();

  const exportCalendarToICal = async () => {
    try {
      // const date = await getTermDates({ userKey, currentSection });
      const startDate = '01-19-2024';
      const endDate = '04-25-2024';
      const times = Object.entries(
        userData.addedClasses.classes[currentSection]
      ).flatMap(([masterCourseCode, courseCodes]) => {
        return courseCodes.flatMap((courseCode) => {
          return classes[masterCourseCode].times.filter(
            (time) => time.courseCode === courseCode
          );
        });
      });
      const calendar = exportToICal(times, startDate, endDate);
      console.log(calendar);
    } catch (e) {
      console.warn(e);
    }
  };

  const accessoryRight = (
    <WrapItems
      childStyle={
        !isMobile && {
          paddingHorizontal: 5
        }
      }
      style={{
        paddingHorizontal: 0,
        alignItems: 'center',
        flexDirection: 'row'
      }}
    >
      {showGuestModal && (
        <ModalContainer
          title="Wait!"
          children={
            <Text style={{ paddingHorizontal: 30 }}>
              This feature is not available to Guest Users. Please create a free
              account with us to take advantage this feature.
            </Text>
          }
          backDropVisible={showGuestModal}
          setBackDropVisible={setShowGuestModal}
        />
      )}
      {Platform.OS === 'web' && showWebModal && (
        <ModalContainer
          title="Wait!"
          children={
            <Text style={{ paddingHorizontal: 30 }}>
              Sharing schedules is not supported on the web. Download the app on
              the App Store or Play Store to share schedules.
            </Text>
          }
          backDropVisible={showWebModal}
          setBackDropVisible={setShowWebModal}
        />
      )}
      {/* <TouchableOpacity
        onPress={exportCalendarToICal}
        style={{
          alignItems: 'center'
        }}
      >
        <Feather name="share" size={24} color={darkMode ? 'white' : 'black'} />
      </TouchableOpacity> */}

      {/* Elective Generator */}
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('ElectiveGenerator');
        }}
        style={{ flexDirection: 'row', alignItems: 'center' }}
      >
        {hasSelectedElectiveGenerator ? (
          <FontAwesome5
            name="brain"
            size={23}
            color={
              darkMode ? theme['color-basic-200'] : theme['color-basic-800']
            }
          />
        ) : (
          <Foundation
            name="burst-new"
            size={30}
            color={theme['color-warning-500']}
          />
        )}
      </TouchableOpacity>

      {/* Settings */}
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('Settings');
        }}
        style={{ flexDirection: 'row', alignItems: 'center' }}
      >
        <FontAwesome5
          name="user-alt"
          size={23}
          color={darkMode ? theme['color-basic-200'] : theme['color-basic-800']}
        />
      </TouchableOpacity>

      {/* Notifications */}
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('NotificationsPage');
        }}
        style={{ flexDirection: 'row', alignItems: 'center' }}
      >
        <FontAwesome5
          name="bell"
          size={23}
          color={darkMode ? theme['color-basic-200'] : theme['color-basic-800']}
        />
        {numberOfNotifications > 0 ? (
          <Layout
            style={{
              position: 'absolute',
              backgroundColor: darkMode ? 'red' : '#fff',
              borderRadius: 10,
              paddingHorizontal: 3,
              right: -7,
              top: -3
            }}
          >
            <Text>{numberOfNotifications}</Text>
          </Layout>
        ) : null}
      </TouchableOpacity>
    </WrapItems>
  );

  function refreshNotifications() {
    fetch(`${base_URL}/users/getNotifications`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        userKey
      }
    })
      .then((res) => {
        res.text().then((notifications) => {
          notifications = JSON.parse(notifications);
          setNumberOfNotifications(notifications.length);
        });
      })
      .catch((e) => console.warn(e));
  }

  useEffect(() => {
    refreshNotifications();
    setInterval(() => {
      refreshNotifications();
    }, 60000); // refresh every minute
  }, []);

  const accessoryLeft = (
    <Image
      style={{
        height: 28,
        width: 28
      }}
      source={require('../../../assets/logos/app-logo-transparent.png')}
    />
  );
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Calendar"
        component={CalendarPage}
        options={{
          header: () => (
            <Layout style={{ paddingBottom: 10 }}>
              <Header
                accessoryLeft={accessoryLeft}
                accessoryRight={accessoryRight}
                title="Athena"
              />
            </Layout>
          )
        }}
      />
      <Stack.Screen
        name="Settings"
        component={Settings}
        options={{
          headerShown: false
        }}
      />
      <Stack.Group screenOptions={{ headerTitle: 'Sponsors' }}>
        <Stack.Screen
          name="SponsorsList"
          component={SponsorsList}
          options={{ title: 'Sponsors' }}
        />
        <Stack.Screen
          name="SponsorPage"
          component={SponsorPage}
          options={{ title: 'Sponsors' }}
        />
      </Stack.Group>
      <Stack.Screen
        name="NotificationsPage"
        component={NotificationsNavigation}
        options={{ headerShown: false, title: 'Notifications' }}
      />
      <Stack.Screen
        name="SelectTerm"
        component={SelectTerm}
        options={{
          title: 'Select Term',
          headerShown: false,
          animation: 'slide_from_bottom',
          gestureDirection: 'vertical'
        }}
      />
      <Stack.Screen
        name="ElectiveGenerator"
        component={ElectiveGenerator}
        options={{
          title: 'Elective Generator',
          animation: 'slide_from_bottom',
          gestureDirection: 'vertical'
        }}
      />
      <Stack.Screen
        name="CreateCustomEvent"
        component={CreateCustomEvent}
        options={{ headerTitle: 'Add Event' }}
      />
    </Stack.Navigator>
  );
}
