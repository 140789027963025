import { Button, Icon, Input, Layout, useTheme } from '@ui-kitten/components';
import { useState } from 'react';
import { Keyboard, LayoutAnimation, TouchableOpacity } from 'react-native';

import { selectDarkMode } from '../app/appDataSlice';
import { useAppSelector } from '../app/hooks';

type SearchBarProps = {
  onChangeText: (text: string) => void;
  searchTerm: string;
  placeholder?: string;
  accessoryLeft?: any;
  inputProps?: any;
  inputStyle?: any;
  inputRef?: any;
  cancelButtonAction?: () => void;
  onSubmitEditing?: () => void;
  showCancelButton?: boolean;
  alwaysShowCancelButton?: boolean;
  cancelButtonStatus?:
    | 'basic'
    | 'primary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger';
  showXButton?: boolean;
};

export default function SearchBar({
  onChangeText,
  searchTerm,
  accessoryLeft,
  placeholder,
  inputRef,
  inputStyle,
  inputProps,
  cancelButtonAction = () => {},
  onSubmitEditing = () => {},
  cancelButtonStatus = 'warning',
  showCancelButton = true,
  alwaysShowCancelButton = false,
  showXButton = true
}: SearchBarProps) {
  const [selected, setSelected] = useState(false);
  const [searchbarWidth, setSearchbarWidth] = useState(0);

  const darkMode = useAppSelector(selectDarkMode);
  const theme = useTheme();

  const select = () => {
    setSelected(true);
    if (!alwaysShowCancelButton) {
      LayoutAnimation.configureNext(
        LayoutAnimation.create(100, 'linear', 'scaleX')
      );
    }
  };

  const deselect = () => {
    setSelected(false);
    if (!alwaysShowCancelButton) {
      LayoutAnimation.configureNext(
        LayoutAnimation.create(100, 'linear', 'opacity')
      );
    }
  };

  const getPlaceHolderText = () => {
    if (searchbarWidth < 200) {
      return '';
    }
    if (searchbarWidth < 300) {
      return 'Search';
    }
    return placeholder;
  };

  return (
    <Layout
      style={{
        backgroundColor: 'transparent',
        flexDirection: 'row'
      }}
      onLayout={(event) => {
        setSearchbarWidth(event.nativeEvent.layout.width);
      }}
    >
      {accessoryLeft}

      <Input
        style={{
          ...inputStyle,
          borderRadius: 20,
          flex: 1,
          borderWidth: 1,
          borderColor: darkMode
            ? theme['color-basic-500']
            : theme['color-basic-100']
        }}
        placeholderTextColor={theme['color-basic-500']}
        accessoryRight={
          showXButton && selected && searchTerm.length > 0
            ? (props) => (
                <TouchableOpacity onPress={() => onChangeText('')}>
                  <Icon {...props} name="times" pack="fontAwesome" />
                </TouchableOpacity>
              )
            : null
        }
        onFocus={select}
        onBlur={deselect}
        placeholder={getPlaceHolderText()}
        value={searchTerm}
        onChangeText={(text) => {
          onChangeText(text);
        }}
        onSubmitEditing={onSubmitEditing}
        ref={inputRef}
        autoCorrect={false}
        {...inputProps}
      />

      {showCancelButton && (alwaysShowCancelButton || selected) ? (
        <Button
          size="small"
          status={cancelButtonStatus}
          appearance="ghost"
          onPress={() => {
            cancelButtonAction();
            Keyboard.dismiss();
          }}
        >
          {/* <Icon name="times" pack="fontAwesome" /> */}
          Cancel
        </Button>
      ) : null}
    </Layout>
  );
}
