export const departments = [
  'AFRI',
  'AMST',
  'ANTH',
  'ARBC',
  'ARBT',
  'ARCN',
  'ARHI',
  'ART',
  'ASAM',
  'ASIA',
  'ASTR',
  'BIOL',
  'CASA',
  'CGH',
  'CGS',
  'CHEM',
  'CHIN',
  'CHLT',
  'CHNT',
  'CHST',
  'CLAS',
  'CLES',
  'COGS',
  'CORE',
  'CREA',
  'CSCI',
  'CSMT',
  'DANC',
  'DS',
  'EA',
  'ECON',
  'EDUC',
  'ENGL',
  'ENGR',
  'ENTR',
  'EURO',
  'FGSS',
  'FHS',
  'FREN',
  'FS',
  'FWS',
  'GEOG',
  'GEOL',
  'GERM',
  'GLAS',
  'GOVT',
  'GREK',
  'GRMT',
  'GWS',
  'HIST',
  'HMSC',
  'HSA',
  'HUM',
  'ID',
  'IR',
  'ITAL',
  'JAPN',
  'JPNT',
  'KORE',
  'KRNT',
  'LAMS',
  'LAS',
  'LAST',
  'LATN',
  'LEAD',
  'LGCS',
  'LGST',
  'LIT',
  'MATH',
  'MCBI',
  'MCSI',
  'MES',
  'MLLC',
  'MOBI',
  'MS',
  'MSL',
  'MUS',
  'NEUR',
  'ORST',
  'PE',
  'PHIL',
  'PHYS',
  'POLI',
  'PONT',
  'PORT',
  'POST',
  'PPA',
  'PPE',
  'PSYC',
  'RLIT',
  'RLST',
  'RUSS',
  'RUST',
  'SCI',
  'SOC',
  'SPAN',
  'SPCH',
  'STS',
  'TEST',
  'THEA',
  'THES',
  'WRIT'
];
