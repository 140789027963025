import {
  Autocomplete,
  AutocompleteItem,
  Input,
  Layout
} from '@ui-kitten/components';
import { Button, Platform, Text, TouchableOpacity } from 'react-native';
import { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useAppSelector } from '../../app/hooks';
import { selectDarkMode, selectUserKey } from '../../app/appDataSlice';
import { base_URL } from '../notifications/constants';
import { useCreateChatRoomMutation } from '../api/usersApi';
import { showToast } from '../../functions';

export default function CreateChatRoom() {
  const isWeb = Platform.OS === 'web';
  const [isDisabled, setIsDisabled] = useState(true);
  const [nameOfChatRoom, setNameOfChatRoom] = useState('');
  const navigation = useNavigation();
  const [createChatRoom] = useCreateChatRoomMutation();
  const userKey = useAppSelector(selectUserKey);
  const darkMode = useAppSelector(selectDarkMode);
  const [recipients, setRecipients] = useState([]);
  const [names, setNames] = useState([]); // filtered list of names
  const [users, setUsers] = useState([]); // clean list of users
  const [value, setValue] = useState(''); // typed name
  const myStyles = {
    modal: {
      fontSize: 18,
      paddingBottom: 20,
      fontWeight: 'bold',
      textAlign: 'center',
      width: '100%',
      color: darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'
    },
    backdrop: {
      backgroundColor: darkMode ? 'rgba(141,141,141,0.5)' : 'rgba(0, 0, 0, 0.5)'
    },
    containerDrop: {
      minWidth: 200,
      paddingBottom: 15
    }
  };

  useEffect(() => {
    setIsDisabled(nameOfChatRoom.length === 0 || recipients.length === 0);
  }, [nameOfChatRoom, recipients]);

  useEffect(() => {
    fetch(`${base_URL}/users/userNamesAndNames`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        userKey
      }
    }).then(async (data) => {
      if (data.ok) {
        let userList = await data.json();
        userList = userList.filter((user) => {
          return user;
        });
        setNames(
          userList.map((user) => {
            if (user) {
              return `${user.realName} (${user.userName})`;
            }
          })
        );
        setUsers(userList);
      }
    });
  }, []);

  const onChangeText = (query) => {
    setNames(
      freshNames().filter(
        (name) =>
          name?.toLowerCase().includes(query.toLowerCase()) ||
          userNameContainsString(name, query)
      )
    );
    setValue(query);
  };

  function freshNames() {
    return users.map((user) => {
      if (user) {
        return `${user.realName} (${user.userName})`;
      }
    });
  }

  const userNameContainsString = (name, query) => {
    const user = users.find(
      (auser) =>
        auser?.userName.toLowerCase().includes(query.toLowerCase()) &&
        auser?.realName === name
    );
    return user !== undefined;
  };

  function findUserNameFromName(name) {
    const firstPar = name.indexOf('(');
    const secondPar = name.indexOf(')');
    return name.slice(firstPar + 1, secondPar);
  }

  function handleAddValue() {
    const nameToAdd = findUserNameFromName(value);
    if (!recipients.includes(nameToAdd)) {
      setRecipients(recipients.concat([nameToAdd]));
    }
    setValue('');
  }

  return (
    <Layout
      style={{
        width: isWeb ? '30%' : '90%',
        alignItems: isWeb ? undefined : 'center',
        backgroundColor: 'transparent',
        marginLeft: isWeb ? 15 : undefined
      }}
    >
      <Layout
        style={{
          marginTop: 20,
          marginLeft: isWeb ? undefined : 20,
          flexDirection: 'row',
          backgroundColor: 'transparent'
        }}
      >
        <Input
          style={{ minWidth: '100%' }}
          label="Chatroom Name"
          onChangeText={setNameOfChatRoom}
          value={nameOfChatRoom}
        />
      </Layout>
      <Layout
        style={{
          marginVertical: 20,
          marginLeft: isWeb ? undefined : 30,
          flexDirection: 'row',
          backgroundColor: 'transparent'
        }}
      >
        <Input
          style={{ minWidth: '85%' }}
          placeholder="Added Users"
          disabled
          value={recipients.join(', ')}
        />
        <Layout style={{ alignSelf: 'center', backgroundColor: 'transparent' }}>
          <Button color="red" onPress={() => setRecipients([])} title="Clear" />
        </Layout>
      </Layout>
      <Layout
        style={{
          flexDirection: 'row',
          marginTop: 10,
          marginLeft: isWeb ? undefined : 10,
          backgroundColor: 'transparent'
        }}
      >
        <Autocomplete
          style={{ minWidth: '85%' }}
          value={value}
          placeholder="Add User"
          onChangeText={onChangeText}
          onSelect={(index) => setValue(names[index])}
        >
          {names.map((user, index) => {
            return <AutocompleteItem title={user} key={index} />;
          })}
        </Autocomplete>
        <Layout style={{ alignSelf: 'center', backgroundColor: 'transparent' }}>
          <Button color="blue" onPress={() => handleAddValue()} title="Add" />
        </Layout>
      </Layout>
      <TouchableOpacity
        disabled={isDisabled}
        onPress={() => {
          createChatRoom({ userKey, recipients, nameOfChatRoom })
            .unwrap()
            .then((payload) => {
              showToast('Chatroom Created');
              navigation.goBack();
            })
            .catch((e) => {
              showToast('Error, please try again later');
              console.warn(e);
            });
        }}
        style={{
          marginTop: 30,
          alignItems: 'center',
          justifyContent: 'center',
          paddingVertical: 12,
          paddingHorizontal: 32,
          borderRadius: 4,
          elevation: 3,
          backgroundColor: isDisabled ? 'rgba(227,39,39,0.49)' : '#e32727'
        }}
      >
        <Text
          style={{
            fontSize: 16,
            lineHeight: 21,
            fontWeight: 'bold',
            letterSpacing: 0.25,
            color: 'white'
          }}
        >
          Create Chat Room
        </Text>
      </TouchableOpacity>
    </Layout>
  );
}
