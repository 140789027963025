export const RENDERED_RESULTS_LIMIT_MOBILE = 10;
export const RENDERED_RESULTS_LIMIT_DESKTOP = 20;
export const NEW_REVIEW_FIELDS = [
  {
    label: 'Professor',
    id: 'professor',
    mandatory: false,
    type: 'DropDown',
    options: []
  },
  {
    label: 'Grade',
    id: 'grade',
    type: 'DropDown',
    mandatory: false,
    options: [
      {
        label: 'A+',
        id: 'A'
      },
      {
        label: 'A',
        id: 'A'
      },
      {
        label: 'A-',
        id: 'A'
      },
      {
        label: 'B+',
        id: 'B'
      },
      {
        label: 'B',
        id: 'B'
      },
      {
        label: 'B-',
        id: 'B'
      },
      {
        label: 'C+',
        id: 'C'
      },
      {
        label: 'C',
        id: 'C'
      },
      {
        label: 'C-',
        id: 'C'
      },
      {
        label: 'D+',
        id: 'D'
      },
      {
        label: 'D',
        id: 'D'
      },
      {
        label: 'D-',
        id: 'D'
      },
      {
        label: 'F+',
        id: 'F'
      },
      {
        label: 'F',
        id: 'F'
      },
      {
        label: 'F-',
        id: 'F'
      }
    ]
  },
  {
    label: 'Time Spent in Hours (ie: 7)',
    id: 'timeSpent',
    mandatory: false,
    type: 'String'
  }
];

export const dayToICalAbbrev = {
  Monday: 'MO',
  Tuesday: 'TU',
  Wednesday: 'WE',
  Thursday: 'TH',
  Friday: 'FR',
  Saturday: 'SA',
  Sunday: 'SU'
};