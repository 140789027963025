import { Entypo, FontAwesome5 } from '@expo/vector-icons';
import BottomSheet from '@gorhom/bottom-sheet/lib/typescript/components/bottomSheet/BottomSheet';
import { useNavigation } from '@react-navigation/native';
import { Button, useTheme } from '@ui-kitten/components';
import React, { useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  PanResponder,
  Platform,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
  TouchableOpacity
} from 'react-native';
import Animated from 'react-native-reanimated';
import { selectDarkMode, selectUserKey } from '../../app/appDataSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CustomEvent } from '../../app/types';
import ModalContainer from '../../components/ModalContainer';
import { getMasterCourseCodeFromCourseCode, showToast } from '../../functions';
import { useRemoveClassMutation } from '../api/classPagesApi';
import { useEditUserMutation, useGetUserInfoQuery } from '../api/usersApi';
import { CurrentTermLabel } from '../classPages/ClassesList';
import SingleClassPage from '../classPages/SingleClassPage';
import {
  classSelected,
  selectClassesList
} from '../classPages/classPagesSlice';
import ClassListPage from '../combinedCalender/classList';
import { base_URL } from '../notifications/constants';
import CalendarFunctions from './CalendarFunctions';
import RenderedItem from './ScheduledItem';
import renderCustomEvent from './customEvent';

// LogBox.ignoreAllLogs();
const DIVIDER_WIDTH = 15;
const TICKER_UPDATE_INTERVAL = 5 * 60 * 1000;

export default function CalendarRender({
  route: {
    params: { overrideData }
  }
}) {
  const [showOverlay, setShowOverlay] = useState(false);
  const isWeb = Platform.OS === 'web';
  const theme = useTheme();
  const [editUser] = useEditUserMutation();
  const [removeClass] = useRemoveClassMutation();
  const userKey = useAppSelector(selectUserKey);
  const isThreeDay = useAppSelector(
    (state) => state.appData.calendar.isThreeDay
  );
  const isMobile = useAppSelector((state) => state.appData.secure.isMobile);
  const navigation = useNavigation();

  const topBar = useRef(null);
  const verticalRef = useRef(null);
  const [isRightWindowOpen, setIsRightWindowOpen] = useState(true);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [startingOffset, setStartingOffset] = useState({
    x: 0,
    y: isWeb ? 500 : 525
  });
  const dimensions = useWindowDimensions();
  const [rightWidth, setRightWidth] = useState(
    (2 * (dimensions.width - DIVIDER_WIDTH - 100)) / 7
  );
  const [menuCourseCode, setMenuCourseCode] = useState<
    { key: string } | boolean
  >(false);
  const [menuCustomEvent, setMenuCustomEvent] = useState<
    { key: string; event: CustomEvent } | boolean
  >(false);
  const leftWidth = dimensions.width - DIVIDER_WIDTH;
  const minimumWidth = (2 * (dimensions.width - DIVIDER_WIDTH - 100)) / 7;

  const _panResponder = useRef(getPanResponder());
  const dispatch = useAppDispatch();
  const bottomSheetRef = useRef<BottomSheet>(null);
  const darkMode: boolean = useAppSelector(selectDarkMode);
  const calendarFun = new CalendarFunctions();
  const [isDividerClicked, setIsDividerClicked] = useState(false);
  const universalHeight = (dimensions.height - 50) / 12;
  const threeDayWidth = (dimensions.width - 75) / 3;
  const fiveDayWidth = isMobile
    ? (dimensions.width - 75) / 5
    : (dimensions.width - 75) / 7;
  const [classes, setClasses] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
  });
  const days = [
    { day: 'Mon', id: 'Monday' },
    { day: 'Tues', id: 'Tuesday' },
    { day: 'Wed', id: 'Wednesday' },
    { day: 'Thurs', id: 'Thursday' },
    { day: 'Fri', id: 'Friday' },
    { day: 'Sat', id: 'Saturday' },
    { day: 'Sun', id: 'Sunday' }
  ];
  const times = [
    '12:00 AM',
    '1:00 AM',
    '2:00 AM',
    '3:00 AM',
    '4:00 AM',
    '5:00 AM',
    '6:00 AM',
    '7:00 AM',
    '8:00 AM',
    '9:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '1:00 PM',
    '2:00 PM',
    '3:00 PM',
    '4:00 PM',
    '5:00 PM',
    '6:00 PM',
    '7:00 PM',
    '8:00 PM',
    '9:00 PM',
    '10:00 PM',
    '11:00 PM'
  ];

  const { data: userData } = useGetUserInfoQuery(userKey);
  const orderedAddedClasses = userData?.addedClasses?.orderedClasses;

  const allClassData = useAppSelector(selectClassesList);
  const [overrideClassesToRender, setOverrideClassesToRender] = useState([]);
  const currentSection = useAppSelector(
    (state) => state.appData.classPages.currentSection
  );

  const today = new Date().getDay() !== 0 ? new Date().getDay() - 1 : 6;

  useEffect(() => {
    try {
      if (overrideData.length !== 0) {
        getOverrideCourseData().then(() => {});
      }
    } catch (e) {
      console.warn(e);
    }
    if (isWeb) {
      verticalRef.current?.scrollTo({
        y: dimensions.height / 1.9
      });
    }

    // Recalculate time
    setInterval(() => {
      setCurrentTime(new Date());
    }, TICKER_UPDATE_INTERVAL);
  }, []);

  useEffect(() => {
    _panResponder.current = getPanResponder();
  }, [dimensions.width]);

  async function getOverrideCourseData() {
    const stringOverrideData = JSON.stringify(
      overrideData.map((course) => {
        return course.courseCode.slice(0, -3);
      })
    );
    const response = await fetch(
      `${base_URL}/classes/claremontColleges?listOfCourses=${stringOverrideData}&courseTerm=${overrideData[0].courseTerm}`,
      {
        headers: {
          userKey,
          'content-type': 'application/json'
        },
        method: 'GET'
      }
    );
    setOverrideClassesToRender(await response.json());
  }

  const renderCustomEvents = (
    elem,
    extraTransparency = false,
    disableInteractions = false
  ) => {
    const finalRender = [];
    if (!userData.customEvents) {
      return null;
    }
    for (const event of userData.customEvents) {
      if (event.days.length > 0) {
        if (event.days.includes(elem.id)) {
          finalRender.push(
            renderCustomEvent(
              event,
              userData,
              userKey,
              editUser,
              setMenuCustomEvent,
              disableInteractions,
              extraTransparency,
              dimensions
            )
          );
        }
      } else if (
        event.date &&
        eventIsThisDay(elem.id, new Date(event.date)) &&
        showThisWeek(new Date(event.date))
      ) {
        finalRender.push(
          renderCustomEvent(
            event,
            userData,
            userKey,
            editUser,
            setMenuCustomEvent,
            disableInteractions,
            extraTransparency,
            dimensions
          )
        );
      }
    }
    return finalRender;
  };

  function getPanResponder() {
    return PanResponder.create({
      // @ts-ignore
      onMoveShouldSetResponderCapture: () => true,
      onMoveShouldSetPanResponderCapture: () => true,

      onPanResponderGrant: (e, gestureState) => {
        setIsDividerClicked(true);
      },

      onPanResponderMove: (e, gestureState) => {
        const newRight = dimensions.width - gestureState.moveX;

        if (newRight < minimumWidth) {
          setRightWidth(minimumWidth);
        } else if (newRight > dimensions.width - minimumWidth) {
          setRightWidth(dimensions.width - minimumWidth);
        } else {
          setRightWidth(newRight);
        }
      },

      onPanResponderRelease: (e, gestureState) => {
        setIsDividerClicked(false);
      }
    });
  }

  function eventIsThisDay(dayString: string, date: Date) {
    const dayOfWeek = adjustDays(date.getDay());
    return dayString === days[dayOfWeek].id;
  }

  function adjustDays(dayOfWeek) {
    if (dayOfWeek === 0) {
      dayOfWeek = 6;
    } else {
      dayOfWeek -= 1;
    }
    return dayOfWeek;
  }

  function getMs(hours, minutes, seconds) {
    let final = 0;
    final += hours * 3600000;
    final += minutes * 60000;
    final += seconds * 1000;
    return final;
  }

  function showThisWeek(dateOfEvent: Date) {
    const currentDate = new Date();
    const currentDayOfWeek = adjustDays(currentDate.getDay());
    const startOfWeek = new Date(
      currentDate.valueOf() -
        currentDayOfWeek * 86400000 -
        getMs(
          currentDate.getHours(),
          currentDate.getMinutes(),
          currentDate.getSeconds()
        )
    );
    const endOfWeek = new Date(
      currentDate.valueOf() +
        (6 - currentDayOfWeek) * 86400000 +
        getMs(
          23 - currentDate.getHours(),
          59 - currentDate.getMinutes(),
          59 - currentDate.getSeconds()
        )
    );
    return !(
      dateOfEvent.valueOf() > endOfWeek.valueOf() ||
      dateOfEvent.valueOf() < startOfWeek.valueOf()
    );
  }

  const handleClassSelected = (courseCode: string) => {
    dispatch(classSelected(courseCode));
    if (Platform.OS === 'web') {
      // @ts-ignore
      navigation.navigate('SingleClassPage');
    } else {
      bottomSheetRef.current?.snapToIndex(0);
    }
  };

  const handleRemoveClass = () => {
    if (typeof menuCourseCode !== 'object') {
      return;
    }
    const masterCourseCode = getMasterCourseCodeFromCourseCode(
      menuCourseCode.key
    );
    try {
      removeClass({
        userKey,
        courseCode: menuCourseCode.key,
        courseTerm: currentSection,
        userName: userData.userName,
        masterCourseCode
      }).unwrap();
      setMenuCourseCode(false);
    } catch (e) {
      console.warn('Add class error: ', e);
      showToast('Failed to remove class', 'red');
    }
  };

  function renderOverlayOption() {
    return (
      <TouchableOpacity
        style={{
          borderWidth: 1,
          borderColor: 'rgba(0,0,0,0.2)',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          bottom: 50,
          right: isWeb ? undefined : 25,
          left: isWeb ? 100 : undefined,
          width: 100,
          height: 100,
          backgroundColor: 'rgba(149,56,182,0.59)',
          borderRadius: 50
        }}
        onPress={() => {
          setShowOverlay(!showOverlay);
        }}
      >
        <Text>
          {`${showOverlay ? 'Disable' : 'Enable'}\n`}
          Overlay
        </Text>
      </TouchableOpacity>
    );
  }

  const handleRemoveCustomEvent = () => {
    if (typeof menuCustomEvent !== 'object') {
      return;
    }
    const { event } = menuCustomEvent;
    editUser({
      userKey,
      userName: userData.userName,
      fields: JSON.stringify({
        removeCustomEvent: {
          name: event.name,
          startTime: event.startTime,
          endTime: event.endTime,
          days: event.days
        }
      })
    })
      .unwrap()
      .then(() => {
        setMenuCustomEvent(false);
      })
      .catch((e) => {
        showToast('Failed to remove event', 'red');
        console.warn("Couldn't remove custom event: ", e);
      });
  };

  function renderDashes() {
    const final = [];
    for (let i = 0; i < 24; i++) {
      final.push(
        <View
          key={i}
          style={{
            width: (isThreeDay ? threeDayWidth : fiveDayWidth) * 0.73016,
            marginLeft: (isThreeDay ? threeDayWidth : fiveDayWidth) * 0.14286,
            top: universalHeight * i,
            position: 'absolute',
            height: universalHeight,
            borderBottomWidth: Platform.OS === 'web' ? 2 : 1,
            borderBottomColor: darkMode ? 'rgb(68,68,68)' : 'rgb(204,204,204)'
          }}
        />
      );
    }
    return final;
  }

  const renderClasses = (
    elem,
    extraTransparency = false,
    disableInteractions = false
  ) => {
    const classes = [];
    Object.keys(orderedAddedClasses).map((term) => {
      if (term === currentSection) {
        // @ts-ignore
        orderedAddedClasses[term]
          .slice()
          .reverse()
          .map((courseCode) => {
            try {
              const data = calendarFun.getFullClassData(
                courseCode,
                allClassData
              );
              if (!data) return <View />; // catch for somehow missing data; usually cached issue or missing term information; not sure
              const data2 = data.times.find(
                (time) => time.courseCode === courseCode
              );
              for (const meetingTime of data2.courseSchedule) {
                for (const day in meetingTime.daysOfTheWeek) {
                  if (meetingTime.daysOfTheWeek[day] === elem.id) {
                    classes.push(
                      <RenderedItem
                        masterClassInfo={data}
                        meetingTime={meetingTime}
                        courseCode={courseCode}
                        handleClassSelected={handleClassSelected}
                        setMenuCourseCode={setMenuCourseCode}
                        disableLongPress={disableInteractions}
                        extraTransparency={extraTransparency}
                        dimensions={dimensions}
                      />
                    );
                  }
                }
              }
            } catch (e) {
              console.warn(e);
            }
          });
      }
    });
    return classes;
  };

  function renderClassesForOverride(elem) {
    const classes = [];
    if (overrideClassesToRender.length > 0) {
      for (const courseCode of overrideData.map((course) => {
        return course.courseCode;
      })) {
        try {
          const data = overrideClassesToRender.find((course) =>
            course.times.some(
              (courseTime) => courseTime.courseCode === courseCode
            )
          );
          const data2 = data.times.find(
            (time) => time.courseCode === courseCode
          );
          for (const meetingTime of data2.courseSchedule) {
            for (const day in meetingTime.daysOfTheWeek) {
              if (meetingTime.daysOfTheWeek[day] === elem.id) {
                classes.push(
                  <RenderedItem
                    masterClassInfo={data}
                    meetingTime={meetingTime}
                    courseCode={courseCode}
                    handleClassSelected={handleClassSelected}
                    setMenuCourseCode={setMenuCourseCode}
                    disableLongPress
                    extraTransparency={false}
                    dimensions={dimensions}
                  />
                );
              }
            }
          }
        } catch (e) {
          console.warn(e);
        }
      }
    }
    return classes;
  }

  function renderDeleteModal(
    event: { key: string; [key: string]: any } | boolean,
    onRemove: () => void,
    onCancel: () => void
  ) {
    return (
      <ModalContainer
        backDropVisible={!!event}
        title={typeof event === 'object' ? event.key : ''}
        setBackDropVisible={onCancel}
        modalStyle={{ maxHeight: 200 }}
        modalProps={{ animationOut: 'fadeOut' }}
      >
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 20,
            backgroundColor: darkMode
              ? theme['color-basic-900']
              : theme['color-basic-100']
          }}
        >
          <Button style={{ marginBottom: 10 }} onPress={onRemove}>
            Remove
          </Button>
          <Button appearance="outline" onPress={onCancel}>
            Cancel
          </Button>
        </View>
      </ModalContainer>
    );
  }

  function masterRender(elem) {
    if (overrideClassesToRender.length !== 0) {
      if (showOverlay) {
        return (
          <View>
            {renderClassesForOverride(elem)}
            {userData?.addedClasses && renderClasses(elem, true, true)}
            {userData?.addedClasses && renderCustomEvents(elem, true, true)}
          </View>
        );
      }
      return <View>{renderClassesForOverride(elem)}</View>;
    }
    return (
      <View>
        {userData?.addedClasses && renderClasses(elem)}
        {userData?.addedClasses && renderCustomEvents(elem)}
      </View>
    );
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: darkMode ? null : 'white',
        flexDirection: 'row',
        borderRadius: 10,
        // @ts-ignore
        cursor: isDividerClicked ? 'col-resize' : null
      }}
    >
      <Animated.View
        style={[
          { flex: 1 },
          !isMobile && { minWidth: minimumWidth, width: leftWidth }
        ]}
      >
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          {/* Times */}
          {overrideData.length === 0 && (
            <CurrentTermLabel
              navigation={navigation}
              currentSection={currentSection}
              style={{ margin: 10 }}
            />
          )}

          {!isMobile && (
            <Button
              accessoryRight={() => (
                <FontAwesome5
                  name={isRightWindowOpen ? 'chevron-right' : 'chevron-left'}
                  size={20}
                  color={darkMode ? 'white' : 'black'}
                />
              )}
              onPress={() => {
                // if single class page is open, close it because it glitches out otherwise
                navigation.goBack();
                setIsRightWindowOpen(!isRightWindowOpen);
              }}
              appearance="ghost"
              status="basic"
            >
              <Text style={{ color: '#000' }}>
                {isRightWindowOpen ? '' : 'Open List'}
              </Text>
            </Button>
          )}
        </View>
        <ScrollView
          horizontal
          snapToInterval={isThreeDay ? threeDayWidth : fiveDayWidth}
          showsHorizontalScrollIndicator={false}
          style={{ marginLeft: 75, height: isWeb ? 43 : undefined }}
          ref={topBar}
        >
          <View style={{ flexDirection: 'row' }}>
            {days.map((elem, index) => {
              return (
                <View key={index}>
                  <View
                    style={{
                      borderColor: darkMode ? '#7a7a7a' : '#525252',
                      borderBottomWidth: 1,
                      width: '100%',
                      borderRadius: index === today ? 5 : 0,
                      backgroundColor:
                        index === today ? theme['color-success-400'] : null
                    }}
                  >
                    <Animated.Text
                      style={{
                        textAlign: 'center',
                        // @ts-ignore
                        userSelect: isDividerClicked ? 'none' : 'auto',
                        width: isThreeDay ? threeDayWidth : fiveDayWidth,
                        color:
                          index === today
                            ? 'white'
                            : darkMode
                              ? 'white'
                              : 'black'
                      }}
                    >
                      {elem.day}
                    </Animated.Text>
                  </View>
                </View>
              );
            })}
          </View>
        </ScrollView>
        <ScrollView
          contentOffset={startingOffset}
          showsVerticalScrollIndicator={isWeb}
          bounces={false}
          ref={verticalRef}
        >
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                justifyContent: 'space-around'
              }}
            >
              {times.map((time, index) => {
                return (
                  <View
                    style={{
                      height: universalHeight,
                      width: 75,
                      borderTopWidth: 1,
                      backgroundColor: darkMode
                        ? '#2b2b2b'
                        : theme['color-basic-200'],
                      borderColor: darkMode ? '#7a7a7a' : '#525252'
                    }}
                  >
                    <Text
                      key={index}
                      style={{
                        textAlign: 'center',
                        color: darkMode ? '#fff' : '#525252',
                        // @ts-ignore
                        userSelect: isDividerClicked ? 'none' : 'auto'
                      }}
                    >
                      {time}
                    </Text>
                  </View>
                );
              })}
            </View>
            {/* Classes */}
            <ScrollView
              horizontal
              snapToInterval={isThreeDay ? threeDayWidth : fiveDayWidth}
              decelerationRate="normal"
              showsHorizontalScrollIndicator={isWeb}
              bounces={false}
              scrollEventThrottle={1}
              onScroll={(e) =>
                topBar.current.scrollTo({
                  x: e.nativeEvent.contentOffset.x,
                  animated: false,
                  useNativeDriver: true
                })
              }
              onMomentumScrollBegin={(e) =>
                topBar.current.scrollTo({
                  x: e.nativeEvent.contentOffset.x,
                  animated: false,
                  useNativeDriver: true
                })
              }
            >
              <View style={{ flexDirection: 'row' }}>
                {days.map((elem, index) => {
                  return (
                    <View key={index}>
                      {renderDashes()}
                      {/* Current Time Indicator */}
                      {index === today && (
                        <View
                          style={{
                            top:
                              universalHeight * new Date().getHours() +
                              new Date().getMinutes() * (universalHeight / 60),
                            position: 'absolute',
                            height: 2,
                            width: '100%',
                            backgroundColor: 'red',
                            justifyContent: 'center',
                            zIndex: 10
                          }}
                        >
                          <View
                            style={{
                              width: 10,
                              height: 10,
                              backgroundColor: 'red',
                              borderRadius: 5,
                              position: 'relative',
                              right: 4
                            }}
                          />
                        </View>
                      )}

                      <View
                        key={elem.day}
                        style={{
                          width: isThreeDay ? threeDayWidth : fiveDayWidth
                        }}
                      >
                        {masterRender(elem)}
                      </View>
                      <View
                        style={{
                          height: '100%',
                          width: 1,
                          backgroundColor: darkMode ? '#7a7a7a' : '#525252'
                        }}
                      />
                    </View>
                  );
                })}
              </View>
            </ScrollView>
          </View>
        </ScrollView>
        {Platform.OS !== 'web' && Object.entries(overrideData).length === 0 && (
          <SingleClassPage bottomSheetRef={bottomSheetRef} />
        )}
      </Animated.View>
      {!isMobile && isRightWindowOpen && (
        <View
          style={{ padding: 2, justifyContent: 'center', cursor: 'col-resize' }}
          {..._panResponder.current.panHandlers}
        >
          <View
            style={[
              {
                borderRadius: 20,
                width: DIVIDER_WIDTH,
                alignItems: 'center',
                justifyContent: 'center',
                paddingVertical: 30
                // @ts-ignore
              },
              isDividerClicked
                ? { backgroundColor: theme['color-basic-500'] }
                : { backgroundColor: theme['color-basic-300'] }
            ]}
          >
            <Entypo name="dots-three-vertical" size={20} />
          </View>
        </View>
      )}
      <Animated.View
        style={!isMobile && { width: isRightWindowOpen ? rightWidth : 0 }}
      >
        {!isMobile && <ClassListPage />}
      </Animated.View>
      {renderDeleteModal(menuCourseCode, handleRemoveClass, () => {
        setMenuCourseCode(false);
      })}
      {renderDeleteModal(menuCustomEvent, handleRemoveCustomEvent, () => {
        setMenuCustomEvent(false);
      })}
      {overrideData.length > 0 && renderOverlayOption()}
    </View>
  );
}
