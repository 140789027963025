import React from 'react';
import {
  Dimensions,
  Platform,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions
} from 'react-native';

import CalendarFunctions from './CalendarFunctions';
import { scale } from '../../functions';
import { useAppSelector } from '../../app/hooks';

export default function ScheduledItem({
  masterClassInfo,
  meetingTime,
  courseCode,
  handleClassSelected = (string: string) => {},
  setMenuCourseCode,
  disableLongPress = false,
  extraTransparency = false,
  dimensions
}) {
  const isMobile = useAppSelector((state) => state.appData.secure.isMobile);
  const calendarFun = new CalendarFunctions();
  const universalHeight = (dimensions.height - 50) / 12;
  const minuteHeight = universalHeight / 60;

  const [startHour, startMinute] = meetingTime.startTime.split(':');
  const { courseName } = masterClassInfo;
  const [endHour, endMinute] = meetingTime.endTime.split(':');
  const color = calendarFun.stringToColour(masterClassInfo.masterCourseCode);
  const code = courseCode.split(' ');

  return (
    <TouchableOpacity
      key={courseCode}
      style={{
        // @ts-ignore
        backgroundColor: extraTransparency ? 'rgba(0, 0, 0, 0.5)' : color,
        top: universalHeight * startHour + startMinute * minuteHeight,
        height:
          universalHeight * (endHour - startHour) +
          calendarFun.getMinutesDiff(startMinute, endMinute, minuteHeight),
        width: '100%',
        position: 'absolute',
        margin: 0,
        justifyContent: 'center'
      }}
      onPress={() => {
        handleClassSelected(masterClassInfo.masterCourseCode);
      }}
      onLongPress={() => {
        if (!disableLongPress) {
          setMenuCourseCode({ key: courseCode });
        }
      }}
    >
      {isMobile && (
        <Text
          adjustsFontSizeToFit
          numberOfLines={5}
          style={{
            color: parseInt(color.slice(1), 16) > 9079434 ? 'black' : 'white',
            fontSize: 12,
            fontWeight: 'bold',
            marginHorizontal: 0.5,
            textAlign: 'center'
          }}
        >
          {code[0]} {code[1]}
          {'\n'}
          {code[2]}
          {/* {meetingTime.classLocation
            ? `\n(${meetingTime.classLocation})`
            : null} */}
          <Text style={{ fontSize: 12, fontWeight: 'normal' }}>
            {`\n\n(${courseName})`}
          </Text>
        </Text>
      )}
      {!isMobile && (
        <View style={{ flexDirection: 'column' }}>
          <Text
            style={{
              color: parseInt(color.slice(1), 16) > 9079434 ? 'black' : 'white',
              fontSize:
                scale(4, dimensions.width) < 15
                  ? scale(4, dimensions.width)
                  : 16,
              fontWeight: 'bold',
              marginHorizontal: 3,
              textAlign: 'center'
            }}
          >
            {code[0]} {code[1]} {code[2]}
          </Text>
          <Text
            style={{
              color: parseInt(color.slice(1), 16) > 9079434 ? 'black' : 'white',
              fontSize:
                scale(4, dimensions.width) < 13
                  ? scale(4, dimensions.width)
                  : 13,
              marginHorizontal: 3,
              textAlign: 'center',
              marginTop: 5
            }}
          >
            {/* {meetingTime.classLocation
              ? `(${meetingTime.classLocation})`
              : null} */}
            {`${courseName}`}
          </Text>
        </View>
      )}
    </TouchableOpacity>
  );
}
