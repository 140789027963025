import { FontAwesome } from '@expo/vector-icons';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
  Card,
  Divider,
  IndexPath,
  Layout,
  Select,
  SelectItem
} from '@ui-kitten/components';
import * as ImagePicker from 'expo-image-picker';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import {
  Dimensions,
  Image,
  KeyboardAvoidingView,
  Platform,
  RefreshControl,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from 'react-native';
import { LineChart } from 'react-native-chart-kit';

import { selectUserKey } from '../../app/appDataSlice';
import { useAppSelector } from '../../app/hooks';
import { base_URL } from '../../constants/admin';

const AdminHomePage = createNativeStackNavigator();

export default function AdminHomeNavigation() {
  const [adminCommands, setAdminCommands] = useState([]);
  const userKey = useAppSelector(selectUserKey);
  useEffect(() => {
    getAdminCommands();
  }, []);
  async function getAdminCommands() {
    const adminURL = `${base_URL}/adminCommands`;
    const res = await fetch(`${adminURL}/getAdminCommands`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        userKey
      }
    });
    let commands: any[] = await res.json();
    const filter = await fetch(`${adminURL}/toggleAdminCommands`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        userKey
      },
      body: '{}'
    });
    const filterObject = (await filter.json()).currentAdminCommandsStatus;
    commands = commands.sort((a, b) => {
      const textA = a.label.toUpperCase();
      const textB = b.label.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    const finalCommandList = [];
    for (const command of commands) {
      if (filterObject[command.label]) {
        finalCommandList.push(command);
      }
    }
    // @ts-ignore
    setAdminCommands(finalCommandList);
    return commands;
  }

  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setAdminCommands([]);
    getAdminCommands().then((commands) => setRefreshing(false));
  }, []);

  function AdminHome({ navigation }) {
    return (
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        {adminCommands?.length === 0 ? <Text>No Commands Yet. Rip</Text> : null}
        {adminCommands?.map((command) => {
          return (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('AdminCommand', { data: command });
              }}
              style={listingStyles.cardContainer}
              key={command.url}
            >
              <Text style={listingStyles.text}>{command.label}</Text>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    );
  }

  return (
    <AdminHomePage.Navigator initialRouteName="AdminHome">
      <AdminHomePage.Screen
        options={{
          // title: 'Admin Home',
          /* headerTitle: () => {
                        return adminControlPanelHeader;
                    }, */
          headerShown: false
        }}
        name="AdminHome"
        component={AdminHome}
      />
      <AdminHomePage.Screen
        name="AdminCommand"
        component={AdminCommand}
        options={{ headerTitle: '' }}
      />
    </AdminHomePage.Navigator>
  );
}

function AdminCommand({
  route: {
    params: { data }
  }
}) {
  const [info, setInfo] = useState({});
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [body, setBody] = useState({});
  const [users, setUsers] = useState([]);
  const [fileUploads, setFileUploads] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [status, setStatus] = useState('0');
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [buttonPushed, setButtonPushed] = useState(false); // refresh button
  const userKey = useAppSelector(selectUserKey);

  useEffect(() => {
    // Check for first button render
    checkForFieldsFilled(body);
    // Fetch User List
    fetch(`${base_URL}/users/getUserNameList`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        userKey
      }
    }).then(async (data) => {
      if (data.ok) {
        const newUsers = (await data.json()).map((userName) => {
          return {
            label: userName,
            id: userName
          };
        });
        setUsers(newUsers);
      }
    });
    // Fetch Sponsor List
    fetch(`${base_URL}/sponsors/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        userKey
      }
    }).then(async (data) => {
      if (data.ok) {
        const sponsors = (await data.json()).map((sponsor) => {
          return {
            label: sponsor.sponsorName,
            id: sponsor._id
          };
        });
        setSponsors(sponsors);
      }
    });
    // Fetch File upload List
    fetch(`${base_URL}/adminCommands/getReferenceImages`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        userKey
      }
    }).then(async (data) => {
      if (data.ok) {
        const images = (await data.json()).map((imageName) => {
          return {
            label: imageName,
            id: imageName
          };
        });
        setFileUploads(images);
      }
    });
  }, []);

  function checkForFieldsFilled(body) {
    for (const field of data.fields) {
      if (field.mandatory) {
        if (body[field.id] === undefined || body[field.id] === null) {
          setAllFieldsFilled(false);
          return;
        }
      }
    }
    setAllFieldsFilled(true);
  }

  const sendApiData = (data, userKey) => {
    let additionToUrl = '';
    for (const aspect in body) {
      if (body[aspect].paramOverride) {
        additionToUrl += body[aspect];
        break;
      }
    }
    if (JSON.stringify(body, null, 2) !== '{}' && data.apiType === 'GET') {
      for (const aspect in body) {
        if (additionToUrl === '') {
          additionToUrl = '?';
        } else {
          additionToUrl += '&';
        }
        additionToUrl += `${aspect}=${body[aspect]}`;
      }
    }
    const newUrl = data.url + additionToUrl;
    if (!isFileUpload) {
      fetch(newUrl, {
        method: data.apiType,
        headers: {
          'Content-Type': 'application/json',
          userKey
        },
        body:
          JSON.stringify(body, null, 2) !== '{}' && data.apiType !== 'GET'
            ? JSON.stringify(body)
            : undefined
      }).then((res) => {
        // @ts-ignore
        setStatus(res.status);
        res.text().then((text) => {
          try {
            const text2 = JSON.parse(text);
            setInfo(text2);
          } catch (err) {
            setInfo(text);
          }
        });
      });
    } else {
      const formdata = new FormData();
      for (const item in body) {
        if (!item.includes('picture')) {
          formdata.append(item, body[item]);
        } else {
          formdata.append(item, {
            // @ts-ignore
            uri: body[item].uri,
            name: item,
            type: 'image/jpg'
          });
        }
      }
      fetch(newUrl, {
        body: formdata,
        redirect: 'follow',
        method: data.apiType,
        headers: {
          userKey
        }
      }).then((res) => {
        // @ts-ignore
        setStatus(res.status);
        res.text().then((text) => {
          try {
            const text2 = JSON.parse(text);
            setInfo(text2);
          } catch (err) {
            setInfo(text);
          }
        });
      });
    }
  };

  if (buttonPushed) {
    setButtonPushed(false);
    sendApiData(data, userKey);
  }

  function renderEachDropItem(field) {
    if (field.data) {
      // This is where we can add preset lists if we can't use something hardcoded on the server
      switch (field.data) {
        case 'users': {
          field.options = users;
          break;
        }
        case 'sponsors': {
          field.options = sponsors;
          break;
        }
        case 'fileUploads': {
          field.options = fileUploads;
          break;
        }
      }
    }
    const final = [];
    for (const option of field.options) {
      final.push(<SelectItem title={option.label} />);
    }
    return final;
  }

  function renderTheFieldsNecessary(fields) {
    const changeValue = (theValue, valueToChange) => {
      const newBody = body;
      for (const aspect in newBody) {
        if (
          newBody[aspect] === null ||
          newBody[aspect] === undefined ||
          newBody[aspect] === ''
        ) {
          delete newBody[aspect];
        }
      }
      if (theValue !== undefined) {
        newBody[valueToChange] = theValue;
      } else if (newBody[valueToChange]) {
        delete newBody[valueToChange];
      }
      setBody(newBody);
      checkForFieldsFilled(newBody);
    };

    const fileUploadTemplate = (field) => {
      const pickImage = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
          allowsEditing: false
        });
        if (!result.cancelled) {
          changeValue(result, field.id);
        }
      };

      return (
        <View>
          {/* Image Picker */}
          <TouchableOpacity
            onPress={() => {
              pickImage();
            }}
            style={{
              height: 80,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 8,
              paddingVertical: 8,
              flexDirection: 'row',
              backgroundColor: '#dbdbdb',
              marginVertical: 15,
              marginHorizontal: 5
            }}
          >
            <Layout
              style={{
                flex: 1,
                flexDirection: 'row',
                height: '100%',
                marginHorizontal: 10,
                backgroundColor: '#dbdbdb'
              }}
            >
              <Layout
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  borderRadius: 10,
                  backgroundColor: '#bfbfbf'
                }}
              >
                {body && body[field?.id] ? (
                  <Image
                    source={{ uri: body[field.id].uri }}
                    style={{ width: '90%', height: '90%', borderRadius: 10 }}
                  />
                ) : (
                  <FontAwesome name="image" size={40} color="grey" />
                )}
              </Layout>
              <Layout
                style={{
                  backgroundColor: '#dbdbdb',
                  flex: 4,
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Text
                  style={{
                    fontSize: 17
                  }}
                >
                  {body && body[field?.id]
                    ? 'Select Another Image'
                    : 'Select Image'}
                </Text>
              </Layout>
            </Layout>
          </TouchableOpacity>
        </View>
      );
    };
    const renderFilterParameters = (fields) => {
      if (fields.length === 0) {
        return null;
      }
      return (
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={{
              fontSize: 15,
              paddingLeft: 12,
              paddingBottom: 5,
              paddingTop: 5,
              alignContent: 'center',
              fontWeight: 'bold'
            }}
          >
            Filters/Parameters
          </Text>
        </View>
      );
    };
    const final = [];

    function renderStringArray(field) {
      const valuesToSend = {};
      function changeStringValue(theValue, miniField, field) {
        valuesToSend[miniField] = theValue;
        const newFieldForBody = [];
        for (const value of Object.values(valuesToSend)) {
          newFieldForBody.push(value);
        }
        changeValue(newFieldForBody, field.id);
      }
      const tempFinal = [];
      tempFinal.push(
        <Text
          style={{
            fontSize: 14,
            paddingLeft: 5,
            paddingBottom: 3,
            paddingTop: 3
          }}
        >
          {field.mandatory ? `${field.label} (mandatory)` : field.label}
        </Text>
      );
      for (const eachOne in field.stringArray) {
        tempFinal.push(
          <TextInput
            autoCapitalize="none"
            onChangeText={(theValue) =>
              changeStringValue(
                theValue,
                field.stringArray[eachOne].label,
                field
              )
            }
            style={myStyles.indentedInput}
            placeholderTextColor="gray"
            placeholder={
              field.stringArray[eachOne].mandatory
                ? `${field.stringArray[eachOne].label} (mandatory)`
                : field.stringArray[eachOne].label
            }
          />
        );
      }
      return tempFinal;
    }

    const renderMultiSelectDropDown = (field) => {
      const [selectedIndex, setSelectedIndex] = useState([]);
      const [hasChosen, setHasChosen] = useState(false);
      function toggleValue(index) {
        setHasChosen(true);
        setSelectedIndex(index);
        const newSelection = Array.isArray(index)
          ? index.map((littleIndex) => {
              return field.options[littleIndex.row].id;
            })
          : field.options[index.row].id;
        changeValue(newSelection, field.id);
      }
      function calculateSelectedValues() {
        const finalArray = selectedIndex.map((littleIndex) => {
          return `${field.options[littleIndex.row].label}, `;
        });
        if (finalArray.length > 0) {
          let finalObject = finalArray.at(-1);
          finalObject = finalObject.replace(', ', '');
          finalArray.splice(-1, 1);
          finalArray.push(finalObject);
          return finalArray;
        }
        return field.mandatory ? `${field.label} (mandatory)` : field.label;
      }
      return (
        <Layout style={myStyles.containerDrop} level="1">
          <Select
            multiSelect
            value={hasChosen ? calculateSelectedValues() : field.label}
            selectedIndex={selectedIndex}
            onSelect={(index) => toggleValue(index)}
          >
            {renderEachDropItem(field)}
          </Select>
        </Layout>
      );
    };

    const renderDropDown = (field) => {
      const [selectedIndex, setSelectedIndex] = useState(new IndexPath(0));
      const [hasChosen, setHasChosen] = useState(false);

      function currentValue() {
        return (
          <Text>
            {!hasChosen
              ? `Choose ${field.label}${field.mandatory ? ' (mandatory)' : ''}`
              : field.options[selectedIndex.row - 1].label}{' '}
          </Text>
        );
      }

      function handleSelection(index) {
        setSelectedIndex(index);
        changeValue(
          index.row === 0 ? undefined : field.options[index.row - 1].id,
          field.id
        );
        if (index.row === 0) {
          setHasChosen(false);
        } else {
          setHasChosen(true);
        }
      }

      return (
        <Select
          style={myStyles.containerDrop}
          value={currentValue}
          selectedIndex={selectedIndex}
          onSelect={handleSelection}
        >
          <SelectItem title={' '} />
          {renderEachDropItem(field)}
        </Select>
      );
    };

    for (let i = 0; i < fields.length; i++) {
      const { type } = fields[i];
      if (type === 'Boolean') {
        final.push(trueFalseTemplate(fields[i]));
      } else if (type === 'String') {
        final.push(
          <TextInput
            autoCapitalize="none"
            clearButtonMode="always"
            onChangeText={(theValue) => changeValue(theValue, fields[i].id)}
            style={myStyles.input}
            placeholderTextColor="gray"
            placeholder={
              fields[i].mandatory
                ? `${fields[i].label} (mandatory)`
                : fields[i].label
            }
          />
        );
      } else if (type === 'ArrayString') {
        final.push(renderStringArray(fields[i]));
      } else if (type === 'DropDown') {
        final.push(renderDropDown(fields[i]));
      } else if (type === 'MultiSelectDropDown') {
        final.push(renderMultiSelectDropDown(fields[i]));
      } else if (type === 'BigString') {
        final.push(
          <TextInput
            autoCapitalize="sentences"
            clearButtonMode="always"
            onChangeText={(theValue) => changeValue(theValue, fields[i].id)}
            style={myStyles.inputBig}
            multiline
            placeholderTextColor="gray"
            placeholder={
              fields[i].mandatory
                ? `${fields[i].label} (mandatory)`
                : fields[i].label
            }
          />
        );
      } else if (type === 'FileUpload') {
        if (!isFileUpload) {
          setIsFileUpload(true);
        }
        final.push(fileUploadTemplate(fields[i]));
      }
    }
    return (
      <View>
        {renderFilterParameters(fields)}
        <View>{final}</View>
      </View>
    );
  }

  const trueFalseTemplate = (field) => {
    const [selectedIndex, setSelectedIndex] = useState();
    const boolValues = ['false', 'true'];
    // @ts-ignore
    const displayValue = boolValues[selectedIndex - 1];
    const renderOption = (displayValue) => <SelectItem title={displayValue} />;
    const changeEverything = (value) => {
      setSelectedIndex(value);
      if (value.row === 0) {
        const newBody = body;
        newBody[field.id] = false;
        setBody(newBody);
      } else if (value.row === 1) {
        const newBody = body;
        newBody[field.id] = true;
        setBody(newBody);
      }
    };
    return (
      <Layout style={styles.container} level="1">
        <Select
          label={field.label}
          selectedIndex={selectedIndex}
          value={displayValue}
          onSelect={(index) => changeEverything(index)}
          placeholder={
            field.mandatory ? `${field.label} (mandatory)` : `${field.label}`
          }
        >
          {boolValues.map(renderOption)}
        </Select>
      </Layout>
    );
  };

  function showResponseData(actions) {
    if (JSON.stringify(info, null, 2) === '{}') {
      return null;
    }
    const informationToShow = [];

    // use this one for displaying everything pretty
    if (Array.isArray(info)) {
      if (data.isDateGraph) {
        // check for need for rendering graphs
        const timeFrames = [
          '2week-unique',
          '2week-total',
          'month-unique',
          '6month-total'
        ];
        for (const item of timeFrames) {
          const dataToShow = {
            labels: getDates(item),
            datasets: [
              {
                data: getData(item)
              }
            ]
          };
          informationToShow.push(renderOneGraph(dataToShow, item));
        }
      }
      for (const oneElement of info) {
        informationToShow.push(renderOneCard(oneElement, actions || []));
      }
    } else {
      informationToShow.push(
        <Text style={{ flexShrink: 1 }}>
          {typeof info === 'string' ? info : JSON.stringify(info, null, 2)}
        </Text>
      );
    }

    function getData(timeFrame) {
      const final = [];
      switch (timeFrame) {
        case '2week-unique': {
          for (let i = 13; i >= 0; i--) {
            const day = moment()
              .tz('America/Los_Angeles')
              .subtract(i, 'days')
              .format('YYYY-MM-DD');
            let counter = 0;
            const usersToday = [];
            // @ts-ignore
            for (const instance of info) {
              const { userName } = instance;
              if (instance.date === day && !usersToday.includes(userName)) {
                usersToday.push(userName);
                counter++;
              }
            }
            final.push(counter);
          }
          break;
        }
        case '2week-total': {
          for (let i = 13; i >= 0; i--) {
            const day = moment()
              .tz('America/Los_Angeles')
              .subtract(i, 'days')
              .format('YYYY-MM-DD');
            let counter = 0;
            const usersToday = [];
            // @ts-ignore
            for (const instance of info) {
              const { userName } = instance;
              if (instance.date === day) {
                usersToday.push(userName);
                counter++;
              }
            }
            final.push(counter);
          }
          break;
        }
        case 'month-unique': {
          for (let i = 4; i > 0; i--) {
            const weekStart = moment()
              .tz('America/Los_Angeles')
              .subtract(i, 'weeks')
              .format('YYYY-MM-DD');
            const weekEnd = moment()
              .tz('America/Los_Angeles')
              .subtract(i - 1, 'weeks')
              .format('YYYY-MM-DD');
            let counter = 0;
            const usersMonth = [];
            // @ts-ignore
            for (const instance of info) {
              if (
                instance.date > weekStart &&
                instance.date <= weekEnd &&
                !usersMonth.includes(instance.userName)
              ) {
                counter++;
                usersMonth.push(instance.userName);
              }
            }
            final.push(counter);
          }
          break;
        }
        case '6month-total': {
          for (let i = 6; i > 0; i--) {
            const monthStart = moment()
              .tz('America/Los_Angeles')
              .subtract(i, 'months')
              .format('YYYY-MM-DD');
            const monthEnd = moment()
              .tz('America/Los_Angeles')
              .subtract(i - 1, 'months')
              .format('YYYY-MM-DD');
            let counter = 0;
            // @ts-ignore
            for (const instance of info) {
              if (instance.date > monthStart && instance.date <= monthEnd) {
                counter++;
              }
            }
            final.push(counter);
          }
          break;
        }
      }
      return final;
    }

    function getDates(timeFrame) {
      const final = [];
      switch (timeFrame) {
        case '2week-unique': {
          for (let i = 13; i >= 0; i--) {
            final.push(
              moment()
                .tz('America/Los_Angeles')
                .subtract(i, 'days')
                .format('MM/DD')
            );
          }
          break;
        }
        case '2week-total': {
          for (let i = 13; i >= 0; i--) {
            final.push(
              moment()
                .tz('America/Los_Angeles')
                .subtract(i, 'days')
                .format('MM/DD')
            );
          }
          break;
        }
        case 'month-unique': {
          for (let i = 4; i > 0; i--) {
            final.push(
              `${moment()
                .tz('America/Los_Angeles')
                .subtract(i, 'weeks')
                .add(1, 'days')
                .format('MM/DD')}-${moment()
                .tz('America/Los_Angeles')
                .subtract(i - 1, 'weeks')
                .format('MM/DD')}`
            );
          }
          break;
        }
        case '6month-total': {
          for (let i = 6; i > 0; i--) {
            final.push(
              `${moment()
                .tz('America/Los_Angeles')
                .subtract(i, 'months')
                .add(1, 'days')
                .format('MM/DD')}-${moment()
                .tz('America/Los_Angeles')
                .subtract(i - 1, 'months')
                .format('MM/DD')}`
            );
          }
          break;
        }
      }
      return final;
    }

    function renderOneGraph(graphData, timeFrame) {
      return (
        <View style={{ paddingBottom: 10 }}>
          <Text>
            Timeframe:
            {timeFrame}
          </Text>
          <LineChart
            data={graphData}
            width={Dimensions.get('window').width}
            height={400}
            verticalLabelRotation={60}
            fromZero
            xLabelsOffset={-10}
            segments={8}
            chartConfig={{
              backgroundColor: '#bdbdbd',
              decimalPlaces: 0,
              color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
              labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
              style: {
                borderRadius: 16
              },
              propsForDots: {
                r: '6',
                strokeWidth: '2',
                stroke: '#333333'
              }
            }}
          />
        </View>
      );
    }

    function renderOneCard(cardInfo, actions) {
      const finalText = [];
      for (const aspect in cardInfo) {
        if (aspect !== '_id' && aspect !== '__v') {
          finalText.push(
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              <Text style={{ fontWeight: 'bold' }}>{`${aspect} : `}</Text>
              <Text>{JSON.stringify(cardInfo[aspect], null, '\t')}</Text>
            </View>
          );
        }
      }
      let renderedActions = null;
      // @ts-ignore
      if (actions !== []) {
        renderedActions = [];
        for (const action of actions) {
          renderedActions.push(renderButtonForCard(action, cardInfo));
        }
      }

      function renderButtonForCard(action, cardInfo) {
        return (
          <View style={{ marginHorizontal: 20 }}>
            <TouchableOpacity
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
                height: 40,
                backgroundColor: action.color ? action.color : 'gray',
                elevation: 3
              }}
              onPress={() => {
                const params = {};
                for (const parameter of action.params) {
                  params[parameter] = cardInfo[parameter]
                    ? cardInfo[parameter]
                    : undefined;
                }
                fetch(action.url, {
                  method: action.method,
                  headers: {
                    'Content-Type': 'application/json',
                    userKey
                  },
                  body: JSON.stringify(params)
                }).then((res) => {
                  if (res.ok) {
                    setButtonPushed(true);
                  }
                });
              }}
            >
              {/* @ts-ignore */}
              <Text style={myStyles.buttonText}>{action.label}</Text>
            </TouchableOpacity>
          </View>
        );
      }

      return (
        <View>
          <Card>
            {finalText}
            <View
              style={{
                paddingTop: 15,
                width: '95%',
                flexDirection: 'row',
                alignSelf: 'center',
                alignContent: 'center',
                justifyContent: 'center'
              }}
            >
              {renderedActions}
            </View>
          </Card>
          <Divider />
        </View>
      );
    }

    return (
      <View style={{ flexShrink: 1 }}>
        <Text
          style={{
            fontSize: 20,
            paddingLeft: 20,
            paddingBottom: 0,
            paddingTop: 20,
            fontWeight: 'bold'
          }}
        >
          Server Response
        </Text>
        <Text
          style={{
            fontSize: 13,
            paddingLeft: 20,
            paddingBottom: 10,
            paddingTop: 5,
            fontStyle: 'italic'
          }}
        >
          Status Code: {status}
        </Text>
        <View style={{ paddingTop: 10, backgroundColor: 'lightgray' }}>
          {informationToShow}
        </View>
      </View>
    );
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <ScrollView style={{ height: '100%' }}>
        <View style={sponsorPageStyles.container}>
          <View
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <Text style={sponsorPageStyles.title}>{data.label}</Text>
          </View>

          {data.url && (
            // @ts-ignore
            <View style={sponsorPageStyles.address}>
              <Text style={{ fontSize: 18 }}>{data.url}</Text>
            </View>
          )}
          <View style={sponsorPageStyles.description}>
            {renderTheFieldsNecessary(data.fields)}
          </View>
          {allFieldsFilled && (
            <TouchableOpacity
              onPress={() => {
                sendApiData(data, userKey);
                // @ts-ignore
              }}
              style={myStyles.button}
            >
              <Text style={myStyles.text}>Send Call</Text>
            </TouchableOpacity>
          )}
          {showResponseData(data.actions)}
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const sponsorPageStyles = {
  container: {
    flex: 1,
    backgroundColor: 'white'
    // alignItems: 'center',
  },
  header: {
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  address: {
    alignItems: 'center',
    margin: 10,
    borderColor: 'black',
    borderWidth: 1,
    padding: 10
  },
  video: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  title: {
    fontSize: 26,
    marginLeft: 10
  },
  logo: {
    width: '100%',
    height: 100
  },
  description: {
    borderWidth: 1,
    margin: 10,
    borderColor: '#5d5c5c',
    borderRadius: 5,
    width: '95%',
    padding: 10,
    backgroundColor: 'rgba(178,166,166,0.22)'
  },
  couponContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20
  },
  coupon: {
    width: 400,
    height: 267
  },
  imageGallery: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 10,
    marginBottom: 0
  },
  imageStyle: {
    width: 150,
    height: 150,
    paddingTop: 100,
    margin: 5
  },
  headerText: {
    fontSize: 20,
    fontWeight: 'bold'
  }
};

const myStyles = {
  inputBig: {
    height: 200,
    backgroundColor: 'white',
    margin: 12,
    borderWidth: 1,
    padding: 10
  },
  input: {
    backgroundColor: '#ffffff',
    height: 50,
    margin: 12,
    borderWidth: 1,
    padding: 10
  },
  containerDrop: {
    backgroundColor: '#ffffff',
    height: 42,
    margin: 12,
    borderWidth: 1
  },
  indentedInput: {
    backgroundColor: '#ffffff',
    height: 50,
    marginVertical: 12,
    borderWidth: 1,
    padding: 10,
    marginRight: 12,
    marginLeft: 30
  },
  text: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: 'white'
  },
  buttonText: {
    fontSize: 13,
    flexWrap: 'wrap',
    lineHeight: 15,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: 'white'
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: 'black'
  }
};

const listingStyles = {
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center'
  },
  cardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    width: '95%',
    height: 75,
    backgroundColor: '#bbbbbb',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ddd',
    borderBottomWidth: 0,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 1,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 10
  },
  text: {
    flex: 1,
    fontSize: 20,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 5
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 10
  }
};

const styles = {
  imagesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: 10,
    paddingTop: 20
  },
  imageContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  imageLabel: {
    fontSize: 12,
    color: 'black',
    padding: 10,
    textAlign: 'center'
  },
  cardContainer: {
    backgroundColor: '#FFF',
    borderWidth: 0,
    flex: 1,
    justifyContent: 'center',
    margin: 0,
    padding: 0
  },
  container: {
    flex: 1
  }
};
