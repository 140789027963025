import { useNetInfo } from '@react-native-community/netinfo';
import { Button, Input, Layout, Spinner, Text } from '@ui-kitten/components';
import { useEffect, useRef, useState } from 'react';
import { Keyboard } from 'react-native';

import { useResetPasswordMutation } from '../../api/usersApi';

export default function EnterEmailPage({ navigation }) {
  const [email, setEmail] = useState('');
  const inputRef = useRef<Input>(null);
  const [resetPassword, { isLoading, isError }] = useResetPasswordMutation();
  const netInfo = useNetInfo();

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef.current]);

  const onSubmit = async () => {
    if (!netInfo.isConnected) {
      alert('Connect to the internet to reset your password');
      return;
    }

    try {
      await resetPassword(email).unwrap();
      navigation.navigate('EnterCodePage');
    } catch (e) {
      console.warn('Error submitting forgot password email', e);
    }
  };

  return (
    <Layout style={{ flex: 1, paddingTop: 30, alignItems: 'center' }}>
      <Text style={{ margin: 10 }} category="s1">
        Enter your email to get started
      </Text>

      <Input
        autoCapitalize="none"
        placeholder="Email"
        ref={inputRef}
        style={{ margin: 10 }}
        onChangeText={setEmail}
        value={email}
        onSubmitEditing={Keyboard.dismiss}
      />
      {isError && (
        <Text style={{ color: 'red' }}>Email is invalid. Try again</Text>
      )}
      <Button
        style={{ margin: 10 }}
        onPress={onSubmit}
        disabled={isLoading}
        accessoryRight={() => (isLoading ? <Spinner status="basic" /> : null)}
      >
        Submit
      </Button>
    </Layout>
  );
}
