import { Layout, Text, Input, Spinner } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { userKeyUpdated } from '../../../app/appDataSlice';
import { useAppDispatch } from '../../../app/hooks';
import {
  useVerifySignupMutation,
  useLazyGetUserInfoQuery
} from '../../api/usersApi';

export default function VerifyEmail() {
  const [verificationCode, setVerificationCode] = React.useState('');
  const [verifyCode, { isError }] = useVerifySignupMutation();
  const [getUserInfo, { isLoading: getUserInfoLoading }] =
    useLazyGetUserInfoQuery();
  const dispatch = useAppDispatch();

  const handleVerifyCodeButton = async () => {
    try {
      const { password } = await verifyCode(verificationCode).unwrap();
      dispatch(userKeyUpdated(password));
      await getUserInfo(password).unwrap();
    } catch (error) {
      console.warn("Couldn't verify code", error);
    }
  };

  return (
    <Layout style={styles.container}>
      <Text style={styles.text}>
        A verification code has been sent to your email. Please check your email
        and enter the code below.
      </Text>
      {getUserInfoLoading && <Spinner />}
      {isError && <Text>Error verifying code</Text>}
      <Input
        style={styles.input}
        placeholder="Verification Code"
        keyboardType="numeric"
        onChangeText={(text) => setVerificationCode(text)}
      />
      <TouchableOpacity style={styles.button} onPress={handleVerifyCodeButton}>
        <Text style={styles.buttonText}>Finish Signup</Text>
      </TouchableOpacity>
    </Layout>
  );
}

const styles = StyleSheet.create({
  SectionStyle: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10
  },
  buttonStyle: {
    backgroundColor: '#b42bba',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#7DE24E',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 20
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16
  },
  inputStyle: {
    flex: 1,
    color: 'black',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#dadae8'
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14
  },
  successTextStyle: {
    color: 'black',
    textAlign: 'center',
    fontSize: 18,
    padding: 30
  },
  description: {
    color: 'black',
    textAlign: 'center',
    fontSize: 18,
    padding: 30
  },
  label: {
    color: 'black',
    textAlign: 'center',
    fontSize: 15,
    paddingLeft: 10,
    paddingRight: 10
  },
  labelSection: {
    justifyContent: 'center'
  },
  container: {
    flex: 1,
    alignItems: 'center'
  },

  text: {
    fontSize: 20,
    margin: 20
  },
  input: {
    width: '80%',
    margin: 20,
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 20,
    height: 40,
    padding: 10
  },
  button: {
    width: '80%',
    margin: 20,
    alignItems: 'center',
    backgroundColor: '#b42bba',
    height: 40,
    borderRadius: 20,
    borderColor: '#b42bba',
    justifyContent: 'center'
  },
  buttonText: {
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white'
  },
  containerDrop: {
    minHeight: 128,
    minWidth: 200
  }
});
