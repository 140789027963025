import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Button, Input, Layout, List, Text } from '@ui-kitten/components';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View
} from 'react-native';
import { SwipeRow } from 'react-native-swipe-list-view';

import { HeaderBackButton } from '@react-navigation/elements';
import {
  selectDarkMode,
  selectIsGuest,
  selectUserKey
} from '../../app/appDataSlice';
import { useAppSelector } from '../../app/hooks';
import BackButton from '../../components/BackButton';
import { base_URL } from './constants';

import ModalContainer from '../../components/ModalContainer';
import UserNameSelectionDropDown from '../../components/UserNameSelectionDropDown';
import { useGetCurrentSectionQuery } from '../api/classPagesApi';
import { selectClassesList } from '../classPages/classPagesSlice';
import SingleClassPage from './notificationSinglePage';
import CalendarRender from '../calendar/CalendarRender';
import Header from '../../components/Header';

const NotificationHomePage = createNativeStackNavigator();

export default function NotificationsNavigation({ navigation }) {
  const [notifications, setNotifications] = useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState([]);
  const [hasChosen, setHasChosen] = useState(false);
  const [users, setUsers] = useState([]);
  const [showGuestModal, setShowGuestModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const userKey = useAppSelector(selectUserKey);
  const darkMode = useAppSelector(selectDarkMode);
  const isGuest = useAppSelector(selectIsGuest);

  useEffect(() => {
    refreshNotifications();
    fetch(`${base_URL}/users/getUserNameList`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        userKey
      }
    }).then(async (data) => {
      if (data.ok) {
        const newUsers = (await data.json()).map((userName) => {
          return {
            label: userName,
            id: userName
          };
        });
        setUsers(newUsers);
      }
    });
  }, []);
  async function refreshNotifications() {
    fetch(`${base_URL}/users/getNotifications`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        userKey
      }
    }).then((res) => {
      res.text().then((notifications) => {
        notifications = JSON.parse(notifications);
        // @ts-ignore
        setNotifications(notifications);
      });
    });
  }
  async function sendNotification(bodyTosend) {
    await fetch(`${base_URL}/users/sendNotification`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        userKey
      },
      body: JSON.stringify(bodyTosend)
    });
  }
  function createNotificationButton() {
    navigation.navigate('CreateNotification');
  }
  function clearNotifications() {
    fetch(`${base_URL}/users/clearNotifications`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        userKey
      }
    }).then(() => {
      refreshNotifications();
    });
  }
  const navigationHeader = (navigation) => (
    <Layout
      style={{
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row',
        backgroundColor: 'transparent'
      }}
    >
      {Platform.OS === 'ios' && <BackButton navigation={navigation} />}

      <Layout
        style={{
          paddingBottom: 5,
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: 'transparent'
        }}
      >
        {/* <Image
          style={{
            height: 28,
            width: 28,
          }}
          source={require('../../../assets/logos/app-logo.png')}
        /> */}
        <Text
          style={{
            paddingTop: 3,
            fontSize: 20,
            fontWeight: '500',
            color: darkMode ? '#fff' : '#000'
          }}
        >
          Notifications
        </Text>
      </Layout>
      <Layout
        style={{
          alignItems: 'flex-end',
          paddingRight: 40,
          paddingBottom: 10,
          flexDirection: 'row',
          backgroundColor: 'transparent'
        }}
      >
        <TouchableOpacity
          style={{
            paddingLeft: 20,
            marginRight: Platform.OS == 'android' ? 50 : 0
          }}
          onPress={() => {
            if (isGuest) {
              setShowGuestModal(true);
            } else {
              createNotificationButton();
            }
          }}
        >
          <FontAwesome5 size={25} color="#1E90FF" name="plus" />
        </TouchableOpacity>
        {showGuestModal && (
          <ModalContainer
            title="Wait!"
            children={
              <Text style={{ paddingHorizontal: 30 }}>
                This feature is locked to our Guest Users. Please create a free
                account with us to take advantage this feature.
              </Text>
            }
            backDropVisible={showGuestModal}
            setBackDropVisible={setShowGuestModal}
          />
        )}
        {/* <TouchableOpacity

          onPress={() => {
            Alert.alert(
              'Caution',
              'Are you sure you want to clear your notifications?\nThis action cannot be undone.',
              [
                {
                  text: 'No',
                  style: 'cancel',
                },
                {
                  text: 'Yes',
                  onPress: async () => {
                    clearNotifications();
                  },
                },
              ]
            );
          }}>
          <FontAwesome5 size={25} color={'#d71717'} name="times" />
        </TouchableOpacity>
        /* <TouchableOpacity
          style={{ paddingLeft: 20 }}
          onPress={() => {
            refreshNotifications();
          }}>
          <FontAwesome size={25} color={'#1E90FF'} name="rotate-right" />
        </TouchableOpacity> */}
      </Layout>
    </Layout>
  );
  function Notifications({ navigation }) {
    const [refreshing, setRefreshing] = useState(false);
    const [isMoving, setIsMoving] = useState(false);

    const onRefresh = useCallback(() => {
      setRefreshing(true);
      refreshNotifications().then(() => {
        setRefreshing(false);
      });
    }, []);
    async function handleOnLeftSwipe(postId) {
      const newNotifications = notifications.map((not) => {
        if (not._id !== postId) {
          return not;
        }
      });
      setNotifications(newNotifications);
      const res = await fetch(`${base_URL}/users/removeNotification`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          userKey
        },
        body: JSON.stringify({
          id: postId
        }),
        redirect: 'follow'
      });
      if (res.ok) {
        refreshNotifications();
      } else {
        console.warn(await res.json());
      }
      setIsOpen(false);
      setIsMoving(false);
    }

    const renderItem = ({ item: notification }) => {
      return (
        <Layout>
          {notification && (
            // @ts-ignore
            <SwipeRow
              disableRightSwipe
              onSwipeValueChange={(status) => {
                if (status.value < -Dimensions.get('window').width * (1 / 3)) {
                  if (!isOpen) {
                    setIsOpen(true);
                    handleOnLeftSwipe(notification._id);
                  }
                } else if (status.value < -5) {
                  setIsMoving(true);
                } else {
                  setIsMoving(false);
                }
              }}
              useNativeDriver
            >
              <Layout
                style={{
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  alignSelf: 'center',
                  width: '95%',
                  height: 75,
                  borderRadius: 11,
                  backgroundColor: '#ce0f0f',
                  marginLeft: 5,
                  paddingRight: 20,
                  marginRight: 5,
                  marginTop: 10
                }}
              >
                <Text style={{ color: '#fff' }}>Delete</Text>
              </Layout>
              <TouchableWithoutFeedback
                style={{
                  alignItems: 'center',
                  alignSelf: 'center',
                  width: '95%',
                  height: 75,
                  borderRadius: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10
                }}
                onPress={() => {
                  navigation.navigate('SingleNotification', {
                    data: notification
                  });
                }}
                key={notification?._id}
              >
                <Layout
                  style={{
                    alignItems: 'center',
                    alignSelf: 'center',
                    width: '95%',
                    height: 75,
                    borderRadius: 10,
                    backgroundColor: darkMode ? '#333333' : '#ebebeb',
                    shadowColor: '#000',
                    shadowOffset: { width: 0, height: 1 },
                    shadowOpacity: 0.2,
                    shadowRadius: 2,
                    elevation: 1,
                    marginLeft: 5,
                    marginRight: 5,
                    marginTop: 10
                  }}
                >
                  <Text
                    style={{
                      paddingTop: 5,
                      fontWeight: 'bold',
                      fontSize: 20
                    }}
                  >
                    {notification?.title}
                  </Text>
                  <Text style={{}}>
                    From:
                    {notification?.from}
                  </Text>
                  {notification?.creationDate ? (
                    <Text style={{ fontStyle: 'italic', fontSize: 12 }}>
                      {notification?.creationDate}
                    </Text>
                  ) : null}
                </Layout>
              </TouchableWithoutFeedback>
            </SwipeRow>
          )}
        </Layout>
      );
    };

    return (
      <Layout style={{ flex: 1 }}>
        {notifications.length === 0 ? (
          <Layout style={{ alignItems: 'center' }}>
            <Text
              style={{
                paddingTop: '25%',
                paddingBottom: 10,
                textAlign: 'center'
              }}
            >
              No New Notifications
            </Text>
            <TouchableOpacity
              onPress={() => {
                refreshNotifications();
              }}
            >
              <FontAwesome size={25} color="#1E90FF" name="rotate-right" />
            </TouchableOpacity>
          </Layout>
        ) : null}
        <List
          style={{ backgroundColor: 'transparent' }}
          contentContainerStyle={{ flex: 1 }}
          data={notifications}
          scrollEnabled={!isMoving}
          renderItem={renderItem}
          onRefresh={refreshNotifications}
          refreshing={refreshing}
        />
      </Layout>
    );
  }

  function getDateText(text) {
    const spaceIndex = text.indexOf(' ');
    const first = text.substring(0, spaceIndex);
    const second = text.substring(spaceIndex + 1);
    return `${second} |  ${first}`;
  }

  function SingleNotification({
    route: {
      params: { data }
    }
  }) {
    navigation = useNavigation();
    const { data: currentSection } = useGetCurrentSectionQuery();

    if (data.isSchedule) {
      return (
        <CalendarRender
          route={{
            params: {
              overrideData: JSON.parse(data.message)
            }
          }}
        />
      );
    }
    if (data.isCourseData) {
      const allClassData = useAppSelector(selectClassesList);
      const courseSimpleData = JSON.parse(data.message);
      if (courseSimpleData.courseTerm === currentSection) {
        return (
          <SingleClassPage
            selectedClass={allClassData[courseSimpleData.masterCourseCode]}
          />
        );
      }
      Alert.alert('This course contains outdated information');
      navigation.goBack();
      return null;
    }
    return (
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <Layout style={sponsorPageStyles.container}>
            <Layout
              style={{ width: '100%', alignItems: 'center', marginTop: 20 }}
            >
              <Text style={sponsorPageStyles.title}>{data.title}</Text>
            </Layout>

            {data.from && (
              <Layout style={sponsorPageStyles.address}>
                <Layout style={{ flexDirection: 'row' }}>
                  <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
                    From:
                  </Text>
                  <Text style={{ fontSize: 15 }}>{data.from}</Text>
                </Layout>
                <Layout style={{ flexDirection: 'row' }}>
                  <Text style={{ fontSize: 15 }}>
                    {getDateText(data.creationDate)}
                  </Text>
                </Layout>
              </Layout>
            )}
            <Layout style={sponsorPageStyles.description}>
              <Text>{data.message}</Text>
            </Layout>
            {data.repliable && (
              <TouchableOpacity
                onPress={() => {
                  // TODO: reply
                }}
                style={myStyles.button}
              >
                <Text style={myStyles.text}>Reply</Text>
              </TouchableOpacity>
            )}
          </Layout>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
  function CreateNotification({ navigation }) {
    const [body, setBody] = useState({});
    const [done, setDone] = useState(false);
    const [recipient, setRecipient] = useState(null);
    const [hasChosen, setHasChosen] = useState(false);

    useEffect(() => {
      changeValue(hasChosen ? [recipient] : null, 'recipients');
    }, [recipient, hasChosen]);

    function checkForDone(tempBody) {
      if (tempBody.recipients && tempBody.title && tempBody.text) {
        setDone(true);
      } else {
        setDone(false);
      }
    }

    const changeValue = (theValue, valueToChange) => {
      const newBody = body;
      for (const aspect in newBody) {
        if (
          newBody[aspect] === null ||
          newBody[aspect] === undefined ||
          newBody[aspect] === ''
        ) {
          delete newBody[aspect];
        }
      }
      if (theValue !== undefined) {
        newBody[valueToChange] = theValue;
      } else if (newBody[valueToChange]) {
        delete newBody[valueToChange];
      }
      checkForDone(newBody);
      setBody(newBody);
    };

    function renderInputFields() {
      const renderHeader = () => {
        return (
          <Layout style={{ flexDirection: 'row' }}>
            <Text
              style={{
                fontSize: 15,
                paddingLeft: 12,
                paddingBottom: 5,
                paddingTop: 5,
                alignContent: 'center',
                fontWeight: 'bold'
              }}
            >
              Send Message
            </Text>
          </Layout>
        );
      };
      const final = [];
      final.push(
        <View
          style={{
            height: 50,
            margin: 12,
            padding: 10
          }}
        >
          {UserNameSelectionDropDown({
            setHasChosen,
            setRecipient,
            placeholder: true
          })}
        </View>
      );
      final.push(
        <Input
          autoCapitalize="words"
          onChangeText={(theValue) => changeValue(theValue, 'title')}
          style={myStyles.input}
          placeholderTextColor="gray"
          placeholder="Title"
        />
      );
      final.push(
        <Input
          autoCapitalize="sentences"
          onChangeText={(theValue) => changeValue(theValue, 'text')}
          style={myStyles.inputBig}
          multiline
          textStyle={{ minHeight: 150 }}
          placeholderTextColor="gray"
          placeholder="Body"
        />
      );

      return (
        <Layout style={{ flex: 1 }}>
          {renderHeader()}
          {final}
        </Layout>
      );
    }
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        >
          <ScrollView contentContainerStyle={{ flex: 1 }}>
            <Layout style={sponsorPageStyles.container}>
              <Layout style={sponsorPageStyles.description}>
                {renderInputFields()}
                <Button
                  disabled={!done}
                  onPress={async () => {
                    await sendNotification(body);
                    navigation.navigate('Notifications');
                  }}
                >
                  <Text style={myStyles.text}>Send Message</Text>
                </Button>
              </Layout>
            </Layout>
          </ScrollView>
        </KeyboardAvoidingView>
      </SafeAreaView>
    );
  }
  return (
    <NotificationHomePage.Navigator initialRouteName="Notifications & Messages">
      <NotificationHomePage.Screen
        options={({ navigation }) => ({
          header: () => (
            <Layout>
              <Header
                title=""
                accessoryLeft={
                  <Layout
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                  >
                    <HeaderBackButton
                      onPress={navigation.goBack}
                      label="Back"
                      labelVisible={Platform.OS === 'ios'}
                    />
                    <Text
                      style={{
                        marginLeft: Platform.OS === 'web' ? undefined : 50,
                        paddingTop: 3,
                        fontSize: 20,
                        fontWeight: '500',
                        color: darkMode ? '#fff' : '#000'
                      }}
                    >
                      Notifications
                    </Text>
                  </Layout>
                }
                accessoryRight={
                  <Layout>
                    <TouchableOpacity
                      style={{
                        paddingLeft: 20,
                        marginRight: Platform.OS == 'android' ? 50 : 0
                      }}
                      onPress={() => {
                        if (isGuest) {
                          setShowGuestModal(true);
                        } else {
                          createNotificationButton();
                        }
                      }}
                    >
                      <FontAwesome5 size={25} color="#1E90FF" name="plus" />
                    </TouchableOpacity>
                    {showGuestModal && (
                      <ModalContainer
                        title="Wait!"
                        children={
                          <Text style={{ paddingHorizontal: 30 }}>
                            This feature is locked to our Guest Users. Please
                            create a free account with us to take advantage this
                            feature.
                          </Text>
                        }
                        backDropVisible={showGuestModal}
                        setBackDropVisible={setShowGuestModal}
                      />
                    )}
                  </Layout>
                }
              />
            </Layout>
          )
        })}
        name="Notifications"
        component={Notifications}
      />
      <NotificationHomePage.Screen
        name="SingleNotification"
        component={SingleNotification}
        options={{ headerTitle: '' }}
      />
      <NotificationHomePage.Screen
        name="CreateNotification"
        component={CreateNotification}
        options={{ headerTitle: '' }}
      />
    </NotificationHomePage.Navigator>
  );
}

function convertTo12HourTime(time) {
  if (time.length !== 5) {
    time = `0${time}`;
  }
  let hours = time.slice(0, 2);
  const minutes = time.slice(3, 5);
  let ampm = Number.parseInt(hours) > 12 ? 'pm' : 'am';
  hours %= 12;
  if (hours === 0) {
    hours = 12;
    ampm = 'pm';
  }
  return `${hours}:${minutes}${ampm}`;
}

function getMinutesDiff(startTime, endTime) {
  if (endTime > startTime) {
    return (endTime - startTime) * 0.7;
  }
  if (startTime === endTime) {
    return 0;
  }
  return (startTime - endTime) * -0.7;
}

const sponsorPageStyles = StyleSheet.create({
  container: {
    flex: 1
    // alignItems: 'center',
  },
  header: {
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  address: {
    alignItems: 'center',
    margin: 10,
    borderColor: 'black',
    borderWidth: 1,
    padding: 10
  },
  video: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  title: {
    fontSize: 26,
    marginLeft: 10
  },
  logo: {
    width: '100%',
    height: 100
  },
  description: {
    flex: 1,
    margin: 10,
    borderRadius: 5,
    width: '95%',
    padding: 10
  },
  couponContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20
  },
  coupon: {
    width: 400,
    height: 267
  },
  imageGallery: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 10,
    marginBottom: 0
  },
  imageStyle: {
    width: 150,
    height: 150,
    paddingTop: 100,
    margin: 5
  },
  headerText: {
    fontSize: 20,
    fontWeight: 'bold'
  }
});

const myStyles = StyleSheet.create({
  inputBig: {
    height: 200,
    margin: 12,
    borderWidth: 1,
    padding: 10
  },
  input: {
    height: 50,
    margin: 12,
    borderWidth: 1,
    padding: 10
  },
  indentedInput: {
    height: 50,
    marginVertical: 12,
    borderWidth: 1,
    padding: 10,
    marginRight: 12,
    marginLeft: 30
  },
  text: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: 'bold',
    letterSpacing: 0.25
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 4,
    elevation: 3
  },
  timeButton: {
    width: 100,
    height: 50,
    marginHorizontal: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4
  },
  timeText: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: 'white'
  },
  actualTimeText: {
    fontSize: 13,
    lineHeight: 18,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: 'white'
  },
  timeStyle: {
    flex: 0.3,
    borderWidth: 2,
    borderLeftColor: 'white'
  }
});

const listingStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center'
  },
  cardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    width: '95%',
    height: 75,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ddd',
    borderBottomWidth: 0,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 1,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 10
  },
  containerForNotifications: {
    alignItems: 'center',
    alignSelf: 'center',
    width: '95%',
    height: 75,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ddd',
    borderBottomWidth: 0,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 1,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 10
  },
  text: {
    flex: 1,
    fontSize: 20,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 5
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 10
  }
});
