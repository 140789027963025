import NetInfo from '@react-native-community/netinfo';
import { Input, Layout, Spinner, Text } from '@ui-kitten/components';
import React, { useState } from 'react';
import {
  Image,
  Keyboard,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback
} from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import {
  selectDarkMode,
  setIsGuest,
  userKeyUpdated
} from '../../app/appDataSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useLazyGetAllSectionsQuery } from '../api/classPagesApi';
import {
  useLazyGetUserInfoQuery,
  useLoginMutation,
  useTrackLoginMutation
} from '../api/usersApi';
import {
  availableSectionsListUpdated,
  currentSectionUpdated
} from '../classPages/classPagesSlice';

const LightModeLogo = require('../../../assets/logos/owl-black-text.png');
const DarkModeLogo = require('../../../assets/logos/owl-white-text.png');

export default function Login({ navigation }) {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  const [login, { isLoading }] = useLoginMutation();
  const [getUserInfo, { isLoading: loadingUserInfo }] =
    useLazyGetUserInfoQuery();
  const [trackLogin] = useTrackLoginMutation();
  const [getAllSections] = useLazyGetAllSectionsQuery();
  const darkMode = useAppSelector(selectDarkMode);
  // const apiUserKey = useAppSelector((state) => state.appData.secure);
  const dispatch = useAppDispatch();

  const passwordRef = React.useRef(null);

  const handleLogin = async () => {
    Keyboard.dismiss();

    // Check for internet connection
    const netInfo = await NetInfo.fetch();
    if (!netInfo.isConnected) {
      setLoginError('No internet connection');
      return;
    }

    // Validate input
    if (!userName) {
      setLoginError("Username can't be empty");
    } else if (!password) {
      setLoginError("Password can't be empty");
    } else {
      try {
        const { userKey } = await login({
          userName,
          password
        }).unwrap();
        // Save user key and data to redux store
        dispatch(userKeyUpdated(userKey));
        dispatch(setIsGuest(false));

        // Get current section
        const allSections = await getAllSections().unwrap();

        dispatch(currentSectionUpdated(allSections.currentTerm));
        dispatch(availableSectionsListUpdated(allSections.availableTerms));

        // Get user info
        getUserInfo(userKey)
          .unwrap()
          .then(async () => {
            await trackLogin(userKey);
          });

        // Reset login form
        setUserName('');
        setPassword('');
        setLoginError('');
      } catch (e) {
        setLoginError('Invalid username or password');
        console.error('Failed to Login: ', e);
      }
    }
  };

  const guestLogin = async () => {
    Keyboard.dismiss();

    // Check for internet connection
    const netInfo = await NetInfo.fetch();
    if (!netInfo.isConnected) {
      setLoginError('No internet connection');
      return;
    }

    try {
      const { userKey } = await login({
        userName: '',
        password: '',
        isGuest: true
      }).unwrap();
      // Save user key and data to redux store
      dispatch(userKeyUpdated(userKey));
      dispatch(setIsGuest(true));

      // Get user info
      getUserInfo(userKey)
        .unwrap()
        .then(async () => {
          await trackLogin(userKey);
        });

      // Reset login form
      setUserName('');
      setPassword('');
      setLoginError('');
    } catch (e) {
      setLoginError('Invalid username or password');
      console.error('Failed to Login: ', e);
    }
  };

  return (
    <TouchableWithoutFeedback
      style={{ flex: 1 }}
      onPress={() => {
        Keyboard.dismiss();
      }}
    >
      <Layout
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {darkMode ? (
          <Image source={DarkModeLogo} style={styles.logo} />
        ) : (
          <Image source={LightModeLogo} style={styles.logo} />
        )}

        <Layout style={styles.inputView}>
          <Input
            autoCapitalize="none"
            status="control"
            autoComplete="username"
            style={styles.TextInput}
            placeholder="username / email"
            placeholderTextColor="white"
            keyboardType="email-address"
            onChangeText={(userName) => setUserName(userName)}
            value={userName}
            onSubmitEditing={() => passwordRef.current.focus()}
            blurOnSubmit={false}
          />
        </Layout>
        <Layout style={styles.inputView}>
          <Input
            style={styles.TextInput}
            autoComplete="password"
            placeholder="password"
            placeholderTextColor="white"
            secureTextEntry
            onChangeText={(password) => setPassword(password)}
            value={password}
            ref={passwordRef}
          />
        </Layout>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('ForgotPassword');
          }}
        >
          <Text style={styles.forgot_button}>Forgot Password?</Text>
        </TouchableOpacity>
        {isLoading || loadingUserInfo ? (
          <Layout
            style={{ position: 'absolute', backgroundColor: 'transparent' }}
          >
            <Spinner />
          </Layout>
        ) : null}

        <TouchableOpacity
          style={styles.loginBtn}
          onPress={() => {
            handleLogin();
          }}
        >
          <Text style={{ color: '#fff' }}>LOGIN</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            width: '50%',
            borderRadius: 25,
            height: 40,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
            backgroundColor: darkMode ? '#8b31e3' : 'rgb(98,111,187)',
            maxWidth: 300
          }}
          onPress={() => {
            guestLogin();
          }}
        >
          <Text style={{ color: '#fff' }}>Try As Guest</Text>
        </TouchableOpacity>
        <Text style={styles.error}>{loginError}</Text>
        <TouchableOpacity
          style={{ width: '100%' }}
          onPress={() => {
            navigation.navigate('Signup');
          }}
        >
          <Layout level="2" style={styles.signupBtn}>
            <Text>SIGN UP</Text>
          </Layout>
        </TouchableOpacity>
      </Layout>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  image: {
    marginBottom: 40
  },

  inputView: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 50,
    marginRight: 50,
    margin: 10
  },

  TextInput: {
    backgroundColor: '#b42bba',
    borderColor: '#b42bba',
    color: '#fff',
    borderWidth: 1,
    borderRadius: 30,
    width: '100%',
    maxWidth: 300
  },

  forgot_button: {
    height: 30,
    marginBottom: 30
  },

  loginBtn: {
    width: '80%',
    borderRadius: 25,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
    backgroundColor: '#ff3f5e',
    maxWidth: 300
  },
  guestButton: {
    width: '60%',
    borderRadius: 25,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20
  },
  signupBtn: {
    marginTop: 60,
    width: '100%',
    height: 50,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    maxWidth: 800
  },
  error: {
    color: 'red',
    fontSize: 15
  },
  logo: {
    width: 178,
    height: 197,
    marginBottom: 20
  }
});
