import {
  IndexPath,
  Layout,
  Select,
  SelectItem,
  Text,
  useTheme,
  Button,
  Spinner,
  Input
} from '@ui-kitten/components';
import { useState } from 'react';
import { Alert, StyleSheet, ScrollView } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { selectUserKey } from '../../../app/appDataSlice';
import { useAppSelector } from '../../../app/hooks';
import { NEW_REVIEW_FIELDS as fields } from '../../../constants/classPages';
import { useSendReviewMutation } from '../../api/classPagesApi';
import ModalContainer from '../../../components/ModalContainer';

export default function NewReview({
  route: {
    params: { professorList }
  }
}) {
  const navigation = useNavigation();
  const [body, setBody] = useState({});
  const userKey = useAppSelector(selectUserKey);
  const darkMode = useAppSelector((state) => state.appData.secure.darkMode);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const selectedClass = useAppSelector(
    (state) => state.appData.classPages.selectedClass
  );
  const [sendReview, { isLoading }] = useSendReviewMutation();

  const theme = useTheme();

  const backgroundColor = darkMode
    ? theme['color-basic-900']
    : theme['color-basic-200'];

  const color = darkMode ? theme['color-basic-200'] : theme['color-basic-900'];

  const handleReviewSubmitted = async () => {
    try {
      await sendReview({
        body: {
          ...body,
          masterCourseCode: selectedClass.masterCourseCode
        },
        userKey
      }).unwrap();
      setShowConfirmationModal(true);
    } catch (error) {
      setShowErrorModal(true);
      console.warn('Error submitting review: ', error);
    }
  };

  function renderTheFieldsNecessary(fields) {
    const changeValue = (theValue, valueToChange) => {
      const newBody = body;
      for (const aspect in newBody) {
        if (
          newBody[aspect] === null ||
          newBody[aspect] === undefined ||
          newBody[aspect] === ''
        ) {
          delete newBody[aspect];
        }
      }
      if (theValue !== undefined) {
        newBody[valueToChange] = theValue;
      } else if (newBody[valueToChange]) {
        delete newBody[valueToChange];
      }
      setBody(newBody);
    };
    const renderFilterParameters = (fields) => {
      if (fields.length === 0) {
        return null;
      }
      return (
        <Layout style={{ flexDirection: 'row', backgroundColor }}>
          <Text
            style={{
              fontSize: 15,
              paddingLeft: 12,
              paddingBottom: 5,
              paddingTop: 5,
              alignContent: 'center',
              fontWeight: 'bold'
            }}
          >
            Review Details
          </Text>
        </Layout>
      );
    };
    const final = [];

    const renderDropDown = (field) => {
      const [selectedIndex, setSelectedIndex] = useState(new IndexPath(0));
      const [hasChosen, setHasChosen] = useState(false);

      if (field.label === 'Professor') {
        field.options = professorList.map((professorName: String) => {
          return {
            label: professorName,
            id: professorName
          };
        });
      }

      function currentValue() {
        return (
          <Text key="gal;fkdjsl;f">
            {!hasChosen
              ? `Choose ${field.label}${field.mandatory ? ' (mandatory)' : ''}`
              : field.options[selectedIndex.row - 1].label}{' '}
          </Text>
        );
      }

      function handleSelection(index) {
        setSelectedIndex(index);
        changeValue(
          index.row === 0 ? undefined : field.options[index.row - 1].id,
          field.id
        );
        if (index.row === 0) {
          setHasChosen(false);
        } else {
          setHasChosen(true);
        }
      }

      return (
        <Select
          style={{ ...styles.containerDrop, backgroundColor }}
          value={currentValue}
          selectedIndex={selectedIndex}
          onSelect={handleSelection}
        >
          <SelectItem title={' '} />
          {field.options.map(({ label }, index) => (
            <SelectItem title={label} key={index} />
          ))}
        </Select>
      );
    };
    final.push(
      <Input
        key="lfjdsa;lfaj"
        autoCapitalize="sentences"
        onChangeText={(theValue) => changeValue(theValue, 'text')}
        style={{ ...styles.inputBig, backgroundColor, color }}
        multiline
        placeholderTextColor={darkMode ? theme['color-basic-500'] : 'gray'}
        placeholder="Review Text (mandatory)"
      />
    );
    for (let i = 0; i < fields.length; i++) {
      const { type } = fields[i];
      if (type === 'String') {
        final.push(
          <Input
            key={i}
            autoCapitalize="words"
            clearButtonMode="always"
            onChangeText={(theValue) => changeValue(theValue, fields[i].id)}
            style={{ ...styles.input, backgroundColor, color }}
            placeholderTextColor={darkMode ? theme['color-basic-500'] : 'gray'}
            placeholder={
              fields[i].mandatory
                ? `${fields[i].label} (mandatory)`
                : fields[i].label
            }
          />
        );
      } else if (type === 'DropDown') {
        final.push(renderDropDown(fields[i]));
      }
    }

    return (
      <Layout style={{ backgroundColor }}>
        {renderFilterParameters(fields)}
        <Layout style={{ backgroundColor }}>{final}</Layout>
      </Layout>
    );
  }

  const successChildren = (
    <Layout style={{ marginHorizontal: 15, justifyContent: 'center' }}>
      <Text>
        {`Your review of "${selectedClass.courseName}" has been submitted and is
          now pending approval.\n\nThank You!`}
      </Text>
      <Button
        onPress={() => {
          navigation.goBack();
        }}
      >
        Okay
      </Button>
    </Layout>
  );

  const errorChildren = (
    <Layout style={{ marginHorizontal: 15, justifyContent: 'center' }}>
      <Text>Your review encountered an error, please try again later</Text>
      <Button
        onPress={() => {
          navigation.goBack();
        }}
      >
        Okay
      </Button>
    </Layout>
  );

  return (
    <ScrollView style={{ backgroundColor }}>
      <ModalContainer
        backDropVisible={showConfirmationModal}
        setBackDropVisible={setShowConfirmationModal}
        title="Review Submitted!"
        children={successChildren}
      />
      <ModalContainer
        backDropVisible={showErrorModal}
        setBackDropVisible={setShowErrorModal}
        title="Error Submitting Review"
        children={errorChildren}
      />
      <Layout style={{ backgroundColor, ...styles.container }}>
        <Layout style={{ backgroundColor }}>
          {renderTheFieldsNecessary(fields)}
        </Layout>
        <Button style={{ margin: 12 }} onPress={handleReviewSubmitted}>
          Post Review
        </Button>
      </Layout>
      {isLoading ? (
        <Layout
          style={{
            backgroundColor: 'transparent',
            position: 'absolute',
            right: '50%',
            top: '50%'
          }}
        >
          <Spinner />
        </Layout>
      ) : null}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  input: {
    height: 50,
    margin: 12,
    padding: 10,
    borderColor: '#B3B3B3',
    borderRadius: 5,
    borderWidth: 1
  },
  inputBig: {
    height: 200,
    margin: 12,
    padding: 10,
    borderColor: '#B3B3B3',
    borderRadius: 5,
    borderWidth: 1
  },
  containerDrop: {
    height: 42,
    margin: 12
  },
  indentedInput: {
    height: 50,
    marginVertical: 12,
    padding: 10,
    marginRight: 12,
    marginLeft: 30
  },
  text: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: 'bold',
    letterSpacing: 0.25
  },
  container: {
    flex: 1
    // alignItems: 'center',
  },
  header: {
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  address: {
    alignItems: 'center',
    margin: 10,
    padding: 10
  },
  video: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  title: {
    fontSize: 26,
    marginLeft: 10
  },
  logo: {
    width: '100%',
    height: 100
  },
  couponContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20
  },
  coupon: {
    width: 400,
    height: 267
  },
  imageGallery: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 10,
    marginBottom: 0
  },
  imageStyle: {
    width: 150,
    height: 150,
    paddingTop: 100,
    margin: 5
  },
  headerText: {
    fontSize: 20,
    fontWeight: 'bold'
  }
});
