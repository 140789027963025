import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import fuzzysort from 'fuzzysort';

import type { RootState } from '../../app/store';
import {
  AddedClasses,
  Class,
  ClassesObject,
  CourseSchedule,
  Filters,
  UserData
} from '../../app/types';
import {
  addCourseInjections,
  getMasterCourseCodeFromCourseCode,
  getTimeAsNumberOfMinutes
} from '../../functions';
import { classPagesApi } from '../api/classPagesApi';
import { usersApiSlice } from '../api/usersApi';

// Define a type for the slice state
interface ClassPagesState {
  classes: { [masterCourseCode: string]: Class };
  filteredClasses: { [masterCourseCode: string]: Class };
  renderedClasses: { [masterCourseCode: string]: Class };
  currentSection: string;
  availableSections: string[];
  searchTerm: string;
  selectedClass: any;
  page: number;
  filters: Filters;
  isHMC: boolean;
  hasSelectedElectiveGen: boolean;
  suggestedClasses: { [masterCourseCode: string]: Class };
  orderedAddedClasses: AddedClasses['orderedClasses'];
  totalCredits: { credits: number; hmcCredits: number };
  userData: UserData; // enables offlines mode
}

// Define the initial state using that type
const initialState: ClassPagesState = {
  classes: {},
  filteredClasses: {},
  renderedClasses: {},
  currentSection: '',
  availableSections: [],
  selectedClass: {},
  searchTerm: '',
  page: 1,
  isHMC: undefined,
  filters: {
    filterConflicts: false,
    colleges: { HM: false, CM: false, PO: false, PZ: false, SC: false }
  },
  hasSelectedElectiveGen: false,
  suggestedClasses: {},
  orderedAddedClasses: {},
  totalCredits: { credits: 0, hmcCredits: 0 },
  userData: undefined
};

export const classPagesSlice = createSlice({
  name: 'classPages',
  initialState,
  reducers: {
    reset: () => initialState,
    addClassInjections: (state) => {
      state.classes = addCourseInjections(state.classes);
    },
    currentSectionUpdated: (state, action: PayloadAction<string>) => {
      state.currentSection = action.payload;
    },
    availableSectionsListUpdated: (state, action: PayloadAction<string[]>) => {
      state.availableSections = action.payload;
    },
    endOfListReached: (state) => {
      state.page++;
    },
    resetClasses: (state) => {
      state.page = 1;
      state.searchTerm = '';

      classPagesSlice.caseReducers.creditsRecalculated(state);
      classPagesSlice.caseReducers.searchResultsFiltered(state);
    },
    suggestedClassesUpdated: (
      state,
      action: PayloadAction<{ [masterCourseCode: string]: Class }>
    ) => {
      state.suggestedClasses = action.payload;
    },
    suggestedClassesDimissed: (state) => {
      state.suggestedClasses = {};
    },
    electiveGeneratorSelected: (state) => {
      state.hasSelectedElectiveGen = true;
    },
    classSelected: (state, action: PayloadAction<string>) => {
      state.selectedClass = state.classes[action.payload];
    },
    selectedClassCleared: (state) => {
      state.selectedClass = {};
    },
    filtersSaved: (state, action: PayloadAction<Filters>) => {
      state.filters = action.payload;
      classPagesSlice.caseReducers.searchResultsFiltered(state);
    },
    isHMCUpdated: (state, action: PayloadAction<boolean>) => {
      state.isHMC = action.payload;
    },
    creditsRecalculated: (state) => {
      state.totalCredits = recalculateTotalCredits({
        orderedAddedClasses: state.orderedAddedClasses[state.currentSection],
        allClasses: state.classes
      });
    },
    filtersReset: (state) => {
      state.filters = initialState.filters;

      classPagesSlice.caseReducers.searchResultsFiltered(state);
    },
    searchResultsFiltered: (state) => {
      // Update the renderedClasses object with the new filters
      let newResultsArray = Object.entries(state.classes);

      const numFilters = Object.entries(state.filters.colleges).filter(
        ([_, isFiltered]) => isFiltered
      ).length;

      if (
        numFilters !== 0 &&
        numFilters !== Object.keys(state.filters.colleges).length
      ) {
        newResultsArray = newResultsArray.filter(([masterCourseCode, _]) => {
          // filter by college
          const collegeAbr = masterCourseCode.substring(
            masterCourseCode.length - 2,
            masterCourseCode.length
          );
          return state.filters.colleges[collegeAbr];
        });
      }

      const newResultsObject = Object.fromEntries(newResultsArray);
      // filter conflicting times
      if (
        state.filters.filterConflicts &&
        state.orderedAddedClasses[state.currentSection] &&
        state.orderedAddedClasses[state.currentSection].length
      ) {
        newResultsArray.forEach(([masterCourseCode, _]) => {
          newResultsObject[masterCourseCode].times = newResultsObject[
            masterCourseCode
          ].times.filter((time) => {
            for (const timeWindow of time.courseSchedule) {
              if (
                doesClassConflict(
                  timeWindow,
                  state.orderedAddedClasses[state.currentSection],
                  state.classes
                )
              ) {
                return false;
              }
            }
            return true;
          });

          if (newResultsObject[masterCourseCode].times.length == 0) {
            delete newResultsObject[masterCourseCode];
          }
        });
      }

      state.filteredClasses = newResultsObject;
      state.renderedClasses = { ...state.filteredClasses };
    },
    searchTermUpdated: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserData>) => {
      // enables offline mode
      state.userData = action.payload;
    },
    clearUserData: (state) => {
      state.userData = undefined;
      state.orderedAddedClasses = {};
      state.totalCredits = { credits: 0, hmcCredits: 0 };
    },
    filterListBySearchTerm: (state) => {
      state.page = 1;
      if (state.searchTerm === '') {
        classPagesSlice.caseReducers.resetClasses(state);
      } else {
        const filteredClassesValues = Object.values(state.filteredClasses);

        // so that professor names are included in the search results
        const atLeastOneOfTheFirstFiveClassesHasProfessor =
          filteredClassesValues
            .slice(0, 5)
            .some((class_) => class_.times[0].professorName && class_.keywords);
        if (!atLeastOneOfTheFirstFiveClassesHasProfessor) {
          classPagesSlice.caseReducers.addClassInjections(state);
        }

        const searchResult = fuzzysort.go(
          state.searchTerm,
          filteredClassesValues,
          {
            keys: [
              'courseName',
              'masterCourseCode',
              'professorNames',
              'keywords'
            ],
            threshold: -1000,
            allowTypo: true
          }
        );

        state.renderedClasses = Object.fromEntries(
          searchResult.map((result) => [
            result.obj.masterCourseCode,
            result.obj
          ])
        );
      }
    },
    // For offline mode only
    setClassesInState(state, action: PayloadAction<ClassesObject>) {
      state.classes = action.payload;
      state.filteredClasses = action.payload;
      state.renderedClasses = action.payload;

      if (state.orderedAddedClasses[state.currentSection]) {
        state.totalCredits = recalculateTotalCredits({
          allClasses: state.classes,
          orderedAddedClasses: state.orderedAddedClasses[state.currentSection]
        });
      }
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      classPagesApi.endpoints.getAllClasses.matchFulfilled,
      (state, { payload }) => {
        state.classes = payload;
        state.filteredClasses = payload;
        state.renderedClasses = payload;

        if (state.orderedAddedClasses[state.currentSection]) {
          state.totalCredits = recalculateTotalCredits({
            allClasses: state.classes,
            orderedAddedClasses: state.orderedAddedClasses[state.currentSection]
          });
        }
      }
    );
    builder.addMatcher(
      usersApiSlice.endpoints.getUserInfo.matchFulfilled,
      (state, { payload }) => {
        state.orderedAddedClasses = payload.addedClasses.orderedClasses;

        if (Object.keys(state.classes).length) {
          state.totalCredits = recalculateTotalCredits({
            orderedAddedClasses:
              state.orderedAddedClasses[state.currentSection],
            allClasses: state.classes
          });
        }
      }
    );
    builder.addMatcher(
      classPagesApi.endpoints.addClass.matchFulfilled,
      (state, { payload }) => {
        state.orderedAddedClasses = getOrderedClassesFromApiRes(
          payload.userData.classes
        );

        state.totalCredits = recalculateTotalCredits({
          orderedAddedClasses: state.orderedAddedClasses[state.currentSection],
          allClasses: state.classes
        });
      }
    );
    builder.addMatcher(
      classPagesApi.endpoints.removeClass.matchFulfilled,
      (state, { payload }) => {
        state.orderedAddedClasses = getOrderedClassesFromApiRes(
          payload.userData.classes
        );

        state.totalCredits = recalculateTotalCredits({
          orderedAddedClasses: state.orderedAddedClasses[state.currentSection],
          allClasses: state.classes
        });
      }
    );
    builder.addMatcher(
      classPagesApi.endpoints.swapCoursePriority.matchFulfilled,
      (state, { payload }) => {
        state.orderedAddedClasses = getOrderedClassesFromApiRes(
          payload.userData.classes
        );
      }
    );
  }
});

/**
 *
 * Helper Functions
 *
 */

const recalculateTotalCredits = ({
  addedClasses,
  orderedAddedClasses,
  allClasses
}: {
  addedClasses?: AddedClasses['classes']['term'];
  orderedAddedClasses?: AddedClasses['orderedClasses']['term'];
  allClasses: ClassPagesState['classes'];
}): ClassPagesState['totalCredits'] => {
  let totalCredits = {
    credits: 0,
    hmcCredits: 0
  } as ClassPagesState['totalCredits'];

  try {
    if (addedClasses && Object.keys(addedClasses).length) {
      for (const masterCourseCode in addedClasses) {
        const course = allClasses[masterCourseCode];

        const numCourses = addedClasses[masterCourseCode].length;

        totalCredits.credits += course.creditWeight * numCourses;
        totalCredits.hmcCredits += course.creditWeightHM * numCourses;
      }
    } else if (orderedAddedClasses) {
      for (const courseCode of orderedAddedClasses) {
        const masterCourseCode = getMasterCourseCodeFromCourseCode(courseCode);
        const course = allClasses[masterCourseCode];

        totalCredits.credits += course.creditWeight;
        totalCredits.hmcCredits += course.creditWeightHM;
      }
    }
  } catch (e) {
    console.warn('Error calculating credits', e);
  }

  return totalCredits;
};

const doesClassConflict = (
  schedule: CourseSchedule,
  orderedAddedClasses: string[],
  classes: { [masterCourseCode: string]: Class }
) => {
  for (const addedClass of orderedAddedClasses) {
    const masterCourseCode = getMasterCourseCodeFromCourseCode(addedClass);
    const addedTimes = classes[masterCourseCode].times;

    for (const addedTime of addedTimes) {
      for (const addedSchedule of addedTime.courseSchedule) {
        const addedTimeStart = getTimeAsNumberOfMinutes(
          addedSchedule.startTime
        );
        const addedTimeEnd = getTimeAsNumberOfMinutes(addedSchedule.endTime);
        const scheduleTimeStart = getTimeAsNumberOfMinutes(schedule.startTime);
        const scheduleTimeEnd = getTimeAsNumberOfMinutes(schedule.endTime);
        if (
          addedSchedule.daysOfTheWeek.filter((day) =>
            schedule.daysOfTheWeek.includes(day)
          ).length > 0 &&
          ((scheduleTimeStart >= addedTimeStart &&
            scheduleTimeStart < addedTimeEnd) ||
            (scheduleTimeEnd > addedTimeStart &&
              scheduleTimeEnd <= addedTimeEnd) ||
            (scheduleTimeStart <= addedTimeStart &&
              scheduleTimeEnd >= addedTimeEnd))
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

const getOrderedClassesFromApiRes = (
  classes: { courseCode: string; courseTerm: string }[]
) => {
  let orderedClasses: AddedClasses['orderedClasses'] = {};
  classes.forEach(({ courseCode, courseTerm }) => {
    if (!orderedClasses[courseTerm]) {
      orderedClasses[courseTerm] = [];
    }
    orderedClasses[courseTerm].push(courseCode);
  });
  return orderedClasses;
};

// Selectors
export const selectClassesList = (state: RootState) =>
  state.appData.classPages.classes;

export const selectRenderedClassesList = (state: RootState) =>
  state.appData.classPages.renderedClasses;

// Actions
export const {
  currentSectionUpdated,
  endOfListReached,
  searchTermUpdated,
  resetClasses,
  classSelected,
  selectedClassCleared,
  reset,
  filtersSaved,
  filtersReset,
  isHMCUpdated,
  availableSectionsListUpdated,
  electiveGeneratorSelected,
  suggestedClassesUpdated,
  suggestedClassesDimissed,
  creditsRecalculated,
  filterListBySearchTerm,
  clearUserData,
  setUserData
} = classPagesSlice.actions;

export default classPagesSlice.reducer;
