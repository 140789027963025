import { apiSlice } from './apiSlice';
import { usersApiSlice } from './usersApi';
import {
  AllSectionsResponse,
  Class,
  SwapCoursePriorityInput,
  UserData
} from '../../app/types';
import { addCourseInjections } from '../../functions';

export const classPagesApi = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getAllClasses: builder.query({
      query: ({ userKey, pageNumber, courseTerm, collegeName }) => ({
        url: `/classes/masterClassList/${collegeName}`,
        headers: {
          userKey
        },
        params: {
          pageNumber,
          courseTerm,
          omitReviews: true
        }
      }),
      transformResponse: (response: { [masterCourseCode: string]: Class }) => {
        return addCourseInjections(response);
      }
    }),
    getCurrentSection: builder.query<string, void>({
      query: () => '/randomShit/currentTerm',
      transformResponse: (response: { currentTerm: string }) =>
        response.currentTerm
    }),
    getAllSections: builder.query<AllSectionsResponse, void>({
      query: () => '/randomShit/getAllTerms'
    }),
    addClass: builder.mutation({
      query: ({ userKey, courseCode, courseTerm, userName }) => ({
        url: `/users/${userName}`,
        method: 'PATCH',
        headers: {
          userKey
        },
        body: {
          classesToAdd: [{ courseCode, courseTerm }]
        }
      }),
      async onQueryStarted(
        { userKey, courseCode, masterCourseCode, courseTerm },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          usersApiSlice.util.updateQueryData(
            'getUserInfo',
            userKey,
            (draft) => {
              // Add to ordered Added Classes
              if (!(courseTerm in draft.addedClasses.orderedClasses)) {
                draft.addedClasses.orderedClasses[courseTerm] = [courseCode];
              } else {
                draft.addedClasses.orderedClasses[courseTerm].push(courseCode);
              }

              // Add to unordered Added Classes
              if (!(courseTerm in draft.addedClasses.classes)) {
                draft.addedClasses.classes[courseTerm] = {
                  [masterCourseCode]: [courseCode]
                };
              } else if (
                !(masterCourseCode in draft.addedClasses.classes[courseTerm])
              ) {
                draft.addedClasses.classes[courseTerm][masterCourseCode] = [
                  courseCode
                ];
              } else {
                draft.addedClasses.classes[courseTerm][masterCourseCode].push(
                  courseCode
                );
              }
              draft.addedClasses.length++;
            }
          )
        );

        try {
          await queryFulfilled;
        } catch (e) {
          if (e.error.status !== 500) {
            patchResult.undo();
          }
        }
      }
    }),
    addMultipleClasses: builder.mutation<
      { userData: UserData },
      {
        userKey: string;
        courseTerm: string;
        userName: string;
        courseCodePairs: {
          courseCode: string;
          masterCourseCode: string;
        }[];
      }
    >({
      query: ({ userKey, courseCodePairs, courseTerm, userName }) => ({
        url: `/users/${userName}`,
        method: 'PATCH',
        headers: {
          userKey
        },
        body: {
          classesToAdd: courseCodePairs.map((courseCodePair) => ({
            courseCode: courseCodePair.courseCode,
            courseTerm
          }))
        }
      }),
      async onQueryStarted(
        { userKey, courseCodePairs, courseTerm },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          usersApiSlice.util.updateQueryData(
            'getUserInfo',
            userKey,
            (draft) => {
              for (const { masterCourseCode, courseCode } of courseCodePairs) {
                if (!(courseTerm in draft.addedClasses.classes)) {
                  draft.addedClasses.classes[courseTerm] = {
                    [masterCourseCode]: [courseCode]
                  };
                } else if (
                  !(masterCourseCode in draft.addedClasses.classes[courseTerm])
                ) {
                  draft.addedClasses.classes[courseTerm][masterCourseCode] = [
                    courseCode
                  ];
                } else {
                  draft.addedClasses.classes[courseTerm][masterCourseCode].push(
                    courseCode
                  );
                }
                draft.addedClasses.length++;
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch (e) {
          if (e.error.status !== 500) {
            patchResult.undo();
          }
        }
      }
    }),
    removeClass: builder.mutation({
      query: ({ userKey, courseCode, courseTerm, userName }) => ({
        url: `/users/${userName}`,
        method: 'PATCH',
        headers: {
          userKey
        },
        body: {
          classesToRemove: [{ courseCode, courseTerm }]
        }
      }),
      async onQueryStarted(
        { userKey, courseCode, masterCourseCode, courseTerm },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          usersApiSlice.util.updateQueryData(
            'getUserInfo',
            userKey,
            (draft) => {
              // Delete from ordered Added Classes
              draft.addedClasses.orderedClasses[courseTerm] =
                draft.addedClasses.orderedClasses[courseTerm].filter(
                  (classCode) => classCode !== courseCode
                );

              // Delete from unordered Added Classes

              draft.addedClasses.classes[courseTerm][masterCourseCode] =
                draft.addedClasses.classes[courseTerm][masterCourseCode].filter(
                  (classCode) => classCode !== courseCode
                );
              // Delete the master course code if there are no more classes
              if (
                draft.addedClasses.classes[courseTerm][masterCourseCode]
                  .length === 0
              ) {
                delete draft.addedClasses.classes[courseTerm][masterCourseCode];
              }
              draft.addedClasses.length--;
            }
          )
        );

        try {
          await queryFulfilled;
        } catch (e) {
          if (e.error.status !== 500) {
            patchResult.undo();
          }
        }
      }
    }),

    addStarredClass: builder.mutation({
      query: ({ userKey, courseCode, courseTerm, userName }) => ({
        url: `/users/${userName}`,
        method: 'PATCH',
        headers: {
          userKey
        },
        body: {
          starredClassesToAdd: [{ courseCode, courseTerm }]
        }
      }),
      async onQueryStarted(
        { userKey, courseCode, masterCourseCode, courseTerm },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          usersApiSlice.util.updateQueryData(
            'getUserInfo',
            userKey,
            (draft) => {
              if (
                !draft.starredClasses.some(
                  (course) =>
                    course.courseTerm === courseTerm &&
                    course.courseCode === courseCode
                )
              ) {
                draft.starredClasses.push({
                  courseCode: courseCode,
                  courseTerm: courseTerm
                });
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch (e) {
          if (e.error.status !== 500) {
            patchResult.undo();
          }
        }
      }
    }),
    removeStarredClass: builder.mutation({
      query: ({ userKey, courseCode, courseTerm, userName }) => ({
        url: `/users/${userName}`,
        method: 'PATCH',
        headers: {
          userKey
        },
        body: {
          starredClassesToRemove: [{ courseCode, courseTerm }]
        }
      }),
      async onQueryStarted(
        { userKey, courseCode, masterCourseCode, courseTerm },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          usersApiSlice.util.updateQueryData(
            'getUserInfo',
            userKey,
            (draft) => {
              draft.starredClasses = draft.starredClasses.filter(
                (course) =>
                  course.courseCode !== courseCode ||
                  course.courseTerm !== courseTerm
              );
            }
          )
        );

        try {
          await queryFulfilled;
        } catch (e) {
          if (e.error.status !== 500) {
            patchResult.undo();
          }
        }
      }
    }),

    isAllowedToReview: builder.query({
      query: ({ masterCourseCode, userKey }) => ({
        url: '/users/isAllowedToReview',
        method: 'GET',
        params: {
          masterCourseCode
        },
        headers: {
          userKey
        }
      })
    }),
    sendReview: builder.mutation({
      query: ({ userKey, body }) => ({
        url: '/classes/userReview',
        method: 'PATCH',
        headers: {
          userKey
        },
        body,
        responseHandler: (response) => response.text()
      })
    }),
    swapCoursePriority: builder.mutation<any, SwapCoursePriorityInput>({
      query: ({
        userName,
        firstPosition,
        secondPosition,
        termToUpdate,
        userKey
      }) => ({
        url: '/users/' + userName,
        method: 'PATCH',
        headers: {
          userKey
        },
        body: {
          swapCoursePriority: {
            firstPosition,
            secondPosition,
            termToUpdate
          }
        }
      }),
      async onQueryStarted(
        { firstPosition, secondPosition, termToUpdate, userKey },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          usersApiSlice.util.updateQueryData(
            'getUserInfo',
            userKey,
            (draft) => {
              // move classes around in list
              if (firstPosition > secondPosition) {
                const temp =
                  draft.addedClasses.orderedClasses[termToUpdate][
                    firstPosition
                  ];

                for (let i = firstPosition; i > secondPosition; i--) {
                  draft.addedClasses.orderedClasses[termToUpdate][i] =
                    draft.addedClasses.orderedClasses[termToUpdate][i - 1];
                }

                draft.addedClasses.orderedClasses[termToUpdate][
                  secondPosition
                ] = temp;
              } else {
                const temp =
                  draft.addedClasses.orderedClasses[termToUpdate][
                    firstPosition
                  ];

                for (let i = firstPosition; i < secondPosition; i++) {
                  draft.addedClasses.orderedClasses[termToUpdate][i] =
                    draft.addedClasses.orderedClasses[termToUpdate][i + 1];
                }

                draft.addedClasses.orderedClasses[termToUpdate][
                  secondPosition
                ] = temp;
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch (e) {
          if (e.error.status !== 500) {
            patchResult.undo();
          }
        }
      }
    }),
    getReviewsByClass: builder.mutation({
      query: ({ masterCourseCode, userKey }) => ({
        url: '/classes/getReviews/' + masterCourseCode,
        method: 'GET',
        headers: {
          userKey
        }
      })
    }),
    getTermDates: builder.mutation<
      { startDate: string; endDate: string },
      { userKey: string; currentSection: string }
    >({
      query: ({ userKey, currentSection }) => ({
        url: 'randomShit/getTermDates',
        method: 'GET',
        headers: {
          userKey
        },
        body: {
          sectionCode: currentSection
        }
      })
    })
  })
});

export const {
  useGetAllClassesQuery,
  useGetCurrentSectionQuery,
  useAddClassMutation,
  useAddMultipleClassesMutation,
  useRemoveClassMutation,
  useIsAllowedToReviewQuery,
  useSendReviewMutation,
  useLazyGetAllClassesQuery,
  useLazyGetAllSectionsQuery,
  useRemoveStarredClassMutation,
  useAddStarredClassMutation,
  useSwapCoursePriorityMutation,
  useGetReviewsByClassMutation,
  useGetTermDatesMutation
} = classPagesApi;
