import { HeaderBackButton } from '@react-navigation/elements';
import { Platform } from 'react-native';

export default function BackButton({ navigation, ...props }) {
  return (
    <HeaderBackButton
      {...props}
      onPress={navigation.goBack}
      label="Back"
      labelVisible={Platform.OS === 'ios'}
    />
  );
}
