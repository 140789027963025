import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Layout, Text } from '@ui-kitten/components';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Image,
  RefreshControl,
  ScrollView,
  StyleSheet,
  TouchableOpacity
} from 'react-native';
import { useGetChatRoomsMutation, usersApiSlice } from '../api/usersApi';

import {
  selectDarkMode,
  selectIsGuest,
  selectUserKey
} from '../../app/appDataSlice';
import { useAppSelector } from '../../app/hooks';
import ModalContainer from '../../components/ModalContainer';
import { CurrentTermLabel } from '../classPages/ClassesList';
import SelectTerm from '../classPages/SelectTerm';
import CreateChatRoom from './CreateChatRoom';
import SingleChatRoom from './singleChatPage/SingleChatRoom';

const ChatRoomsNavigator = createNativeStackNavigator();

export default function ChatsRoomNavigation({ navigation }) {
  const isGuest = useAppSelector(selectIsGuest);
  const [showCreateChatModal, setShowCreateChatModal] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const darkMode = useAppSelector(selectDarkMode);
  const navigationHeader = (navigation) => (
    <Layout
      style={{
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        backgroundColor: 'transparent'
      }}
    >
      <Layout
        style={{
          paddingBottom: 5,
          flexDirection: 'row',
          alignContent: 'center',
          flex: 1,
          backgroundColor: 'transparent'
        }}
      >
        <Image
          style={{
            height: 28,
            width: 28
          }}
          source={require('../../../assets/logos/app-logo-transparent.png')}
        />
        <Text
          style={{
            paddingTop: 3,
            paddingLeft: 10,
            fontSize: 20,
            fontWeight: '500',
            color: darkMode ? '#fff' : '#000'
          }}
        >
          Chats
        </Text>
      </Layout>
      <Layout
        style={{
          alignSelf: 'flex-end',
          paddingRight: 40,
          paddingBottom: 10,
          flexDirection: 'row',
          backgroundColor: 'transparent'
        }}
      />
    </Layout>
  );

  return (
    <ChatRoomsNavigator.Navigator initialRouteName="Chats">
      <ChatRoomsNavigator.Screen
        options={({ navigation }) => ({
          title: 'Chats',
          headerTitle: () => {
            return navigationHeader(navigation);
          },
          headerRight: () => {
            return (
              <Layout style={{ marginRight: 20 }}>
                {!isGuest && (
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('CreateChatRoom');
                    }}
                  >
                    <FontAwesome5
                      size={24}
                      name="plus"
                      color={darkMode ? 'white' : 'black'}
                    />
                  </TouchableOpacity>
                )}
              </Layout>
            );
          }
        })}
        name="Chats"
        component={Chats}
      />
      <ChatRoomsNavigator.Screen
        name="SingleChatRoom"
        component={SingleChatRoom}
        options={{ headerTitle: '' }}
      />
      <ChatRoomsNavigator.Screen
        name="CreateChatRoom"
        component={CreateChatRoom}
        options={{ headerTitle: 'Create Chat Room' }}
      />
      <ChatRoomsNavigator.Screen
        name="SelectTerm"
        component={SelectTerm}
        options={{
          title: 'Select Term',
          headerShown: false,
          animation: 'slide_from_bottom',
          gestureDirection: 'vertical'
        }}
      />
    </ChatRoomsNavigator.Navigator>
  );
}

function Chats({ navigation }) {
  const [getChatRooms] = useGetChatRoomsMutation();
  const [showGuestModal, setShowGuestModal] = useState(false);
  const userKey = useAppSelector(selectUserKey);
  const isGuest = useAppSelector(selectIsGuest);
  const darkMode = useAppSelector(selectDarkMode);
  const [refreshing, setRefreshing] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const currentSection = useAppSelector(
    (state) => state.appData.classPages.currentSection
  );
  const [availableChats, setAvailableChats] = useState([]);
  const { data: userData } =
    usersApiSlice.endpoints.getUserInfo.useQueryState(userKey);

  function generateChatRooms() {
    getChatRooms({ userKey })
      .unwrap()
      .then(async (payload) => {
        const realPayload = JSON.parse(JSON.stringify(payload));
        const chats = [];
        if (userData.permissionLevel === 'admin') {
          chats.push({
            title: 'Admin Chatroom',
            lastModifiedDate: '',
            id: 'admin'
          });
        }
        try {
          for (const section of Object.entries(userData.addedClasses.classes)) {
            if (section[0] === currentSection) {
              for (const course of Object.values(section[1])) {
                chats.push({
                  title: course[0],
                  lastModifiedDate: '',
                  id: course[0].replace(' ', '')
                });
              }
            }
          }
        } catch (e) {
          console.warn(e.message);
          setRefreshing(false);
        }
        setAvailableChats(chats.concat(realPayload.reverse()));
        setTimeout(() => {
          setRefreshing(false);
        }, 300);
      })
      .catch((error) => {
        console.warn(error);
        setRefreshing(false);
      });
  }

  useEffect(() => {
    setRefreshing(true);
    generateChatRooms();
  }, [useIsFocused(), currentSection]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    generateChatRooms();
  }, []);

  const renderItem = (item) => {
    return (
      <Layout key={item?.id}>
        <ScrollView>
          <TouchableOpacity
            style={{
              alignItems: 'center',
              alignSelf: 'center',
              width: '95%',
              borderRadius: 10,
              marginLeft: 5,
              marginRight: 5,
              marginTop: 10
            }}
            onPress={() => {
              if (isGuest) {
                setShowGuestModal(true);
              } else {
                navigation.navigate('SingleChatRoom', {
                  data: item
                });
              }
            }}
            key={item?.id}
          >
            <Layout
              style={{
                alignItems: 'center',
                alignSelf: 'center',
                width: '95%',
                height: 75,
                borderRadius: 10,
                backgroundColor: darkMode ? '#333333' : '#ebebeb',
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 1 },
                shadowOpacity: 0.2,
                shadowRadius: 2,
                elevation: 1,
                marginLeft: 5,
                marginRight: 5,
                marginTop: 10,
                justifyContent: 'center'
              }}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 20
                }}
              >
                {item?.title}
              </Text>
              {item?.lastModifiedDate ? (
                <Text style={{ fontStyle: 'italic', fontSize: 12 }}>
                  {item?.lastModifiedDate}
                </Text>
              ) : null}
            </Layout>
          </TouchableOpacity>
        </ScrollView>
        {showGuestModal && (
          <ModalContainer
            title="Wait!"
            children={
              <Text style={{ paddingHorizontal: 30 }}>
                This feature is locked to our Guest Users. Please create a free
                account with us to take advantage this feature.
              </Text>
            }
            backDropVisible={showGuestModal}
            setBackDropVisible={setShowGuestModal}
          />
        )}
      </Layout>
    );
  };

  return (
    <Layout style={{ flex: 1 }}>
      <CurrentTermLabel
        navigation={navigation}
        currentSection={currentSection}
        style={{ marginTop: 20, marginLeft: 20 }}
      />
      {availableChats.length === 0 ? (
        <Layout style={{ alignItems: 'center' }}>
          <Text
            style={{
              paddingTop: '25%',
              paddingBottom: 10,
              textAlign: 'center'
            }}
          >
            No Available Chat Rooms
          </Text>
          <TouchableOpacity
            onPress={() => {
              onRefresh();
            }}
          >
            <FontAwesome size={25} color="#1E90FF" name="rotate-right" />
          </TouchableOpacity>
        </Layout>
      ) : null}
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        {availableChats.map((chat) => {
          return renderItem(chat);
        })}
      </ScrollView>
    </Layout>
  );
}

const sponsorPageStyles = StyleSheet.create({
  container: {
    flex: 1
    // alignItems: 'center',
  },
  header: {
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  address: {
    alignItems: 'center',
    margin: 10,
    borderColor: 'black',
    borderWidth: 1,
    padding: 10
  },
  video: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  title: {
    fontSize: 26,
    marginLeft: 10
  },
  logo: {
    width: '100%',
    height: 100
  },
  description: {
    flex: 1,
    margin: 10,
    borderRadius: 5,
    width: '95%',
    padding: 10
  },
  couponContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20
  },
  coupon: {
    width: 400,
    height: 267
  },
  imageGallery: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 10,
    marginBottom: 0
  },
  imageStyle: {
    width: 150,
    height: 150,
    paddingTop: 100,
    margin: 5
  },
  headerText: {
    fontSize: 20,
    fontWeight: 'bold'
  }
});

const myStyles = StyleSheet.create({
  inputBig: {
    height: 200,
    margin: 12,
    borderWidth: 1,
    padding: 10
  },
  input: {
    height: 50,
    margin: 12,
    borderWidth: 1,
    padding: 10
  },
  indentedInput: {
    height: 50,
    marginVertical: 12,
    borderWidth: 1,
    padding: 10,
    marginRight: 12,
    marginLeft: 30
  },
  text: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: 'bold',
    letterSpacing: 0.25
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 4,
    elevation: 3
  },
  timeButton: {
    width: 100,
    height: 50,
    marginHorizontal: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4
  },
  timeText: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: 'white'
  },
  actualTimeText: {
    fontSize: 13,
    lineHeight: 18,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: 'white'
  },
  timeStyle: {
    flex: 0.3,
    borderWidth: 2,
    borderLeftColor: 'white'
  }
});

const listingStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center'
  },
  cardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    width: '95%',
    height: 75,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ddd',
    borderBottomWidth: 0,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 1,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 10
  },
  containerForNotifications: {
    alignItems: 'center',
    alignSelf: 'center',
    width: '95%',
    height: 75,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ddd',
    borderBottomWidth: 0,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 1,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 10
  },
  text: {
    flex: 1,
    fontSize: 20,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 5
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 10
  }
});
