import React from 'react';
import { LogBox } from 'react-native';

import CalendarRender from './CalendarRender';

LogBox.ignoreAllLogs();

export default function CalendarPage({ navigation }) {
  return (
    <CalendarRender
      route={{
        params: {
          overrideData: []
        }
      }}
    />
  );
}

const myStyles = {
  input: {
    height: 50,
    margin: 12,
    borderWidth: 1,
    padding: 10
  },
  indentedInput: {
    height: 50,
    marginVertical: 12,
    borderWidth: 1,
    padding: 10,
    marginRight: 12,
    marginLeft: 30
  },
  text: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: 'white'
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: 'black'
  },
  timeButton: {
    width: 100,
    height: 50,
    marginHorizontal: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    backgroundColor: '#c45269'
  },
  timeText: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: 'white'
  },
  actualTimeText: {
    fontSize: 13,
    lineHeight: 18,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: 'white'
  }
};

const sponsorPageStyles = {
  container: {
    flex: 1,
    backgroundColor: 'white'
    // alignItems: 'center',
  },
  header: {
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  address: {
    alignItems: 'center',
    margin: 10,
    borderColor: 'black',
    borderWidth: 1,
    padding: 10
  },
  video: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  title: {
    fontSize: 26,
    marginLeft: 10
  },
  logo: {
    width: '100%',
    height: 100
  },
  description: {
    borderWidth: 1,
    margin: 10,
    borderColor: '#5d5c5c',
    borderRadius: 5,
    width: '95%',
    padding: 10,
    backgroundColor: 'rgba(178,166,166,0.22)'
  },
  couponContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20
  },
  coupon: {
    width: 400,
    height: 267
  },
  imageGallery: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 10,
    marginBottom: 0
  },
  imageStyle: {
    width: 150,
    height: 150,
    paddingTop: 100,
    margin: 5
  },
  headerText: {
    fontSize: 20,
    fontWeight: 'bold'
  }
};
