import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';

import { rootReducer } from './storage';
import { apiSlice } from '../features/api/apiSlice';

export const store = configureStore({
  reducer: {
    appData: rootReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  }).concat(apiSlice.middleware)
});

// serializableCheck: {
//   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//   ignoredPaths: [
//     'appData.classPages.classes',
//     'addData.classPages.renderedClasses',
//   ],
// },
// immutableCheck: {
//   ignoredPaths: [
//     'appData.classPages.classes',
//     'addData.classPages.renderedClasses',
//   ],
// },

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
