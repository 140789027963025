import { FontAwesome5 } from '@expo/vector-icons';
import { Layout, useTheme } from '@ui-kitten/components';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useState } from 'react';
import {
  Dimensions,
  Image,
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';

import { selectDarkMode, selectUserKey } from '../../app/appDataSlice';
import { useAppSelector } from '../../app/hooks';
import Menu from '../../components/Menu';
import {
  useLazyRefreshSponsorsQuery,
  usePostSponsorVisitMutation
} from '../api/sponsorsApi';

const windowWidth = Dimensions.get('window').width;

export function SponsorPage({
  route: {
    params: { data }
  }
}) {
  const isDarkMode = useAppSelector(selectDarkMode);
  const sponsorPageStyles = StyleSheet.create({
    container: {
      flex: 1
    },
    header: {
      marginTop: 20,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    },
    address: {
      color: isDarkMode ? 'white' : 'black',
      alignItems: 'center',
      margin: 10,
      borderColor: 'black',
      borderWidth: 1,
      padding: 10
    },
    video: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'flex-start'
    },
    title: {
      color: isDarkMode ? 'white' : 'black',
      fontSize: 26,
      marginLeft: 10
    },
    subtitle: {
      color: isDarkMode ? 'white' : 'black',
      fontSize: 14,
      justifyContent: 'center'
    },
    logo: {
      width: '100%',
      height: 100
    },
    description: {
      borderWidth: 1,
      margin: 10,
      borderColor: '#F1E4B5',
      borderRadius: 5,
      width: '95%',
      padding: 10
      // backgroundColor: '#F9F9F9',
    },
    couponContainer: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      marginBottom: 20
    },
    coupon: {
      width: 400,
      height: 267
    },
    imageGallery: {
      justifyContent: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: 10,
      marginBottom: 0
    },
    imageStyle: {
      width: 150,
      height: 150,
      paddingTop: 100,
      margin: 5
    },
    headerText: {
      color: isDarkMode ? 'white' : 'black',
      fontSize: 20,
      fontWeight: 'bold'
    }
  });
  const [imageIndex, setImageIndex] = useState(0);
  const [visible, setIsVisible] = useState(false);
  const [canOpenLink, setCanOpenLink] = useState(false);

  const [postSponsorVisitQuery] = usePostSponsorVisitMutation();

  const userKey = useAppSelector(selectUserKey);

  const videoLinkArray = data.videoLink && data.videoLink.split('/');
  const videoLink = data.videoLink && videoLinkArray[videoLinkArray.length - 1];

  function getSubtitle() {
    if (data.phoneNumber && data.email) {
      return `${data.phoneNumber} \u2022 ${data.email}`;
    }
    if (data.phoneNumber) {
      return data.phoneNumber;
    }
    if (data.email) {
      return data.email;
    }
    return null;
  }

  const postSponsorVisit = async () => {
    try {
      await postSponsorVisitQuery({ userKey, sponsorId: data._id }).unwrap();
    } catch (e) {
      console.warn('Error posting sponsor visit: ', e);
    }
  };

  useEffect(() => {
    const funct = async () => {
      setCanOpenLink(await Linking.canOpenURL(data.website));
      postSponsorVisit();
    };
    funct();
  }, []);

  function renderWebsiteLink() {
    if (canOpenLink) {
      return (
        <View>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL(data.website);
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Text
              style={{
                fontSize: 15,
                color: isDarkMode ? '#6b7ee5' : '#4b5dc7',
                paddingRight: 5
              }}
            >
              Check Out Our Website!
            </Text>
            <FontAwesome5 name="link" size={15} color="#4b5dc7" />
          </TouchableOpacity>
        </View>
      );
    }
    return null;
  }

  return (
    <Layout style={{ flex: 1 }}>
      <ScrollView>
        <Layout style={sponsorPageStyles.container}>
          <View style={sponsorPageStyles.header}>
            <Image
              resizeMode="contain"
              style={sponsorPageStyles.logo}
              source={{ uri: data.logo }}
            />
          </View>
          <View
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <Text style={sponsorPageStyles.title}>{data.sponsorName}</Text>
            <Text style={sponsorPageStyles.subtitle}>{getSubtitle()}</Text>
          </View>

          {data.location && (
            <View style={sponsorPageStyles.address}>
              <Text
                style={{ fontSize: 18, color: isDarkMode ? 'white' : 'black' }}
              >
                {data.location}
              </Text>
            </View>
          )}

          <View
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            {renderWebsiteLink()}
          </View>

          <Layout style={sponsorPageStyles.description}>
            <Text
              style={{ fontSize: 17, color: isDarkMode ? 'white' : 'black' }}
            >
              {data.description}
            </Text>
          </Layout>
          <View
            style={{
              width: '100%',
              alignSelf: 'flex-start',
              marginLeft: 20,
              borderBottomColor: '#F1E4B5',
              borderBottomWidth: 2,
              paddingBottom: 5
            }}
          >
            <Text style={sponsorPageStyles.headerText}>Image Gallery</Text>
          </View>

          <View style={sponsorPageStyles.imageGallery}>
            {data.images.map((img, index) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    setImageIndex(index);
                    setIsVisible(true);
                  }}
                >
                  <Image
                    style={sponsorPageStyles.imageStyle}
                    source={{ uri: img }}
                  />
                </TouchableOpacity>
              );
            })}
          </View>

          {data.coupons[0] && (
            <Layout style={sponsorPageStyles.couponContainer}>
              <Image
                resizeMode="contain"
                source={require('../../../assets/sponsorPage/coupon.png')}
                style={sponsorPageStyles.coupon}
              />
              <Text
                style={{
                  position: 'absolute',
                  fontSize: 30,
                  textAlign: 'center',
                  justifyContent: 'center',
                  width: '75%',
                  paddingBottom: 20,
                  paddingRight: 7
                }}
              >
                {data.coupons[0].couponDescription}
              </Text>
              <Text
                style={{ fontSize: 15, color: isDarkMode ? 'white' : 'black' }}
              >
                Show this coupon at the store to redeem.
              </Text>
            </Layout>
          )}

          <View
            style={{
              width: '100%',
              alignSelf: 'flex-start',
              marginLeft: 20,
              borderBottomColor: '#F1E4B5',
              borderBottomWidth: 2,
              paddingBottom: 5
            }}
          >
            <Text style={sponsorPageStyles.headerText}>Hours</Text>
          </View>
          <View
            style={{
              justifyContent: 'center',
              alignSelf: 'center',
              marginLeft: 25,
              marginBottom: 50
            }}
          >
            {data.hours.map((day, i) => {
              return (
                <Layout
                  key={i}
                  style={{
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <Text
                    style={{
                      marginTop: 10,
                      color: isDarkMode ? 'white' : 'black'
                    }}
                  >
                    {day.dayOfTheWeek}:
                  </Text>
                  <Text
                    style={{
                      alignSelf: 'flex-end',
                      paddingRight: 30,
                      marginTop: 10,
                      color: isDarkMode ? 'white' : 'black'
                    }}
                  >
                    {buildTimeString(day)}
                  </Text>
                </Layout>
              );
            })}
          </View>
        </Layout>
      </ScrollView>
    </Layout>
  );
}

function buildTimeString(time) {
  if (!time.openTime || time.openTime === '' || time.openTime === null) {
    return 'closed';
  }
  return `${convertTo12HourTime(time.openTime)} - ${convertTo12HourTime(
    time.closeTime
  )}`;
}

function convertTo12HourTime(time) {
  if (time.length !== 5) {
    time = `0${time}`;
  }
  let hours = time.slice(0, 2);
  const minutes = time.slice(3, 5);
  let ampm = Number.parseInt(hours) > 12 ? 'pm' : 'am';
  hours %= 12;
  if (hours === 0) {
    hours = 12;
    ampm = 'pm';
  }
  return `${hours}:${minutes}${ampm}`;
}

export function SponsorsList({ navigation }) {
  const theme = useTheme();
  const isDarkMode = useAppSelector(selectDarkMode);
  const [sponsorFilters, setSponsorFilters] = useState({});
  const [gotSponsors, setGotSponsors] = useState(false);
  const userKey = useAppSelector(selectUserKey);
  const [refreshSponsorsQuery, { data: sponsors }] =
    useLazyRefreshSponsorsQuery();

  if (!gotSponsors) {
    setGotSponsors(true);
    refreshSponsors();
  }

  useEffect(() => {
    if (sponsors?.length > 0) {
      getFilters();
    }
  }, [sponsors]);

  async function refreshSponsors() {
    try {
      await refreshSponsorsQuery(userKey).unwrap();
    } catch (e) {
      console.warn('Error refreshing sponsors: ', e);
    }
  }

  function getFilters() {
    let tabNames = [];
    const final = [];
    for (const sponsor of sponsors) {
      if (sponsor.tags !== undefined) {
        for (const name of sponsor.tags) {
          if (!tabNames.includes(name)) {
            tabNames.push(name);
          }
        }
      }
    }
    tabNames = tabNames.sort();

    // Puts 'Other' at the end
    const temp = tabNames.includes('Other')
      ? tabNames.findIndex((item) => item === 'Other')
      : -1;
    if (temp !== -1) {
      tabNames.splice(temp, 1);
    }
    tabNames.push('Other');

    const tempFilters = {};
    for (const name of tabNames) {
      tempFilters[name] = {
        selected: false
      };
    }
    setSponsorFilters(tempFilters);
  }

  function renderSponsorsBasedOnFilters() {
    let shouldLimitPrint = false;
    for (const filterName in sponsorFilters) {
      if (sponsorFilters[filterName].selected) {
        shouldLimitPrint = true;
        break;
      }
    }
    if (!shouldLimitPrint) {
      return getTheSponsors(sponsors || [], navigation);
    }
    const sponsorsToSend = [];
    for (const filterName in sponsorFilters) {
      if (sponsorFilters[filterName].selected) {
        for (const sponsor of sponsors) {
          if (sponsor.tags.includes(filterName)) {
            sponsorsToSend.push(sponsor);
          }
        }
      }
    }
    return getTheSponsors(sponsorsToSend || [], navigation);
  }

  return (
    <LinearGradient
      colors={
        isDarkMode
          ? [theme['color-basic-1100'], theme['color-basic-1100']]
          : [theme['color-primary-500'], theme['color-success-500']]
      }
      style={{
        width: '100%',
        margin: 0,
        alignItems: 'center',
        flex: 1
      }}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0.4 }}
    >
      <Layout style={{ flex: 1 }}>
        <Menu
          setMenuItems={setSponsorFilters}
          menuItems={Object.keys(sponsorFilters).length > 0}
        />
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ alignItems: 'center' }}
        >
          {sponsors?.length === 0 ? (
            <Text>Nothing to see here</Text>
          ) : (
            renderSponsorsBasedOnFilters()
          )}
        </ScrollView>
      </Layout>
    </LinearGradient>
  );
}

function getTheSponsors(sponsors, navigation) {
  const isDarkMode = useAppSelector(selectDarkMode);
  const listingStyles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center'
    },
    cardContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      maxWidth: windowWidth / 2,
      height: 130,
      backgroundColor: isDarkMode ? '#464646' : '#fff',
      borderRadius: 10,
      borderWidth: 1,
      borderColor: isDarkMode ? '#818181' : '#ddd',
      borderBottomWidth: isDarkMode ? 1 : 0,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 2,
      elevation: 1,
      marginLeft: 5,
      marginRight: 5,
      marginTop: 10
    },
    text: {
      flex: 1,
      color: isDarkMode ? 'white' : 'black',
      fontSize: 20,
      textAlign: 'center',
      marginTop: 5,
      marginBottom: 5
    },
    image: {
      width: 120,
      height: 120,
      marginLeft: 10,
      borderRadius: 10
    }
  });
  if (sponsors.length === 0) {
    return;
  }
  const final = [];
  const starredFinal = [];
  for (const sponsor of sponsors) {
    if (sponsor.coupons && sponsor.coupons.length > 0) {
      starredFinal.push(
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('SponsorPage', { data: sponsor });
          }}
          style={listingStyles.cardContainer}
          key={sponsor._id}
        >
          {sponsor.coupons?.length ? (
            <FontAwesome5
              name="star"
              color="gold"
              style={{
                alignSelf: 'flex-start',
                top: 5,
                left: 5
              }}
            />
          ) : null}

          <View style={{ flex: 1 }}>
            <Image
              resizeMode="contain"
              style={listingStyles.image}
              source={{ uri: sponsor.logo }}
            />
          </View>

          <Text style={listingStyles.text}>{sponsor.sponsorName}</Text>
        </TouchableOpacity>
      );
    } else {
      final.push(
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('SponsorPage', { data: sponsor });
          }}
          style={listingStyles.cardContainer}
          key={sponsor._id}
        >
          {sponsor.coupons?.length ? (
            <FontAwesome5
              name="star"
              color="gold"
              style={{
                position: 'absolute',
                alignSelf: 'flex-start',
                top: 5,
                left: 5
              }}
            />
          ) : null}

          <View style={{ flex: 1 }}>
            <Image
              resizeMode="contain"
              style={listingStyles.image}
              source={{ uri: sponsor.logo }}
            />
          </View>

          <Text style={listingStyles.text}>{sponsor.sponsorName}</Text>
        </TouchableOpacity>
      );
    }
  }
  for (const object of final) {
    starredFinal.push(object);
  }
  return starredFinal;
}
