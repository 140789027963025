import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useTheme } from '@ui-kitten/components';

import SignupForm from './SignupForm';
import VerifyEmail from './VerifyEmail';
import { selectDarkMode } from '../../../app/appDataSlice';
import { useAppSelector } from '../../../app/hooks';
import BackButton from '../../../components/BackButton';

const Stack = createNativeStackNavigator();

export default function Signup() {
  const theme = useTheme();
  const darkMode = useAppSelector(selectDarkMode);

  return (
    <Stack.Navigator
      screenOptions={({ navigation }) => ({
        headerStyle: {
          backgroundColor: darkMode ? theme['color-basic-800'] : '#fff'
        },
        headerLeft: (props) => <BackButton {...props} navigation={navigation} />
      })}
    >
      <Stack.Screen
        name="SignupForm"
        component={SignupForm}
        options={{ title: 'Signup' }}
      />
      <Stack.Screen
        name="VerifyEmail"
        component={VerifyEmail}
        options={{ title: 'Verify Email' }}
      />
    </Stack.Navigator>
  );
}
