import { FontAwesome5 } from '@expo/vector-icons';
import { Card, Modal, Text } from '@ui-kitten/components';
import React, { useState } from 'react';
import { Alert, Button, Platform, TouchableOpacity, View } from 'react-native';

import {
  selectDarkMode,
  selectIsGuest,
  selectUserKey
} from '../../../app/appDataSlice';
import { useAppSelector } from '../../../app/hooks';
import ModalContainer from '../../../components/ModalContainer';
import UserNameSelectionDropDown from '../../../components/UserNameSelectionDropDown';
import { useGetUserInfoQuery } from '../../api/usersApi';
import { base_URL } from '../../notifications/constants';

export default function ExportModalClass({ classData }) {
  const isGuest = useAppSelector(selectIsGuest);
  const [showGuestModal, setShowGuestModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showWebModal, setShowWebModal] = useState(false);
  const darkMode = useAppSelector(selectDarkMode);

  return (
    <View
      style={{
        marginRight: '7%',
        flexDirection: 'column-reverse'
      }}
    >
      <TouchableOpacity
        onPress={() => {
          if (isGuest) {
            setShowGuestModal(true);
          } else if (Platform.OS === 'web') {
            setShowWebModal(true);
          } else {
            setShowModal(true);
          }
        }}
        style={{
          alignItems: 'center'
        }}
      >
        <View
          style={{
            height: 45,
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: 5
          }}
        >
          <FontAwesome5
            name="file-export"
            size={20}
            color={darkMode ? 'white' : 'black'}
          />
        </View>
        <Text style={{ marginTop: -10, fontSize: 10 }}>Share Class</Text>
      </TouchableOpacity>
      {showWebModal && (
        <ModalContainer
          title="Wait!"
          children={
            <Text style={{ paddingHorizontal: 30 }}>
              Sharing courses is not supported on the web. Download the app on
              the App Store or Play Store to share courses.
            </Text>
          }
          backDropVisible={showWebModal}
          setBackDropVisible={setShowWebModal}
        />
      )}
      {showGuestModal && (
        <ModalContainer
          title="Wait!"
          children={
            <Text style={{ paddingHorizontal: 30 }}>
              This feature is not available to Guest Users. Please create a free
              account with us to take advantage this feature.
            </Text>
          }
          backDropVisible={showGuestModal}
          setBackDropVisible={setShowGuestModal}
        />
      )}
      {renderModal(setShowModal, showModal, classData)}
    </View>
  );
}

function renderModal(setShowModal, showModal, classData: any) {
  const { data: userData } = useGetUserInfoQuery(useAppSelector(selectUserKey));

  const [value, setValue] = useState('');
  const darkMode = useAppSelector(selectDarkMode);
  const userKey = useAppSelector(selectUserKey);
  const [recipient, setRecipient] = useState(null);
  const [hasChosen, setHasChosen] = useState(false);

  const myStyles = {
    modal: {
      fontSize: 18,
      paddingBottom: 20,
      fontWeight: 'bold',
      textAlign: 'center',
      width: '100%',
      color: darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'
    },
    backdrop: {
      backgroundColor: darkMode ? 'rgba(141,141,141,0.5)' : 'rgba(0, 0, 0, 0.5)'
    },
    containerDrop: {
      minWidth: 200,
      paddingBottom: 15
    }
  };

  const exportClass = async (recipient) => {
    try {
      const status = await fetch(`${base_URL}/users/sendNotification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          userKey
        },
        body: JSON.stringify({
          recipients: [recipient],
          text: JSON.stringify({
            masterCourseCode: classData.masterCourseCode,
            courseTerm: classData.courseTerm
          }),
          title: `${userData.realFirstName} shared a course`,
          isCourseData: true
        })
      });
      return status.ok;
    } catch (e) {
      console.warn(e);
      return false;
    }
  };
  return (
    <Modal
      visible={showModal}
      backdropStyle={myStyles.backdrop}
      style={{ width: '80%', height: '70%' }}
      onBackdropPress={() => setShowModal(false)}
    >
      <Card disabled>
        <Text style={myStyles.modal}>Share This Course</Text>
        {UserNameSelectionDropDown({
          setHasChosen,
          setRecipient,
          placeholder: true
        })}
        <View>
          <Button
            disabled={recipient === null}
            title="Send"
            onPress={() => {
              exportClass(recipient).then((returnValue) => {
                setShowModal(false);
                returnValue
                  ? Alert.alert('Message Sent')
                  : Alert.alert(
                      'Error Sending Message',
                      'Please Try Again Later'
                    );
              });
            }}
          />
          <Button
            title="Dismiss"
            onPress={() => {
              setShowModal(false);
              setHasChosen(false);
              setValue('');
              setRecipient(null);
            }}
          />
        </View>
      </Card>
    </Modal>
  );
}
