import { FontAwesome5, Foundation, Ionicons } from '@expo/vector-icons';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Layout, Text, useTheme } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { Image, Platform, TouchableOpacity } from 'react-native';

import { selectDarkMode, selectUserKey } from '../../app/appDataSlice';
import { useAppSelector } from '../../app/hooks';
import Header from '../../components/Header';
import NotificationsNavigation from '../notifications/Notifications';
import { base_URL } from '../notifications/constants';
import Settings from '../settings/index';
import { SponsorPage, SponsorsList } from '../sponsors/SponsorsList';
import ClassesList from './ClassesList';
import ElectiveGenerator from './ElectiveGenerator';
import SelectTerm from './SelectTerm';
import SingleClassPage from './SingleClassPage/index';

const Stack = createNativeStackNavigator();

export default function SearchClasses({ navigation }) {
  const theme = useTheme();
  const hasSelectedElectiveGenerator = useAppSelector(
    (state) => state.appData.classPages.hasSelectedElectiveGen
  );
  const userKey = useAppSelector(selectUserKey);
  const darkMode = useAppSelector(selectDarkMode);
  const [numberOfNotifications, setNumberOfNotifications] = useState(0);

  function refreshNotifications() {
    fetch(`${base_URL}/users/getNotifications`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        userKey
      }
    }).then((res) => {
      res.text().then((notifications) => {
        notifications = JSON.parse(notifications);
        setNumberOfNotifications(notifications.length);
      });
    });
  }

  useEffect(() => {
    refreshNotifications();
    setInterval(() => {
      refreshNotifications();
    }, 60000); // refresh every minute
  }, []);

  const accessoryLeft = (
    <Image
      style={{
        height: 28,
        width: 28
      }}
      source={require('../../../assets/logos/app-logo-transparent.png')}
    />
  );

  const accessoryRight = (
    <Layout
      style={{
        flexDirection: 'row',
        width: 120,
        justifyContent: 'space-between'
      }}
    >
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('ElectiveGenerator');
        }}
        style={{ flexDirection: 'row', alignItems: 'center' }}
      >
        {hasSelectedElectiveGenerator ? (
          <FontAwesome5
            name="brain"
            size={23}
            color={
              darkMode ? theme['color-basic-200'] : theme['color-basic-800']
            }
          />
        ) : (
          <Foundation
            name="burst-new"
            size={30}
            color={theme['color-warning-500']}
          />
        )}
      </TouchableOpacity>

      {false && (
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('SponsorsList');
          }}
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <FontAwesome5
            name="shopping-bag"
            size={23}
            color={
              darkMode ? theme['color-basic-200'] : theme['color-basic-800']
            }
          />
        </TouchableOpacity>
      )}

      <TouchableOpacity
        onPress={() => {
          navigation.navigate('Settings');
        }}
        style={{ flexDirection: 'row', alignItems: 'center' }}
      >
        <FontAwesome5
          name="user-alt"
          size={23}
          color={darkMode ? theme['color-basic-200'] : theme['color-basic-800']}
        />
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => {
          navigation.navigate('NotificationsPage');
        }}
        style={{ flexDirection: 'row', alignItems: 'center' }}
      >
        <FontAwesome5
          name="bell"
          size={23}
          color={darkMode ? theme['color-basic-200'] : theme['color-basic-800']}
        />
        {numberOfNotifications > 0 ? (
          <Layout
            style={{
              position: 'absolute',
              backgroundColor: darkMode ? 'red' : '#fff',
              borderRadius: 10,
              paddingHorizontal: 3,
              right: -7,
              top: -3
            }}
          >
            <Text>{numberOfNotifications}</Text>
          </Layout>
        ) : null}
      </TouchableOpacity>
    </Layout>
  );

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ClassesList"
        component={ClassesList}
        options={{
          header: () => (
            <Layout style={{ paddingBottom: 10 }}>
              <Header
                accessoryLeft={accessoryLeft}
                accessoryRight={accessoryRight}
                title="Athena"
              />
            </Layout>
          ),
          title: 'Class Pages'
        }}
      />
      <Stack.Screen
        name="Settings"
        component={Settings}
        options={{
          headerShown: false
        }}
      />
      <Stack.Group screenOptions={{ headerTitle: 'Sponsors' }}>
        <Stack.Screen
          name="SponsorsList"
          component={SponsorsList}
          options={{ title: 'Sponsors' }}
        />
        <Stack.Screen
          name="SponsorPage"
          component={SponsorPage}
          options={{ title: 'Sponsors' }}
        />
      </Stack.Group>
      <Stack.Screen
        name="NotificationsPage"
        component={NotificationsNavigation}
        options={{ headerShown: false, title: 'Notifications' }}
      />
      <Stack.Screen
        name="SelectTerm"
        component={SelectTerm}
        options={{
          title: 'Select Term',
          headerShown: false,
          animation: 'slide_from_bottom',
          gestureDirection: 'vertical'
        }}
      />
      <Stack.Screen
        name="ElectiveGenerator"
        component={ElectiveGenerator}
        options={{
          title: 'Elective Generator',
          animation: 'slide_from_bottom',
          gestureDirection: 'vertical'
        }}
      />
      {/* Show a full page screen for web (modal for mobile) */}
      {Platform.OS === 'web' && (
        <Stack.Screen
          name="SingleClassPage"
          component={SingleClassPage}
          options={{
            headerShown: false,
            title: 'ClassPages'
          }}
        />
      )}
    </Stack.Navigator>
  );
}
