import { createNativeStackNavigator } from '@react-navigation/native-stack';

import InfoPage from './InfoPage';
import PostReview from './PostReview';

const Stack = createNativeStackNavigator();

export default function SingleClassPage() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="InfoPage"
        component={InfoPage}
        options={{ title: 'ClassPages' }}
      />
      <Stack.Screen
        name="PostReview"
        component={PostReview}
        options={{ title: 'Post Review' }}
      />
    </Stack.Navigator>
  );
}
