import { Input, Layout, Spinner, Text } from '@ui-kitten/components';
import { TouchableWithoutFeedback } from '@ui-kitten/components/devsupport';
import { useState } from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity
} from 'react-native';

import { selectUserKey } from '../../app/appDataSlice';
import { useAppSelector } from '../../app/hooks';
import WrapItems from '../../components/WrapItems';
import { useEditUserMutation, useGetUserInfoQuery } from '../api/usersApi';

function EditProfileHeader({ navigation, saveChanges }) {
  return (
    <Layout
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 20,
        marginTop: Platform.OS !== 'ios' ? 40 : 20
      }}
    >
      <TouchableOpacity
        onPress={() => {
          navigation.goBack();
        }}
      >
        <Text>Cancel</Text>
      </TouchableOpacity>
      <Text category="s1">Edit Profile</Text>
      <TouchableOpacity onPress={saveChanges}>
        <Text>Save</Text>
      </TouchableOpacity>
    </Layout>
  );
}

export default function EditUser({ navigation }) {
  const userKey = useAppSelector(selectUserKey);
  const { data: userData } = useGetUserInfoQuery(userKey, {
    skip: !userKey
  });

  const inputs = [
    {
      placeholder: '*******',
      label: 'password',
      name: 'password'
    },
    {
      placeholder: userData.realFirstName,
      label: 'First Name',
      name: 'realFirstName'
    },
    {
      placeholder: userData.realLastName,
      label: 'Last Name',
      name: 'realLastName'
    },
    {
      placeholder: userData.profileDescription,
      label: 'Profile Description',
      name: 'profileDescription',
      propOptions: { multiline: true, textStyle: { minHeight: 64 } }
    },
    {
      placeholder: userData.linkedin,
      label: 'LinkedIn',
      name: 'linkedin'
    },
    {
      placeholder: userData.major,
      label: 'Major',
      name: 'major'
    }
  ];

  const [fields, setFields] = useState(
    Object.fromEntries(inputs.map((input) => [input.name, '']))
  );
  const [editUser, { isLoading }] = useEditUserMutation();

  const saveChanges = async () => {
    const changedFields = Object.entries(fields).filter(
      ([_, value]) => value !== ''
    );

    try {
      if (changedFields.length > 0) {
        await editUser({
          userKey,
          userName: userData.userName,
          fields: Object.fromEntries(changedFields)
        }).unwrap();
      }

      navigation.goBack();
    } catch (err) {
      console.warn('Error editing user', err);
    }
  };

  return (
    <Layout style={{ flex: 1 }}>
      {isLoading ? (
        <Layout
          style={{
            flex: 1,
            backgroundColor: 'transparent',
            position: 'absolute',
            top: '30%',
            right: '50%',
            zIndex: 1
          }}
        >
          <Spinner size="medium" />
        </Layout>
      ) : null}

      <EditProfileHeader navigation={navigation} saveChanges={saveChanges} />
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <TouchableWithoutFeedback
          style={{ flex: 1 }}
          onPress={Keyboard.dismiss}
        >
          <WrapItems
            style={{ marginVertical: 20 }}
            childStyle={{ marginHorizontal: 40, marginVertical: 10 }}
          >
            {inputs.map(({ placeholder, label, name, propOptions }) => (
              <Input
                key={name}
                placeholder={placeholder}
                label={label}
                onChangeText={(text) => setFields({ ...fields, [name]: text })}
                {...propOptions}
              />
            ))}
            <Input placeholder={userData?.email} label="Email" disabled />
          </WrapItems>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </Layout>
  );
}
