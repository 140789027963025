export default {
  'color-basic-100': '#FFFFFF',
  'color-basic-200': '#F5F5F5',
  'color-basic-300': '#D4D4D4',
  'color-basic-400': '#B3B3B3',
  'color-basic-500': '#808080',
  'color-basic-600': '#4A4A4A',
  'color-basic-700': '#383838',
  'color-basic-800': '#292929',
  'color-basic-900': '#1F1F1F',
  'color-basic-1000': '#141414',
  'color-basic-1100': '#000000',
  'color-primary-100': '#FFE0D8',
  'color-primary-200': '#FFBBB2',
  'color-primary-300': '#FF8E8B',
  'color-primary-400': '#FF6F78',
  'color-primary-500': '#FF3F5E',
  'color-primary-600': '#DB2E5A',
  'color-primary-700': '#B71F54',
  'color-primary-800': '#93144C',
  'color-primary-900': '#7A0C47',
  'color-success-100': '#FBD4F0',
  'color-success-200': '#F8ABE8',
  'color-success-300': '#EA7EDC',
  'color-success-400': '#D55AD0',
  'color-success-500': '#b42bba',
  'color-success-600': '#8F1F9F',
  'color-success-700': '#6E1585',
  'color-success-800': '#500D6B',
  'color-success-900': '#3A0859',
  'color-info-100': '#CDFDFE',
  'color-info-200': '#9CF4FE',
  'color-info-300': '#6AE4FC',
  'color-info-400': '#45D0FA',
  'color-info-500': '#09B0F7',
  'color-info-600': '#0689D4',
  'color-info-700': '#0466B1',
  'color-info-800': '#02498F',
  'color-info-900': '#013476',
  'color-warning-100': '#FFF6CC',
  'color-warning-200': '#FFEA99',
  'color-warning-300': '#FFDC66',
  'color-warning-400': '#FFCD3F',
  'color-warning-500': '#FFB600',
  'color-warning-600': '#DB9600',
  'color-warning-700': '#B77800',
  'color-warning-800': '#935C00',
  'color-warning-900': '#7A4800',
  'color-danger-100': '#FEE5DE',
  'color-danger-200': '#FEC6BE',
  'color-danger-300': '#FEA09D',
  'color-danger-400': '#FD858D',
  'color-danger-500': '#FC5D77',
  'color-danger-600': '#D8436A',
  'color-danger-700': '#B52E5E',
  'color-danger-800': '#921D51',
  'color-danger-900': '#781149',
  'color-grey-800': '#1F1F1F',
  'color-grey-700': '#2F2F2F',
  'color-grey-600': '#3F3F3F',
  'color-grey-500': '#4F4F4F',
  'color-grey-400': '#5F5F5F',
  'color-grey-300': '#6F6F6F',
  'color-grey-200': '#7F7F7F',
  'color-grey-100': '#8F8F8F',
  'color-grey-50': '#9F9F9F'
};
