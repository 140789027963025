import { Entypo, Ionicons } from '@expo/vector-icons';
import { BottomSheetMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import { useNavigation } from '@react-navigation/native';
import { Divider, Layout, Text, useTheme } from '@ui-kitten/components';
import React from 'react';
import { Platform, ScrollView, TouchableOpacity } from 'react-native';
import DraggableFlatList, {
  RenderItemParams,
  ScaleDecorator
} from 'react-native-draggable-flatlist';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { classSelected, isHMCUpdated } from './classPagesSlice';
import { showToast } from '../../functions';
import StarredClasses from './StarredClasses';

type AddedClassesProps = {
  setAddedClassesVisible: (visible: boolean) => void;
  addedClasses: string[];
  handleRemoveClass: (courseCode: string, masterCourseCode: string) => void;
  darkMode: boolean;
  handleSwapAddedClasses: (from: number, to: number) => void;
  bottomSheetRef: React.MutableRefObject<BottomSheetMethods>;
  starredClasses: any[];
  currentSection: string;
  title?: JSX.Element;
};

type AddedClassListing = [
  masterCourseCode: string,
  courseCode: string,
  courseName: string
];

export default function AddedClasses({
  setAddedClassesVisible,
  addedClasses,
  handleRemoveClass,
  darkMode,
  handleSwapAddedClasses,
  bottomSheetRef,
  starredClasses,
  currentSection,
  title
}: AddedClassesProps) {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const isHMC = useAppSelector((state) => state.appData.classPages.isHMC);
  const totalCredits = useAppSelector(
    (state) => state.appData.classPages.totalCredits
  );
  const allClassesList = useAppSelector(
    (state) => state.appData.classPages.classes
  );

  const addedClassesList: AddedClassListing[] = [];

  for (let i = 0; i < addedClasses.length; i++) {
    const courseCode = addedClasses[i];
    try {
      const courseCodeParts = courseCode.split('-');
      const masterCourseCode = courseCodeParts
        .slice(0, courseCodeParts.length - 1)
        .join('-');
      const { courseName } = allClassesList[masterCourseCode];
      addedClassesList.push([
        masterCourseCode,
        courseCode,
        courseName
      ] as AddedClassListing);
    } catch (e) {
      console.warn('Error finding added class', courseCode, e);
    }
  }

  function handleClassSelected(courseCode: string) {
    dispatch(classSelected(courseCode));
    setAddedClassesVisible(false);
    if (Platform.OS === 'web') {
      navigation.navigate('SingleClassPage');
    } else {
      bottomSheetRef.current?.snapToIndex(0);
    }
  }

  const renderItem = ({
    item: [masterCourseCode, courseCode, courseName],
    drag,
    isActive
  }: RenderItemParams<AddedClassListing>) => {
    return (
      <ScaleDecorator>
        <Layout
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingVertical: 7,
            paddingHorizontal: 10,
            backgroundColor: isActive
              ? theme['color-success-200']
              : 'transparent'
          }}
        >
          <Layout
            style={{ flexDirection: 'row', backgroundColor: 'transparent' }}
          >
            <TouchableOpacity disabled={isActive} onLongPress={drag}>
              <Ionicons name="reorder-three" size={28} />
            </TouchableOpacity>
            <Layout style={{ backgroundColor: 'transparent' }}>
              <TouchableOpacity
                onPress={() => {
                  handleClassSelected(courseCode.slice(0, -3));
                }}
              >
                <Text category="s1">{courseName}</Text>
                <Text category="p2">{courseCode}</Text>
              </TouchableOpacity>
            </Layout>
          </Layout>

          <TouchableOpacity
            onPress={() => handleRemoveClass(courseCode, masterCourseCode)}
          >
            <Entypo name="cross" size={28} color="red" />
          </TouchableOpacity>
        </Layout>
      </ScaleDecorator>
    );
  };

  const renderFlatList = () => {
    return (
      <DraggableFlatList
        persistentScrollbar
        indicatorStyle={darkMode ? 'white' : 'black'}
        showsVerticalScrollIndicator
        ListEmptyComponent={
          <Text style={{ marginHorizontal: 50 }}>
            No classes yet... Click the{' '}
            <Text style={{ fontWeight: 'bold' }}>+</Text> next to a class to see
            it here
          </Text>
        }
        ItemSeparatorComponent={() => <Divider />}
        data={
          addedClassesList === undefined || addedClassesList.length === 0
            ? []
            : addedClassesList
        }
        onDragEnd={({ from, to }) => {
          handleSwapAddedClasses(from, to);
        }}
        keyExtractor={(item) => item[1]}
        renderItem={renderItem}
        ListHeaderComponent={title || null}
        ListFooterComponent={
          <>
            {totalCredits.credits > 0 && (
              <TouchableOpacity
                onPress={() => {
                  const toastMessage = !isHMC
                    ? 'Switched to HMC credits'
                    : 'Switched to 4C credits';
                  showToast(toastMessage);
                  dispatch(isHMCUpdated(!isHMC));
                }}
              >
                <Text style={{ textAlign: 'center' }}>
                  {`${
                    isHMC ? totalCredits.hmcCredits : totalCredits.credits
                  } Credit(s)`}
                </Text>
              </TouchableOpacity>
            )}

            {starredClasses.length > 0 && (
              <StarredClasses
                bottomSheetRef={bottomSheetRef}
                setAddedClassesVisible={setAddedClassesVisible}
                courseTerm={currentSection}
                darkMode={darkMode}
              />
            )}
          </>
        }
      />
    );
  };

  return (
    <Layout
      style={{
        flex: 1,
        backgroundColor: darkMode
          ? theme['color-basic-900']
          : theme['color-basic-100']
      }}
    >
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        {renderFlatList()}
      </ScrollView>
    </Layout>
  );
}
