import {
  Autocomplete,
  AutocompleteItem,
  Card,
  Modal
} from '@ui-kitten/components';
import {
  JSXElementConstructor,
  ReactElement,
  useEffect,
  useState
} from 'react';
import { Alert, Button, View, Text } from 'react-native';

import renderItem from './ScheduledItem';
import { selectDarkMode, selectUserKey } from '../../app/appDataSlice';
import { useAppSelector } from '../../app/hooks';
import UserNameSelectionDropDown from '../../components/UserNameSelectionDropDown';
import { useGetCurrentSectionQuery } from '../api/classPagesApi';
import { useGetUserInfoQuery } from '../api/usersApi';
import { base_URL } from '../notifications/constants';

export default function renderModal(setShowModal, showModal) {
  const { data: userData } = useGetUserInfoQuery(useAppSelector(selectUserKey));
  const { data: currentSection } = useGetCurrentSectionQuery();
  const darkMode = useAppSelector(selectDarkMode);
  const userKey = useAppSelector(selectUserKey);
  const [names, setNames] = useState([]);
  const [users, setUsers] = useState([]);
  const [value, setValue] = useState('');
  const [hasChosen, setHasChosen] = useState(false);
  const [recipient, setRecipient] = useState(null);

  const myStyles = {
    modal: {
      fontSize: 18,
      paddingBottom: 20,
      fontWeight: 'bold',
      textAlign: 'center',
      width: '100%',
      color: darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'
    },
    backdrop: {
      backgroundColor: darkMode ? 'rgba(141,141,141,0.5)' : 'rgba(0, 0, 0, 0.5)'
    },
    containerDrop: {
      minWidth: 200,
      paddingBottom: 15
    }
  };

  useEffect(() => {
    fetch(`${base_URL}/users/userNamesAndNames`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        userKey
      }
    }).then(async (data) => {
      if (data.ok) {
        const userList = await data.json();
        setNames(
          userList.map((user) => {
            if (user) {
              return `${user.realName} (${user.userName})`;
            }
          })
        );
        setUsers(userList);
      }
    });
  }, []);

  const exportSchedule = async (recipient) => {
    function convert(addedClasses) {
      const final = [];
      Object.keys(addedClasses).map((term) => {
        if (term === currentSection) {
          Object.keys(addedClasses[term]).map((masterCourseCode) => {
            for (const courseCode of addedClasses[term][masterCourseCode]) {
              final.push({
                courseCode,
                courseTerm: currentSection
              });
            }
          });
        }
      });
      return final;
    }
    const status = await fetch(`${base_URL}/users/sendNotification`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        userKey
      },
      body: JSON.stringify({
        recipients: [recipient],
        text: JSON.stringify(convert(userData.addedClasses?.classes)),
        title: `${userData.realFirstName}'s Schedule (${currentSection})`,
        isSchedule: true
      })
    });
    return status.ok;
  };
  return (
    <Modal
      visible={showModal}
      backdropStyle={myStyles.backdrop}
      style={{ width: '80%', height: '70%' }}
      onBackdropPress={() => setShowModal(false)}
    >
      <Card disabled>
        <Text style={myStyles.modal}>Share Your Schedule</Text>
        {UserNameSelectionDropDown({
          setHasChosen,
          setRecipient,
          placeholder: false
        })}
        <View>
          <Button
            disabled={recipient === null}
            title="Send"
            onPress={() => {
              exportSchedule(recipient).then((returnValue) => {
                setShowModal(false);
                returnValue
                  ? Alert.alert('Message Sent')
                  : Alert.alert(
                      'Error Sending Message',
                      'Please Try Again Later'
                    );
              });
            }}
          />
          <Button
            title="Dismiss"
            onPress={() => {
              setShowModal(false);
            }}
          />
        </View>
      </Card>
    </Modal>
  );
}
