import * as eva from '@eva-design/eva';
import { Ionicons } from '@expo/vector-icons';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import NetInfo, { NetInfoConfiguration } from '@react-native-community/netinfo';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
  useNavigation
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
  ApplicationProvider,
  Card,
  IconRegistry,
  Layout,
  Radio,
  Text,
  useTheme
} from '@ui-kitten/components';
import Constants from 'expo-constants';
import { isDevice } from 'expo-device';
import * as Linking from 'expo-linking';
import { Subscription } from 'expo-modules-core';
import * as Notifications from 'expo-notifications';
import * as ExpoSplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useRef, useState } from 'react';
import {
  Alert, Image,
  Platform, ScrollView,
  useColorScheme,
  useWindowDimensions, View
} from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { RootSiblingParent } from 'react-native-root-siblings';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import {
  deviceDarkModeSet,
  deviceExpoPushTokenSet,
  mobileSiteSet,
  netInfoChecked,
  selectDarkMode,
  selectUserKey,
  userKeyUpdated
} from './src/app/appDataSlice';
import { store } from './src/app/store';
import { useLazyGetCurrentVersionQuery } from './src/features/api/apiSlice';
import {
  availableSectionsListUpdated,
  clearUserData,
  currentSectionUpdated,
  isHMCUpdated,
  setUserData
} from './src/features/classPages/classPagesSlice';

import { useAppDispatch, useAppSelector } from './src/app/hooks';
import WebSplashScreen from './src/components/WebSplashScreen';
import AdminPanel from './src/features/adminPanel';
import {
  useLazyGetAllClassesQuery,
  useLazyGetAllSectionsQuery
} from './src/features/api/classPagesApi';
import {
  useAddExpoPushTokenMutation,
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery,
  useTrackLoginMutation,
  usersApiSlice
} from './src/features/api/usersApi';
import Auth from './src/features/authentication';
import Calendar from './src/features/calendar';
import ChatsRoomNavigation from './src/features/chats/ChatRooms';
import ClassPages from './src/features/classPages';
import CombinedClassPages from './src/features/combinedCalender';
import { versionCompare } from './src/functions';
import Theme from './src/theme/custom-theme';
import FeatherIconsPack from './src/theme/feather-icons';
import FontAwesomeIconsPack from './src/theme/font-awesome-icons';
import ModalContainer from './src/components/ModalContainer';
import {base_URL} from "./src/features/notifications/constants";

const persistor = persistStore(store);
const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();
ExpoSplashScreen.preventAutoHideAsync();

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false
  })
});

const registerForPushNotificationsAsync = async () => {
  let token: string;
  if (isDevice && Platform.OS !== 'web') {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      alert('Failed to get push token for push notification!');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C'
    });
  }

  return token;
};

export default function Entry() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RootSiblingParent>
          <GestureHandlerRootView style={{ flex: 1 }}>
            <BottomSheetModalProvider>
              <App />
            </BottomSheetModalProvider>
          </GestureHandlerRootView>
        </RootSiblingParent>
      </PersistGate>
    </Provider>
  );
}

function App() {
  const userKey = useAppSelector(selectUserKey);
  const darkMode = useAppSelector(selectDarkMode);
  const isHMC = useAppSelector((state) => state.appData.classPages.isHMC);
  const useDeviceColorScheme = useAppSelector(
    (state) => state.appData.secure.useDeviceColorScheme
  );
  const localCurrentSection = useAppSelector(
    (state) => state.appData.classPages.currentSection
  );
  const deviceExpoPushToken = useAppSelector(
    (state) => state.appData.secure.deviceExpoPushToken
  );
  const lastNetInfo = useAppSelector(
    (state) => state.appData.secure.connectedToInternet
  );

  const [isReady, setIsReady] = useState(false);
  const [notification, setNotification] =
    useState<Notifications.Notification>(null);

  const [trackLogin] = useTrackLoginMutation();
  const [getAllSections] = useLazyGetAllSectionsQuery();
  const [getAllClasses] = useLazyGetAllClassesQuery();
  const [getCurrentVersion] = useLazyGetCurrentVersionQuery();
  const [getUserInfo] = useLazyGetUserInfoQuery();
  const userDataFromApi =
    usersApiSlice.endpoints.getUserInfo.useQueryState(userKey);
  const userData = useAppSelector((state) => state.appData.classPages.userData);

  const [addExpoPushToken] = useAddExpoPushTokenMutation();
  const windowWidth = useWindowDimensions().width;

  const notificationListener = useRef<Subscription>();
  const responseListener = useRef();
  const netInfoHook = NetInfo.useNetInfo();
  const colorScheme = useColorScheme();
  const dispatch = useAppDispatch();

  const linking = {
    prefixes: [Linking.createURL('/'), 'https://joinathena.net']
  };

  useEffect(() => {
    const prepare = async () => {
      dispatch(
        usersApiSlice.util.updateQueryData('getUserInfo', userKey, (draft) => {
          draft = userData;
        })
      );
      if (userData && !userKey) {
        dispatch(clearUserData());
      }

      try {
        // Check for internet connection
        const netInfo = await NetInfo.fetch();

        // netInfo.isConnected = false;

        // user is logged in and online
        if (userKey && netInfo.isConnected) {
          await handleOnline();
        }

        notificationListener.current =
          Notifications.addNotificationReceivedListener((notification) => {
            setNotification(notification);
          });

        checkForUpdates();
      } catch (e) {
        dispatch(userKeyUpdated('')); // Clear userKey if login fails
        console.warn('Login Fail', e);
      } finally {
        setIsReady(true);
        await ExpoSplashScreen.hideAsync();
      }
    };

    prepare();

    return () => {
      if (notificationListener.current) {
        Notifications.removeNotificationSubscription(
          notificationListener.current
        );
      }

      if (responseListener.current) {
        Notifications.removeNotificationSubscription(responseListener.current);
      }
    };
  }, [userKey]);

  useEffect(() => {
    if (useDeviceColorScheme) {
      dispatch(deviceDarkModeSet(colorScheme === 'dark'));
    }
  }, [colorScheme]);

  useEffect(() => {
    dispatch(mobileSiteSet(windowWidth < 768));
  }, [windowWidth]);

  useEffect(() => {
    if (netInfoHook.isConnected && !lastNetInfo) {
      handleOnline();
    }
    dispatch(netInfoChecked(netInfoHook.isConnected));
  }, [netInfoHook.isConnected]);

  // For offline mode, save the data from the api to the redux store and rehydrate the store on app start
  useEffect(() => {
    if (
      userDataFromApi.currentData &&
      userDataFromApi.currentData.starredClasses
    ) {
    }
    dispatch(setUserData(userDataFromApi.currentData));
  }, [userDataFromApi.currentData]);

  const handleOnline = async () => {
    try {
      var fetchedCurrentSection = await getAllSections().unwrap();
    } catch (e) {
      return;
    }

    // Check for expo push token
    // "undefined" means the user has not been asked for notification permission yet
    if (deviceExpoPushToken === undefined) {
      var expoPushToken = await registerForPushNotificationsAsync();
      if (expoPushToken) {
        // null means the user rejected the notification permission request
        dispatch(deviceExpoPushTokenSet(null));
      }
    }

    // Notfication token setup
    try {
      const fetchedUserData = await getUserInfo(userKey).unwrap();
      await trackLogin(userKey);
      if (
        expoPushToken &&
        (!fetchedUserData.expoPushToken ||
          !fetchedUserData.expoPushToken.includes(expoPushToken))
      ) {
        await addExpoPushToken({
          userKey,
          expoPushToken,
          userName: fetchedUserData.userName
        }).unwrap();
      }

      dispatch(
        availableSectionsListUpdated(fetchedCurrentSection.availableTerms)
      );

      if (isHMC === undefined) {
        dispatch(isHMCUpdated(fetchedUserData.email.includes('hmc.edu')));
      }

      // Check for current section and update class list if outdated
      if (
        Platform.OS === 'web' ||
        fetchedCurrentSection.currentTerm !== localCurrentSection
      ) {
        dispatch(currentSectionUpdated(fetchedCurrentSection.currentTerm));
        try {
          getAllClasses({
            userKey,
            collegeName: fetchedUserData.collegeName,
            courseTerm: fetchedCurrentSection.currentTerm
          }).unwrap();
        } catch (e) {
          console.warn('Section is outdated: could not fetch classes', e);
        }
      }
    } catch (e) {
      console.warn('Failed to set up expo push token', e);
    }
  };

  const checkForUpdates = async () => {
    try {
      // Check for current version
      const currentVersion =
        Constants?.manifest2?.extra?.expoClient?.version ??
        Constants?.manifest?.version;

      const res = await getCurrentVersion().unwrap();
      const currentFrontEndVersion =
        res?.currentFrontEndVersion.currentFrontEndVersion;

      if (
        Platform.OS !== 'web' &&
        currentVersion &&
        versionCompare(currentFrontEndVersion, currentVersion) > 0
      ) {
        Alert.alert('There is a new version available!', '', [
          {
            text:
              Platform.OS === 'android'
                ? 'Update on the Play Store'
                : 'Update on the App Store',
            onPress: () =>
              Linking.openURL(
                Platform.OS === 'android'
                  ? 'market://details?id=com.theathenanetwork.app'
                  : 'https://apps.apple.com/us/app/athena-network/id1628317342'
              )
          },
          {
            text: 'Cancel',
            style: 'cancel'
          }
        ]);
      }
    } catch (e) {
      console.warn('Update check failed', e);
    }
  };

  if (!isReady) {
    if (Platform.OS === 'web') {
      return <WebSplashScreen />;
    }
    return null;
  }

  return (
    <NavigationContainer
      documentTitle={{
        formatter: (options, route) =>
          `${options?.title ? options?.title + ' -' : ''} Athena Network`
      }}
      theme={darkMode ? DarkTheme : DefaultTheme}
      // linking={linking}
      fallback={<WebSplashScreen />}
    >
      <ApplicationProvider
        {...eva}
        theme={{ ...(darkMode ? eva.dark : eva.light), ...Theme }}
      >
        <StatusBar style={darkMode ? 'light' : 'dark'} />
        <IconRegistry icons={[FontAwesomeIconsPack, FeatherIconsPack]} />

        <Layout style={{ flex: 1, overflow: 'hidden' }}>
          <Stack.Navigator screenOptions={{ headerShown: false }}>
            {userData ? (
              <Stack.Screen name="Tabs" component={TabsPage} />
            ) : (
              <Stack.Screen name="Auth" component={Auth} />
            )}
          </Stack.Navigator>
        </Layout>
      </ApplicationProvider>
    </NavigationContainer>
  );
}

function TabsPage() {
  const theme = useTheme();
  const connectedToInternet = useAppSelector(
    (state) => state.appData.secure.connectedToInternet
  );
  const isMobile = useAppSelector((state) => state.appData.secure.isMobile);
  const userKey = useAppSelector(selectUserKey);
  const { data: userData } = useGetUserInfoQuery(userKey, {
    skip: !userKey
  });

  const [surveyData, setSurveyData ] = useState({questionList: [], title: ''})
  const [showTutorial, setShowTutorial] = useState(false);
  const [surveyAnswers, setSurveyAnswers] = useState({})
  const [showSurvey, setShowSurvey] = useState(false)

  useEffect(() => {
    if (userData.new === true) {
      setShowTutorial(true)
    } else {
      getSurveys()
    }
  }, []);

  function submitSurvey() {
    const responseDataEntries = Object.entries(surveyAnswers);
    if (responseDataEntries.length > 0) {
      for (const response of responseDataEntries) {
        fetch(base_URL + '/users/submitSurvey', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            userKey: userKey
          },
          body: JSON.stringify({
            questionIndex: Number(response[0]),
            response: response[1],
            surveyId: surveyData['_id']
          })
        });
      }
    }
  }

  function buildSurveyChildren() {
    const final = [];
    for (const questionIndex in surveyData.questionList) {
      final.push(
        <Card
          style={{
            marginBottom: '5%',
            borderRadius: 200,
            backgroundColor: 'white'
          }}
        >
          <Layout
            style={{
              backgroundColor: 'transparent',
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center'
            }}
          >
            <Text
              style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 18 }}
            >
              {surveyData.questionList[Number(questionIndex)]}
            </Text>
            {createButtons(Number(questionIndex))}
          </Layout>
        </Card>
      );
    }
    return (
      <ScrollView
        style={{
          backgroundColor: 'rgba(216, 27, 96, 0.85)',
          padding: '5%',
          width: '90%',
          marginBottom: '5%',
          borderRadius: 10
        }}
      >
        {final}
      </ScrollView>
    );
  }

  function createButtons(questionIndex: number) {
    let final = [];
    for (let i = 0; i < 6; i++) {
      final.push(
        <Layout
          style={{
            backgroundColor: 'transparent',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Radio
            checked={
              surveyAnswers[questionIndex.toString()] !== undefined &&
              surveyAnswers[questionIndex.toString()] === i
            }
            disabled={false}
            onChange={(newOption) => {
              let newData = { ...surveyAnswers };
              // @ts-ignore
              newData[questionIndex.toString()] = i;
              setSurveyAnswers(newData);
            }}
          />
          <Text style={{ textAlign: 'center', fontWeight: 'bold' }}>{i}</Text>
        </Layout>
      );
    }
    return (
      <Layout
        style={{
          backgroundColor: 'transparent',
          marginTop: '5%',
          width: '100%',
          justifyContent: 'space-evenly',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        {final}
      </Layout>
    );
  }

  function getSurveys() {
    fetch(`${base_URL}/users/surveys`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        userKey: userKey
      }
    }).then(async (res) =>{
      if (res.ok) {
        const surveyData = await res.json()
        setSurveyData(surveyData)
        setShowSurvey(true)
      }
    });
  }

  function handleSurveyModalClose(status = false) {
    submitSurvey();
    setShowSurvey(false);
  }

  function generateTutorial() {
    return (
      <ScrollView style={{width: '100%'}} bounces={true}>
        <Image style={{marginHorizontal: 'auto', height: 500, width: 345}} source={require('./assets/tutorial/1.jpg')}/>
        <Image style={{marginHorizontal: 'auto', marginVertical: '5%', height: 500, width: 345}} source={require('./assets/tutorial/2.jpg')}/>
        <Image style={{marginHorizontal: 'auto', height: 500, width: 345}} source={require('./assets/tutorial/3.jpg')}/>
      </ScrollView>
    )
  }

  return (
    <Layout style={{ flex: 1 }}>
        <ModalContainer backDropVisible={showTutorial} setBackDropVisible={setShowTutorial} title={'Tutorial'} children={generateTutorial()}/>
        <ModalContainer
        backDropVisible={showSurvey}
        setBackDropVisible={handleSurveyModalClose}
        title={surveyData.title}
        children={buildSurveyChildren()}
      />
      {!connectedToInternet && isMobile && (
        <Layout
          style={{
            width: '100%',
            height: 30,
            backgroundColor: 'red',
            zIndex: 1000,
            position: 'absolute',
            bottom: 78,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Text style={{ color: '#fff', fontStyle: 'italic' }}>
            You are offline (read only)
          </Text>
        </Layout>
      )}
      <Tab.Navigator
        initialRouteName={'ClassPages'}
        screenOptions={({ route }) => ({
          tabBarHideOnKeyboard: Platform.OS === 'android',
          tabBarIcon: ({ focused }) => {
            // Create type for icon name
            type IoniconsIconName = React.ComponentProps<
              typeof Ionicons
            >['name'];
            let iconName: IoniconsIconName = 'ios-home';

            if (route.name === 'ClassPages' || route.name === 'ClassPages2') {
              iconName = focused ? 'book' : 'book-outline';
            } else if (route.name === 'Discover') {
              iconName = focused ? 'compass' : 'compass-outline';
            } else if (route.name === 'Calendar') {
              iconName = focused ? 'calendar' : 'calendar-outline';
            } else if (route.name === 'ChatsRoomNavigation') {
              iconName = focused ? 'chatbubbles' : 'chatbubbles-outline';
            }

            return (
              <Ionicons
                name={iconName}
                size={25}
                color={
                  focused
                    ? theme['color-primary-500']
                    : theme['color-basic-500']
                }
              />
            );
          },
          tabBarStyle: { paddingTop: 5 },
          headerShown: false
        })}
      >
        {isMobile ? (
          <Stack.Group>
            <Tab.Screen
              name="ClassPages"
              component={ClassPages}
              options={{
                tabBarLabel: () => (
                  <Text
                    style={{
                      margin: isMobile ? 0 : 15,
                      fontSize: 12
                    }}
                  >
                    {Platform.OS !== 'web' && 'Class Pages'}
                  </Text>
                )
              }}
            />
            <Tab.Screen
              name="Calendar"
              component={Calendar}
              options={{
                tabBarLabel: () => (
                  <Text
                    style={{
                      margin: isMobile ? undefined : 15,
                      fontSize: 12
                    }}
                  >
                    {Platform.OS !== 'web' && 'Calendar'}
                  </Text>
                )
              }}
            />
          </Stack.Group>
        ) : (
          <Tab.Screen
            name="ClassPages2"
            component={CombinedClassPages}
            options={{
              tabBarLabel: () => (
                <Text
                  style={{
                    margin: isMobile ? undefined : 15,
                    fontSize: 12
                  }}
                >
                  Class Pages
                </Text>
              ),
              title: 'Class Pages'
            }}
          />
        )}
        <Tab.Screen
          name="ChatsRoomNavigation"
          component={ChatsRoomNavigation}
          options={{
            tabBarLabel: () => (
              <Text
                style={{
                  margin: isMobile ? undefined : 15,
                  fontSize: 12
                }}
              >
                {!(isMobile && Platform.OS === 'web') && 'Chats'}
              </Text>
            )
          }}
        />
        {userData?.permissionLevel === 'admin' && (
          <Tab.Screen
            name="Admin"
            component={AdminPanel}
            options={{
              unmountOnBlur: false,
              tabBarLabel: () => (
                <Text
                  style={{
                    margin: isMobile ? undefined : 15,
                    fontSize: 12
                  }}
                >
                  Admin
                </Text>
              )
            }}
          />
        )}
      </Tab.Navigator>
    </Layout>
  );
}
