import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Layout, Text, useTheme } from '@ui-kitten/components';
import React, { useState } from 'react';
import {
  Dimensions,
  Image,
  Platform,
  TouchableOpacity,
  View
} from 'react-native';
import Toggle from 'react-native-toggle-element';

import {
  selectDarkMode,
  selectIsGuest,
  selectUserKey
} from '../../app/appDataSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Header from '../../components/Header';
import ModalContainer from '../../components/ModalContainer';
import { exportToICal } from '../../functions';
import { useGetUserInfoQuery } from '../api/usersApi';
import SelectTerm from '../classPages/SelectTerm';
import { base_URL } from '../notifications/constants';
import CalendarPage from './CalendarPage';
import CreateCustomEvent from './CreateCustomEvent';
import { isThreeDaySet } from './calendarSlice';
import renderModal from './exportModal';

const Stack = createNativeStackNavigator();

export default function Calendar() {
  const navigation = useNavigation();
  const isThreeDay = useAppSelector(
    (state) => state.appData.calendar.isThreeDay
  );
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isGuest = useAppSelector(selectIsGuest);
  const darkMode = useAppSelector(selectDarkMode);
  const userKey = useAppSelector(selectUserKey);
  const currentSection = useAppSelector(
    (state) => state.appData.classPages.currentSection
  );
  const classes = useAppSelector((state) => state.appData.classPages.classes);
  const { data: userData } = useGetUserInfoQuery(userKey);
  const [showModal, setShowModal] = useState(false);
  const [showGuestModal, setShowGuestModal] = useState(false);
  const [showWebModal, setShowWebModal] = useState(false);

  const accessoryLeft = (
    <Image
      style={{
        height: 28,
        width: 28,
        alignItems: 'center'
      }}
      source={require('../../../assets/logos/app-logo-transparent.png')}
    />
  );

  const title = (
    <View
      style={{
        alignItems: 'center',
        paddingRight: 20
      }}
    >
      {Dimensions.get('window').width < 525 && (
        <Toggle
          leftComponent={
            <FontAwesome5 name="compress-arrows-alt" size={14} color="#fff" />
          }
          rightComponent={
            <FontAwesome5 name="expand-arrows-alt" size={15} color="#fff" />
          }
          trackBar={{
            activeBackgroundColor: theme['color-primary-500'],
            inActiveBackgroundColor: theme['color-primary-500'],
            width: 60,
            height: 23
          }}
          thumbButton={{
            width: 30,
            height: 30,
            activeBackgroundColor: theme['color-primary-800'],
            inActiveBackgroundColor: theme['color-primary-300'],
            activeColor: theme['color-primary-100'],
            inActiveColor: '#fff',
            borderWidth: 5
          }}
          value={isThreeDay}
          onPress={(bool) => dispatch(isThreeDaySet(bool))}
          animationDuration={10}
        />
      )}
    </View>
  );
  const accessoryRight = (
    <View
      style={{
        paddingHorizontal: 0,
        alignItems: 'center',
        flexDirection: 'row'
      }}
    >
      <TouchableOpacity
        onPress={() => {
          if (isGuest) {
            setShowGuestModal(true);
          } else {
            // @ts-ignore
            navigation.navigate('CreateCustomEvent');
          }
        }}
        style={{
          marginRight: 10,
          alignItems: 'center'
        }}
      >
        <View
          style={{
            height: 45,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <FontAwesome5
            name="plus"
            size={20}
            color={darkMode ? 'white' : 'black'}
          />
          <Text style={{ paddingTop: 5, fontSize: 10 }}>Add Event</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          if (Platform.OS === 'web') {
            const times = Object.entries(
              userData.addedClasses.classes[currentSection]
            ).flatMap(([masterCourseCode, courseCodes]) => {
              return courseCodes.flatMap((courseCode) => {
                return classes[masterCourseCode].times.filter(
                  (time) => time.courseCode === courseCode
                );
              });
            });
            const calendar = exportToICal(times);
          } else if (isGuest) {
            setShowGuestModal(true);
          } else {
            setShowModal(true);
            setShowWebModal(true);
          }
        }}
        style={{
          alignItems: 'center'
        }}
      >
        <View
          style={{
            height: 45,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <FontAwesome5
            name="file-export"
            size={20}
            color={darkMode ? 'white' : 'black'}
          />
          <Text style={{ paddingTop: 5, fontSize: 10 }}>Share Schedule</Text>
        </View>
      </TouchableOpacity>
      {showGuestModal && (
        <ModalContainer
          title="Wait!"
          children={
            <Text style={{ paddingHorizontal: 30 }}>
              This feature is not available to Guest Users. Please create a free
              account with us to take advantage this feature.
            </Text>
          }
          backDropVisible={showGuestModal}
          setBackDropVisible={setShowGuestModal}
        />
      )}
      {Platform.OS === 'web' && showWebModal && (
        <ModalContainer
          title="Wait!"
          children={
            <Text style={{ paddingHorizontal: 30 }}>
              Sharing schedules is not supported on the web. Download the app on
              the App Store or Play Store to share schedules.
            </Text>
          }
          backDropVisible={showWebModal}
          setBackDropVisible={setShowWebModal}
        />
      )}
      {Platform.OS !== 'web' && renderModal(setShowModal, showModal)}
    </View>
  );
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="CalendarPage"
        component={CalendarPage}
        options={{
          header: () => {
            return (
              <Layout style={{ paddingBottom: 10 }}>
                <Header
                  center={title}
                  accessoryRight={accessoryRight}
                  accessoryLeft={accessoryLeft}
                  title="Calendar"
                />
              </Layout>
            );
          }
        }}
      />
      <Stack.Screen
        name="CreateCustomEvent"
        component={CreateCustomEvent}
        options={{ headerTitle: 'Add Event' }}
      />
      <Stack.Screen
        name="SelectTerm"
        component={SelectTerm}
        options={{
          title: 'Select Term',
          headerShown: false,
          animation: 'slide_from_bottom',
          gestureDirection: 'vertical'
        }}
      />
    </Stack.Navigator>
  );
}
