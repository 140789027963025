import { Button, Icon, Layout, Text, useTheme } from '@ui-kitten/components';
import Modal from 'react-native-modal';

import { selectDarkMode } from '../app/appDataSlice';
import { useAppSelector } from '../app/hooks';

type ModalContainerProps = {
  children: React.ReactNode;
  backDropVisible: boolean;
  setBackDropVisible: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  coverScreen?: boolean;
  clickBackdropToClose?: boolean;
  modalStyle?: any;
  modalProps?: any;
};

export default function ModalContainer({
  children,
  backDropVisible,
  setBackDropVisible,
  title,
  clickBackdropToClose = true,
  coverScreen = true,
  modalStyle,
  modalProps
}: ModalContainerProps) {
  const theme = useTheme();
  const darkMode = useAppSelector(selectDarkMode);

  return (
    <Modal
      coverScreen={coverScreen}
      backdropOpacity={0}
      onBackdropPress={
        clickBackdropToClose ? () => setBackDropVisible(false) : undefined
      }
      isVisible={backDropVisible}
      style={[
        {
          backgroundColor: darkMode
            ? theme['color-basic-900']
            : theme['color-basic-50'],
          borderRadius: 20,
          flex: 1,
          marginTop: 300,
          marginBottom: 50,
          width: '90%',
          maxWidth: 500,
          alignSelf: 'center',
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 12
          },
          shadowOpacity: 0.58,
          shadowRadius: 16.0,

          elevation: 11
        },
        modalStyle
      ]}
      {...modalProps}
    >
      <Layout
        style={{
          flex: 1,
          alignItems: 'center',
          borderRadius: 20,
          backgroundColor: darkMode
            ? theme['color-basic-900']
            : theme['color-basic-100']
        }}
      >
        <Button
          appearance="ghost"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            flex: 1,
            borderRadius: 20
          }}
          accessoryRight={(props) => (
            <Icon pack="fontAwesome" name="angle-down" {...props} />
          )}
          onPress={() => setBackDropVisible(false)}
        />
        <Text category="h5" style={{ marginVertical: 15 }}>
          {title}
        </Text>
        {children}
      </Layout>
    </Modal>
  );
}
