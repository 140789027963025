import {
  Button,
  CheckBox,
  Divider,
  Layout,
  Text,
  Toggle,
  useTheme
} from '@ui-kitten/components';
import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  classPagesSlice,
  filtersReset,
  filtersSaved,
  isHMCUpdated
} from './classPagesSlice';

export default function FilterClasses({ darkMode, setFiltersMenuVisible }) {
  const theme = useTheme();
  const initialFilters = classPagesSlice.getInitialState().filters;
  const filters = useAppSelector((state) => state.appData.classPages.filters);
  const isHMC = useAppSelector((state) => state.appData.classPages.isHMC);

  const [colleges, setColleges] = useState(filters.colleges);
  const [filterConflicts, setFilterConflicts] = useState(
    filters.filterConflicts
  );

  const dispatch = useAppDispatch();

  const onChangeIsHMC = (change: boolean) => {
    dispatch(isHMCUpdated(change));
  };

  const onChangeFilterCollege = (college: string, change: boolean) => {
    setColleges({ ...colleges, [college]: change });
  };

  const resetFilters = () => {
    setColleges(initialFilters.colleges);
    setFilterConflicts(initialFilters.filterConflicts);
    dispatch(filtersReset());
  };

  const saveFilters = () => {
    dispatch(filtersSaved({ colleges, filterConflicts }));
    setFiltersMenuVisible(false);
  };

  return (
    <Layout
      style={{
        backgroundColor: darkMode
          ? theme['color-basic-900']
          : theme['color-basic-100'],
        alignItems: 'center',
        padding: 10
      }}
    >
      <Layout
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 10,
          backgroundColor: darkMode
            ? theme['color-basic-900']
            : theme['color-basic-100']
        }}
      >
        <Text>4C Credits </Text>
        <Toggle
          checked={isHMC}
          onChange={(change) => {
            onChangeIsHMC(change);
          }}
        />
        <Text> HMC Credits</Text>
      </Layout>
      <Divider />

      {/* <CheckBox
        style={{ marginTop: 20 }}
        checked={filterConflicts}
        onChange={(bool) => setFilterConflicts(bool)}
      >
        Filter Conflicting Classes
      </CheckBox> */}

      <Text style={{ marginTop: 20, marginBottom: 5 }}>Colleges</Text>

      <Layout
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {Object.keys(colleges).map((college) => (
          <CheckBox
            style={{
              backgroundColor: darkMode
                ? theme['color-basic-900']
                : theme['color-basic-100']
            }}
            key={college}
            checked={colleges[college]}
            onChange={(change) => {
              onChangeFilterCollege(college, change);
            }}
          >
            {college}
          </CheckBox>
        ))}
      </Layout>
      <Button style={{ marginTop: 20 }} onPress={saveFilters}>
        Update Results
      </Button>
      <Button
        size="small"
        style={{ marginTop: 20 }}
        status="info"
        onPress={resetFilters}
      >
        Reset Filters
      </Button>
    </Layout>
  );
}
