import { Entypo, FontAwesome5 } from '@expo/vector-icons';
import BottomSheet from '@gorhom/bottom-sheet/lib/typescript/components/bottomSheet/BottomSheet';
import {
  Button,
  Layout,
  List,
  Spinner,
  Text,
  useTheme
} from '@ui-kitten/components';
import { LinearGradient } from 'expo-linear-gradient';
import { useEffect, useRef, useState } from 'react';
import {
  Keyboard,
  LayoutAnimation,
  PanResponder,
  Platform,
  RefreshControl,
  TouchableOpacity,
  useWindowDimensions
} from 'react-native';

import Animated from 'react-native-reanimated';
import { selectDarkMode, selectUserKey } from '../../app/appDataSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Message from '../../components/Message';
import ModalContainer from '../../components/ModalContainer';
import SearchBar from '../../components/SearchBar';
import {
  RENDERED_RESULTS_LIMIT_DESKTOP,
  RENDERED_RESULTS_LIMIT_MOBILE
} from '../../constants/classPages';
import { showToast } from '../../functions';
import {
  useAddClassMutation,
  useAddMultipleClassesMutation,
  useLazyGetAllClassesQuery,
  useRemoveClassMutation,
  useSwapCoursePriorityMutation
} from '../api/classPagesApi';
import { useGetUserInfoQuery, useLazyGetUserInfoQuery } from '../api/usersApi';
import AddedClasses from './AddedClasses';
import Item from './ClassListItem';
import FilterClasses from './FilterClasses';
import SingleClassPage from './SingleClassPage';
import {
  classSelected,
  endOfListReached,
  filterListBySearchTerm,
  resetClasses,
  searchTermUpdated,
  selectClassesList,
  suggestedClassesDimissed
} from './classPagesSlice';

export function CurrentTermLabel({
  navigation,
  currentSection,
  style = {},
  textStyle = {},
  disabled = false
}) {
  return (
    <TouchableOpacity
      disabled={disabled}
      style={style}
      onPress={() => navigation.navigate('SelectTerm')}
    >
      <Text category="label" style={textStyle}>
        {`Term: ${currentSection}`}
      </Text>
    </TouchableOpacity>
  );
}

const DIVIDER_HEIGHT = 15;
const MIN_WINDOW_HEIGHT = 45;

export default function ClassesList({ navigation }) {
  const [menuOpen, setMenuOpen] = useState(null);
  const [addedClassesVisible, setAddedClassesVisible] = useState(false);
  const [filtersMenuVisible, setFiltersMenuVisible] = useState(false);
  const [listRef, setListRef] = useState(null);
  const [renderedResultsLimit, setRenderedResultsLimit] = useState(
    RENDERED_RESULTS_LIMIT_MOBILE
  );
  const [isDividerClicked, setIsDividerClicked] = useState(false);

  const [addClass] = useAddClassMutation();
  const [addMultipleClasses] = useAddMultipleClassesMutation();
  const [removeClass] = useRemoveClassMutation();
  const [swapAddedClasses] = useSwapCoursePriorityMutation();
  const [fetchUserInfo] = useLazyGetUserInfoQuery();
  const [getClasses, { isLoading: classListLoading, isError }] =
    useLazyGetAllClassesQuery();
  const currentSection = useAppSelector(
    (state) => state.appData.classPages.currentSection
  );

  const filters = useAppSelector((state) => state.appData.classPages.filters);
  const isMobile = useAppSelector((state) => state.appData.secure.isMobile);
  const classes = useAppSelector(selectClassesList);
  const userKey = useAppSelector(selectUserKey);
  const page = useAppSelector((state) => state.appData.classPages.page);
  const darkMode = useAppSelector(selectDarkMode);
  const searchTerm = useAppSelector(
    (state) => state.appData.classPages.searchTerm
  );
  const renderedClasses = useAppSelector(
    (state) => state.appData.classPages.renderedClasses
  );
  const suggestedClasses = useAppSelector(
    (state) => state.appData.classPages.suggestedClasses
  );

  const { data: userData, isSuccess: userInfoSuccess } = useGetUserInfoQuery(
    userKey,
    { skip: !userKey }
  );
  const collegeName = userData?.collegeName || 'claremontColleges';

  const userName = userData?.userName;
  const orderedAddedClasses = userData?.addedClasses?.orderedClasses;
  const addedClasses = userData?.addedClasses?.classes;
  const length =
    orderedAddedClasses && orderedAddedClasses[currentSection]
      ? orderedAddedClasses[currentSection].length
      : 0;

  const bottomSheetRef = useRef<BottomSheet>(null);
  const searchBarWaitId = useRef(null);
  const _panResponder = useRef(getPanResponder());

  // Other Hooks
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const dimensions = useWindowDimensions();
  const [bottomHeight, setBottomHeight] = useState(dimensions.height / 4); // added classes height (web only)

  const topHeight = dimensions.height - bottomHeight - DIVIDER_HEIGHT;

  useEffect(() => {
    fetchClasses();
  }, []);

  useEffect(() => {
    setRenderedResultsLimit(
      isMobile ? RENDERED_RESULTS_LIMIT_MOBILE : RENDERED_RESULTS_LIMIT_DESKTOP
    );
  }, [isMobile]);

  useEffect(() => {
    _panResponder.current = getPanResponder();
  }, [dimensions.height]);

  const fetchClasses = async () => {
    if (Object.keys(classes).length === 0) {
      try {
        await getClasses({
          userKey,
          collegeName,
          courseTerm: currentSection
        }).unwrap();
      } catch (e) {
        console.warn('Error getting classes: ', e);
      }
    } else {
      dispatch(resetClasses());
    }
  };

  const isClassAdded = (courseCode: string, masterCourseCode: string) => {
    return (
      currentSection in addedClasses &&
      masterCourseCode in addedClasses[currentSection] &&
      addedClasses[currentSection][masterCourseCode].includes(courseCode)
    );
  };

  function getPanResponder() {
    return PanResponder.create({
      // @ts-ignore
      onMoveShouldSetResponderCapture: () => true,
      onMoveShouldSetPanResponderCapture: () => true,

      onPanResponderGrant: (e, gestureState) => {
        setIsDividerClicked(true);
      },

      onPanResponderMove: (e, gestureState) => {
        const newBottom = dimensions.height - gestureState.moveY - 60;

        if (newBottom < MIN_WINDOW_HEIGHT) {
          setBottomHeight(MIN_WINDOW_HEIGHT);
        } else if (newBottom > dimensions.height - MIN_WINDOW_HEIGHT) {
          setBottomHeight(dimensions.height - MIN_WINDOW_HEIGHT);
        } else {
          setBottomHeight(newBottom);
        }
      },

      onPanResponderRelease: (e, gestureState) => {
        setIsDividerClicked(false);
      }
    });
  }

  const handleRefresh = () => {
    fetchUserInfo(userKey);
    fetchClasses();
  };

  const handleSearch = (searchPhrase: string) => {
    if (listRef) {
      listRef.scrollToOffset({ animated: true, offset: 0 });
    }

    dispatch(searchTermUpdated(searchPhrase));

    if (searchBarWaitId.current) {
      clearTimeout(searchBarWaitId.current);
    }

    // prevents lagginness when typing
    searchBarWaitId.current = setTimeout(() => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      dispatch(filterListBySearchTerm());
    }, 150);
  };

  const handleClassSelected = (courseCode: string) => {
    dispatch(classSelected(courseCode));
    if (Platform.OS === 'web') {
      navigation.navigate('SingleClassPage');
    } else {
      bottomSheetRef.current?.snapToIndex(0);
    }
  };

  const handleAddClass = (courseCode: string, masterCourseCode: string) => {
    try {
      addClass({
        userKey,
        courseCode,
        courseTerm: currentSection,
        userName,
        masterCourseCode
      }).unwrap();
    } catch (e) {
      console.warn('Add class error: ', e);
    }
  };

  const handleRemoveClass = (courseCode: string, masterCourseCode: string) => {
    try {
      removeClass({
        userKey,
        courseCode,
        courseTerm: currentSection,
        userName,
        masterCourseCode
      }).unwrap();
    } catch (e) {
      console.warn('Add class error: ', e);
    }
  };

  const handleMassImport = async () => {
    const courseCodes = [
      ...searchTerm.matchAll(/\b[A-Z]+\d+[A-Z]*\s?[A-Z]+-\d+\b/g)
    ];
    if (!courseCodes[0]) {
      showToast('No course codes found in search bar', 'maroon');
      return;
    }
    const courseCodePairs = courseCodes.reduce((accumulator, courseCode) => {
      const matches = courseCode[0].match(/(\D{3,4})(\d+[A-Z]?[A-Z]?)(.+)/);

      if (!matches || matches.length !== 4) {
        // Skip this course code
        return accumulator;
      }

      const collegeParts = matches[3].split('-'); // Ex: ["PO", "01"]
      const masterCourseCode = `${matches[1].trim()} ${matches[2].trim()} ${collegeParts[0].trim()}`;
      const newCourseCode = `${matches[1].trim()} ${matches[2].trim()} ${matches[3].trim()}`;
      if (isClassAdded(newCourseCode, masterCourseCode)) {
        return accumulator;
      }
      return [...accumulator, { masterCourseCode, courseCode: newCourseCode }];
    }, []);

    if (courseCodePairs.length === 0) {
      showToast('No new course codes found in search bar', 'maroon');
      return;
    }

    const message = `Added ${courseCodePairs.length} class(es) to ${currentSection}`;

    try {
      await addMultipleClasses({
        userKey,
        courseCodePairs,
        courseTerm: currentSection,
        userName
      }).unwrap();
      showToast(message, 'green');
      handleRefresh();
    } catch (e) {
      console.warn('Mass import error: ', e);
      showToast('Error adding classes', 'maroon');
    }
  };

  const handleSwapAddedClasses = async (from: number, to: number) => {
    if (from === to) {
      return;
    }
    try {
      await swapAddedClasses({
        firstPosition: from,
        secondPosition: to,
        userKey,
        termToUpdate: currentSection,
        userName: userData.userName
      }).unwrap();
    } catch (e) {
      console.warn('Error swapping classes: ', e);
    }
  };

  const renderItem = ({ item: [masterCourseCode, courseInfo] }) => {
    // check if course is in addedClasses set and set to true if it is
    const inItem = new Array(courseInfo.times.length);
    let inItemSize = 0;
    const schedule = courseInfo.times.map((time) => {
      if (isClassAdded(time.courseCode, masterCourseCode)) {
        inItem[inItemSize++] = time.courseCode;
        return { ...time, added: true };
      }
      return { ...time, added: false };
    });

    return (
      <Item
        courseName={courseInfo.courseName}
        masterCourseCode={masterCourseCode}
        schedule={schedule}
        menuOpen={menuOpen}
        setIsMenuOpen={setMenuOpen}
        darkMode={darkMode}
        handleClassSelected={handleClassSelected}
        handleAddClass={handleAddClass}
        handleRemoveClass={handleRemoveClass}
        inItem={inItem}
        isMobile={isMobile}
      />
    );
  };

  const accessoryLeft = (
    <Layout style={{ flexDirection: 'row', backgroundColor: 'transparent' }}>
      {isMobile && (
        <TouchableOpacity
          onPress={() => setAddedClassesVisible(!addedClassesVisible)}
          disabled={!classes || Object.keys(classes).length === 0}
          style={{ justifyContent: 'center', marginRight: 10 }}
        >
          <FontAwesome5
            name="list-ul"
            size={24}
            color={
              darkMode ? theme['color-basic-500'] : theme['color-basic-200']
            }
          />
          {length > 0 ? (
            <Layout
              style={{
                position: 'absolute',
                backgroundColor: darkMode ? 'red' : '#fff',
                borderRadius: 10,
                paddingHorizontal: 3,
                right: -7,
                top: -3
              }}
            >
              <Text>{length}</Text>
            </Layout>
          ) : null}
        </TouchableOpacity>
      )}
      <TouchableOpacity
        style={{ justifyContent: 'center', marginRight: 10 }}
        onPress={() => setFiltersMenuVisible(!filtersMenuVisible)}
      >
        <FontAwesome5
          name="filter"
          size={22}
          color={darkMode ? theme['color-basic-500'] : theme['color-basic-200']}
        />
      </TouchableOpacity>
    </Layout>
  );

  const renderFilteredCollegesList = () => {
    const filteredColleges = Object.keys(filters.colleges).filter(
      (c) => filters.colleges[c]
    );

    if (
      filteredColleges.length !== Object.keys(filters.colleges).length &&
      filteredColleges.length !== 0
    ) {
      return (
        <TouchableOpacity
          style={{ paddingTop: 5 }}
          onPress={() => setFiltersMenuVisible(!filtersMenuVisible)}
        >
          <Text category="label" style={{ fontStyle: 'italic', color: '#fff' }}>
            {`Colleges: ${filteredColleges.join(', ')}`}
          </Text>
        </TouchableOpacity>
      );
    }
    return <Layout style={{ backgroundColor: 'transparent', marginTop: 20 }} />;
  };

  const dismissSuggestions = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    dispatch(suggestedClassesDimissed());
  };

  const addedClassesWebView = () => {
    const title = (
      <Layout
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 10
        }}
      >
        <TouchableOpacity
          onPress={() => setAddedClassesVisible(!addedClassesVisible)}
        >
          <FontAwesome5 name="expand" size={20} />
        </TouchableOpacity>
        <Text category="h5">Added Classes</Text>
        {bottomHeight !== MIN_WINDOW_HEIGHT ? (
          <TouchableOpacity
            onPress={() => {
              setBottomHeight(MIN_WINDOW_HEIGHT);
            }}
          >
            <FontAwesome5 name="chevron-down" size={20} />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={() => {
              setBottomHeight(dimensions.height / 4);
            }}
          >
            <FontAwesome5 name="chevron-up" size={20} />
          </TouchableOpacity>
        )}
      </Layout>
    );
    return (
      <>
        {!isMobile && (
          <Layout
            style={[
              {
                height: DIVIDER_HEIGHT,
                alignItems: 'center',
                justifyContent: 'center',
                // @ts-ignore
                cursor: 'row-resize'
              },
              isDividerClicked
                ? { backgroundColor: theme['color-basic-300'] }
                : { backgroundColor: theme['color-basic-200'] }
            ]}
            {..._panResponder.current.panHandlers}
          >
            {/* <Entypo name="dots-three-horizontal" size={20} /> */}
          </Layout>
        )}

        {orderedAddedClasses &&
        currentSection &&
        classes &&
        Object.keys(classes).length !== 0 ? (
          <Animated.View style={{ height: bottomHeight }}>
            <AddedClasses
              setAddedClassesVisible={setAddedClassesVisible}
              addedClasses={orderedAddedClasses[currentSection] || []}
              handleRemoveClass={handleRemoveClass}
              darkMode={darkMode}
              handleSwapAddedClasses={handleSwapAddedClasses}
              bottomSheetRef={bottomSheetRef}
              starredClasses={userData.starredClasses}
              currentSection={currentSection}
              title={title}
            />
          </Animated.View>
        ) : (
          <Layout
            style={{
              flex: 1,
              alignItems: 'center',
              borderRadius: 20,
              paddingTop: 50
            }}
          >
            <Layout
              style={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 10
              }}
            >
              <TouchableOpacity
                onPress={() => setAddedClassesVisible(!addedClassesVisible)}
              >
                <FontAwesome5 name="expand" size={20} />
              </TouchableOpacity>
              <Text category="h5">Added Classes</Text>
              {bottomHeight !== MIN_WINDOW_HEIGHT ? (
                <TouchableOpacity
                  onPress={() => {
                    setBottomHeight(MIN_WINDOW_HEIGHT);
                  }}
                >
                  <FontAwesome5 name="chevron-down" size={20} />
                </TouchableOpacity>
              ) : (
                <Layout />
              )}
            </Layout>
          </Layout>
        )}
      </>
    );
  };

  // Decide what content to render
  let content: keyof JSX.IntrinsicElements | JSX.Element;

  if (classListLoading || !userInfoSuccess) {
    content = <Message title="Loading Classes" item={<Spinner />} />;
  } else if (isError) {
    content = (
      <Message
        title="Error Loading Classes"
        item={<Button onPress={fetchClasses}>Refresh</Button>}
      />
    );
  } else {
    content = (
      <List
        style={{ backgroundColor: 'transparent' }}
        contentContainerStyle={{ paddingBottom: 20 }}
        ListHeaderComponent={
          Object.entries(suggestedClasses).length > 0 &&
          searchTerm.length === 0 && (
            <List
              ListHeaderComponent={
                <Layout
                  style={{
                    marginLeft: 20,
                    marginRight: 25,
                    backgroundColor: 'transparent',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Text category="label" style={{ color: '#fff' }}>
                    Suggested Electives
                  </Text>
                  <TouchableOpacity onPress={dismissSuggestions}>
                    <FontAwesome5 name="times" size={24} color="#fff" />
                  </TouchableOpacity>
                </Layout>
              }
              style={{
                backgroundColor: 'rgba(0,0,0,.2)',
                borderRadius: 20,
                margin: 10,
                paddingBottom: 10,
                paddingTop: 3
              }}
              contentContainerStyle={{ paddingBottom: 100 }}
              data={Object.entries(suggestedClasses)}
              renderItem={renderItem}
              keyExtractor={([courseCode, _]) => courseCode}
              onScroll={Keyboard.dismiss}
              scrollEventThrottle={16}
            />
          )
        }
        data={Object.entries(renderedClasses).slice(
          0,
          renderedResultsLimit * page
        )}
        renderItem={renderItem}
        keyExtractor={([courseCode, _]) => courseCode}
        maxToRenderPerBatch={10}
        ListEmptyComponent={
          <Message
            title="No Classes Found"
            item={<Button onPress={fetchClasses}>Refresh</Button>}
          />
        }
        initialNumToRender={10}
        showsVerticalScrollIndicator={Platform.OS === 'web'}
        // ListHeaderComponent={() => <Layout style={{minHeight: 67, opacity: 0}}/>}
        onEndReachedThreshold={0.5}
        onEndReached={() => dispatch(endOfListReached())}
        refreshControl={
          <RefreshControl
            refreshing={classListLoading}
            onRefresh={handleRefresh}
            title="Pull to refresh"
            tintColor={theme['color-basic-100']}
            titleColor={theme['color-basic-100']}
          />
        }
        ref={(ref) => {
          setListRef(ref);
        }}
      />
    );
  }

  return (
    <Layout
      style={{
        flex: 1,
        // @ts-ignore
        cursor: isDividerClicked ? 'row-resize' : null
      }}
    >
      <Animated.View
        style={[
          { flex: 1 },
          !isMobile && { minHeight: MIN_WINDOW_HEIGHT, height: topHeight }
        ]}
      >
        <LinearGradient
          colors={
            darkMode
              ? [theme['color-basic-1100'], theme['color-basic-1100']]
              : [theme['color-primary-500'], theme['color-success-500']]
          }
          style={{ width: '100%', margin: 0, flex: 1 }}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0.4 }}
        >
          <LinearGradient
            colors={[
              // 'rgba(219,76,113,1)',
              // 'rgba(219,76,113,00)',

              'rgba(225,78,106,0)',
              'rgba(225,78,106,0)'
              // 'rgba(225,78,106,.6)',
              // 'rgba(225,78,106,0)',
            ]}
            locations={[0, 1]}
            style={{
              zIndex: 1,
              width: '100%',
              paddingHorizontal: 20,
              paddingTop: 14
            }}
          >
            {isMobile && (
              <CurrentTermLabel
                navigation={navigation}
                currentSection={currentSection}
                textStyle={{ color: '#fff', paddingBottom: 5, marginLeft: 80 }}
              />
            )}
            <SearchBar
              accessoryLeft={accessoryLeft}
              searchTerm={searchTerm}
              onChangeText={handleSearch}
              inputProps={{ autoCorrect: false }}
              placeholder="Search classes..."
              showCancelButton={isMobile}
              cancelButtonAction={handleRefresh}
              showXButton={Platform.OS !== 'web'}
            />
            {searchTerm?.length > 30 ? (
              <TouchableOpacity
                style={{ marginLeft: 60, marginTop: 5 }}
                onPress={handleMassImport}
              >
                <Text style={{ color: '#fff' }}>
                  <FontAwesome5 name="file-import" color="#fff" />
                  {` Import Full Schedule`}
                </Text>
              </TouchableOpacity>
            ) : null}
            {renderFilteredCollegesList()}
          </LinearGradient>

          {content}
        </LinearGradient>

        <ModalContainer
          setBackDropVisible={setAddedClassesVisible}
          coverScreen={Platform.OS === 'web'}
          backDropVisible={addedClassesVisible}
          title="Added Classes"
          modalStyle={{ maxWidth: 400 }}
        >
          {orderedAddedClasses && currentSection ? (
            <AddedClasses
              setAddedClassesVisible={setAddedClassesVisible}
              addedClasses={orderedAddedClasses[currentSection] || []}
              handleRemoveClass={handleRemoveClass}
              darkMode={darkMode}
              handleSwapAddedClasses={handleSwapAddedClasses}
              bottomSheetRef={bottomSheetRef}
              starredClasses={userData.starredClasses}
              currentSection={currentSection}
            />
          ) : (
            <Layout
              style={{
                flex: 1,
                alignItems: 'center',
                borderRadius: 20,
                paddingTop: 50
              }}
            >
              <Text>Loading</Text>
              <Spinner />
            </Layout>
          )}
        </ModalContainer>

        <ModalContainer
          setBackDropVisible={setFiltersMenuVisible}
          backDropVisible={filtersMenuVisible}
          title="Filters"
        >
          <FilterClasses
            setFiltersMenuVisible={setFiltersMenuVisible}
            darkMode={darkMode}
          />
        </ModalContainer>
      </Animated.View>

      {!isMobile && addedClassesWebView()}

      {Platform.OS !== 'web' && (
        <SingleClassPage bottomSheetRef={bottomSheetRef} />
      )}
    </Layout>
  );
}
