import {
  AddedClasses,
  UserData,
  UserDataApiRes
} from '../../app/types';
import { apiSlice } from './apiSlice';

export const usersApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      providesTags: ['User'],
      query: (userKey: string) => ({
        url: '/users/me',
        headers: {
          userKey
        }
      }),
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return { error: 'Unauthorized' };
        }
        return response;
      },
      transformResponse: ({
        classes,
        starredClasses,
        ...rest
      }: UserDataApiRes) => {
        const addedClasses = {
          length: 0,
          classes: {},
          orderedClasses: {},
          starredClasses: {}
        } as AddedClasses;

        // Add classes to orderedClasses
        classes.forEach(({ courseCode, courseTerm }) => {
          if (courseTerm in addedClasses.orderedClasses) {
            addedClasses.orderedClasses[courseTerm].push(courseCode);
          } else {
            addedClasses.orderedClasses[courseTerm] = [courseCode];
          }
        });
        // Add classes to unordered classes
        for (const { courseCode, courseTerm } of classes) {
          try {
            let schoolParts = courseCode.split('-');
            schoolParts = schoolParts[schoolParts.length - 2].split(' ');
            const school = schoolParts[schoolParts.length - 1];
            const masterCourseCode = `${courseCode
              .split(' ')
              .slice(0, 2)
              .join(' ')} ${school}`;
            if (courseTerm in addedClasses.classes) {
              if (masterCourseCode in addedClasses.classes[courseTerm]) {
                addedClasses.classes[courseTerm][masterCourseCode].push(
                  courseCode
                );
              } else {
                addedClasses.classes[courseTerm][masterCourseCode] = [
                  courseCode
                ];
              }
            } else {
              addedClasses.classes[courseTerm] = {
                [masterCourseCode]: [courseCode]
              };
            }

            addedClasses.length++;
          } catch (e) {
            console.warn('Error Adding Classes To State', e);
          }
        }
        // Add classes to starred classes
        for (const { courseCode, courseTerm } of starredClasses) {
          try {
            let schoolParts = courseCode.split('-');
            schoolParts = schoolParts[schoolParts.length - 2].split(' ');
            const school = schoolParts[schoolParts.length - 1];
            const masterCourseCode = `${courseCode
              .split(' ')
              .slice(0, 2)
              .join(' ')} ${school}`;
            if (courseTerm in addedClasses.starredClasses) {
              if (masterCourseCode in addedClasses.starredClasses[courseTerm]) {
                addedClasses.starredClasses[courseTerm][masterCourseCode].push(
                  courseCode
                );
              } else {
                addedClasses.starredClasses[courseTerm][masterCourseCode] = [
                  courseCode
                ];
              }
            } else {
              addedClasses.starredClasses[courseTerm] = {
                [masterCourseCode]: [courseCode]
              };
            }
          } catch (e) {
            console.warn('Error Adding Starred Classes To State', e);
          }
        }

        return { addedClasses, starredClasses, ...rest } as UserData;
      }
    }),
    login: builder.mutation({
      query: ({ userName, password, isGuest }) => ({
        url: `/users/login`,
        params: isGuest
          ? {
              userName,
              password,
              isGuest
            }
          : {
              userName,
              password
            }
      })
    }),
    trackLogin: builder.mutation({
      query: (userKey) => ({
        url: `/analytics/login/openedApp`,
        method: 'POST',
        headers: {
          userKey
        },
        responseHandler: (response) => response.text()
      })
    }),
    deleteAccount: builder.mutation({
      query: ({ userKey, userName }) => ({
        url: '/users',
        method: 'DELETE',
        headers: {
          userKey
        },
        params: {
          userName
        }
      })
    }),
    editUser: builder.mutation({
      invalidatesTags: ['User'],
      query: ({ userKey, userName, fields }) => ({
        url: `/users/${userName}`,
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          userKey
        },
        body: fields
      })
    }),
    getCollegeList: builder.query<any, void>({
      query: () => '/randomShit/getCollegeList'
    }),
    getChatRooms: builder.mutation({
      query: ({ userKey }) => ({
        url: `/chatRooms/listOfChatRooms`,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          userKey
        },
        responseHandler: (response) => response.json()
      })
    }),
    createChatRoom: builder.mutation({
      query: ({ userKey, recipients, nameOfChatRoom }) => ({
        url: `/chatRooms/newChatRoom`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          userKey
        },
        body: JSON.stringify({
          userList: recipients,
          name: nameOfChatRoom
        }),
        responseHandler: (response) => response.text()
      })
    }),
    addOrRemoveUserFromChatRoom: builder.mutation({
      query: ({ userKey, chatRoomId, userName }) => ({
        url: `/chatRooms/addOrRemoveUser`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          userKey
        },
        body: JSON.stringify({
          chatRoomId: chatRoomId,
          userName: userName
        })
      })
    }),
    signupUser: builder.mutation({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body,
        responseHandler: (response) => response.text()
      })
    }),
    verifySignup: builder.mutation({
      query: (code) => ({
        url: '/users/verification',
        method: 'POST',
        body: { code }
      })
    }),
    resetPassword: builder.mutation({
      query: (email) => ({
        url: '/users/forgotPassword',
        params: {
          email
        },
        responseHandler: (response) => response.text()
      })
    }),
    addExpoPushToken: builder.mutation({
      query: ({ userName, expoPushToken, userKey }) => ({
        url: `/users/addExpoPushKey/${userName}`,
        method: 'PATCH',
        headers: {
          userKey
        },
        body: {
          expoPushToken
        }
      })
    }),
    removeExpoPushToken: builder.mutation({
      query: ({ userName, expoPushToken, userKey }) => ({
        url: `/users/removeExpoPushKey/${userName}`,
        method: 'DELETE',
        headers: {
          userKey
        },
        body: {
          expoPushToken
        }
      })
    })
  })
});

export const {
  useAddOrRemoveUserFromChatRoomMutation,
  useCreateChatRoomMutation,
  useGetChatRoomsMutation,
  useTrackLoginMutation,
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery,
  useLoginMutation,
  useDeleteAccountMutation,
  useEditUserMutation,
  useGetCollegeListQuery,
  useSignupUserMutation,
  useVerifySignupMutation,
  useResetPasswordMutation,
  useAddExpoPushTokenMutation,
  useRemoveExpoPushTokenMutation
} = usersApiSlice;
