import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Layout } from '@ui-kitten/components';
import React, { useState } from 'react';
import { Image, TouchableOpacity } from 'react-native';

import AdminHomeNavigation from './adminPanel';
import { selectDarkMode, selectUserKey } from '../../app/appDataSlice';
import { useAppSelector } from '../../app/hooks';
import Header from '../../components/Header';
import { base_URL } from '../notifications/constants';

const AdminStack = createNativeStackNavigator();
export default function AdminPanel() {
  const accessoryLeft = (
    <Image
      style={{
        height: 28,
        width: 28,
        alignItems: 'center'
      }}
      source={require('../../../assets/logos/app-logo-transparent.png')}
    />
  );

  return (
    <AdminStack.Navigator>
      <AdminStack.Screen
        options={{
          header: () => {
            return (
              <Layout style={{ paddingBottom: 10 }}>
                <Header accessoryLeft={accessoryLeft} title="Admin Panel" />
              </Layout>
            );
          }
        }}
        name="AdminStack"
        component={AdminHomeNavigation}
      />
    </AdminStack.Navigator>
  );
}
