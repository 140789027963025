import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useTheme } from '@ui-kitten/components';

import { selectDarkMode } from '../../app/appDataSlice';
import { useAppSelector } from '../../app/hooks';
import BackButton from '../../components/BackButton';
import EditUser from './EditUser';
import SettingsPage from './SettingsPage';

const Stack = createNativeStackNavigator();

export default function Settings() {
  const theme = useTheme();
  const darkMode = useAppSelector(selectDarkMode);

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: darkMode ? theme['color-basic-800'] : '#fff'
        }
      }}
    >
      <Stack.Screen
        name="SettingsPage"
        component={SettingsPage}
        options={({ navigation }) => ({
          title: 'Settings',
          headerLeft: (props) => (
            <BackButton navigation={navigation} {...props} />
          )
        })}
      />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen
          name="EditUser"
          component={EditUser}
          options={{ headerShown: false }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}
