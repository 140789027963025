// import createSecureStore from "redux-persist-expo-securestore"
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ExpoSecureStorage from 'expo-secure-store';
import { Platform } from 'react-native';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import ExpoFileSystemStorage from 'redux-persist-expo-filesystem';

import createSecureStorage from './SecureStorage';
import appDataReducer from '../app/appDataSlice';
import calendarReducer from '../features/calendar/calendarSlice';
import classPagesReducer from '../features/classPages/classPagesSlice';
import discoverReducer from '../features/discover/discoverSlice';

// Secure storage

// const SecureStorage = createSecureStore();
const SecureStorage = createSecureStorage();

const securePersistConfig = {
  key: 'secure',
  storage: Platform.OS === 'web' ? AsyncStorage : SecureStorage
};

// Non-secure (AsyncStorage) storage
const cpPersistConfig = {
  key: 'classPages',
  storage: Platform.OS === 'android' ? ExpoFileSystemStorage : AsyncStorage,
  whitelist:
    Platform.OS === 'web'
      ? ['currentSection', 'hasSelectedElectiveGen']
      : ['classes', 'currentSection', 'isHMC', 'hasSelectedElectiveGen', 'orderedAddedClasses']
};

const discoverPersistConfig = {
  key: 'discover',
  storage: Platform.OS === 'android' ? ExpoFileSystemStorage : AsyncStorage,
  whitelist: ['recentSearches']
};

// Combine them together
export const rootReducer = combineReducers({
  classPages: persistReducer(cpPersistConfig, classPagesReducer),
  secure: persistReducer(securePersistConfig, appDataReducer),
  discover: persistReducer(discoverPersistConfig, discoverReducer),
  calendar: calendarReducer
});
