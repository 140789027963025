import { Feather, FontAwesome5 } from '@expo/vector-icons';
import { Button, useTheme } from '@ui-kitten/components';
import React from 'react';
import { ScrollView, View, Text } from 'react-native';

export default function Menu({ menuItems, setMenuItems }) {
  const theme = useTheme();

  return (
    <View style={{ marginVertical: 10 }}>
      <ScrollView
        showsHorizontalScrollIndicator={false}
        horizontal
        style={{ flexDirection: 'row' }}
      >
        {Object.entries(menuItems).map(([name, value]: any[], index) => {
          if (!value.selected) {
            return (
              <Button
                onPress={() => {
                  setMenuItems({
                    ...menuItems,
                    [name]: { selected: !value.selected }
                  });
                }}
                status={value.selected ? 'info' : 'primary'}
                style={{
                  height: 45,
                  marginHorizontal: 10,
                  borderRadius: 20,
                  backgroundColor: `rgba(${theme['color-success-500']}, ${
                    1 - 0.03 * index
                  })`,
                  borderColor: `rgba(${theme['color-success-500']}, ${
                    1 - 0.03 * index
                  })`
                }}
                key={index}
              >
                <View
                  style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                  <Text style={{ color: 'white' }}>
                    {name}
                    {value.selected && (
                      <Text>
                        {`    `}
                        <Feather name="x" size={15} />
                      </Text>
                    )}
                  </Text>
                </View>
              </Button>
            );
          }
        })}
      </ScrollView>
      <ScrollView
        showsHorizontalScrollIndicator={false}
        horizontal
        style={{ flexDirection: 'row', marginTop: 3, marginLeft: 8 }}
      >
        {Object.entries(menuItems).map(([name, value]: any[], index) => {
          if (value.selected) {
            return (
              <Button
                accessoryRight={
                  <FontAwesome5 name="times" size={14} color="white" />
                }
                onPress={() => {
                  setMenuItems({
                    ...menuItems,
                    [name]: { selected: !value.selected }
                  });
                }}
                style={{
                  height: 45,
                  marginHorizontal: 2,
                  borderRadius: 20,
                  backgroundColor: theme['color-success-500'],
                  borderColor: theme['color-success-500']
                }}
                key={index}
              >
                <View
                  style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                  <Text style={{ color: 'white' }}>{name}</Text>
                </View>
              </Button>
            );
          }
        })}
      </ScrollView>
    </View>
  );
}
