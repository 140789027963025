import { Layout } from '@ui-kitten/components';
import React from 'react';

export default function WrapItems({ childStyle, children, ...LayoutProps }) {
  return (
    <Layout {...LayoutProps}>
      {React.Children.map(children, (child) => (
        <Layout style={childStyle}>{child}</Layout>
      ))}
    </Layout>
  );
}
