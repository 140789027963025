import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface CalendarState {
  isThreeDay: boolean;
}

const initialState: CalendarState = {
  isThreeDay: false
};

export const calendarSlice = createSlice({
  name: 'discover',
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
    isThreeDaySet: (state, action: PayloadAction<boolean>) => {
      state.isThreeDay = action.payload;
    }
  }
});

export const { reset, isThreeDaySet } = calendarSlice.actions;

export default calendarSlice.reducer;
