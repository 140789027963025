import { Button, Divider, Layout, Text, Toggle } from '@ui-kitten/components';
import Constants from 'expo-constants';
import React, { useState } from 'react';
import { ScrollView, useColorScheme } from 'react-native';

import {
  deviceDarkModeSet,
  deleteAllAppData as resetAppData,
  selectDarkMode,
  selectIsGuest,
  selectUserKey,
  useDeviceColorSchemeSet
} from '../../app/appDataSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ModalContainer from '../../components/ModalContainer';
import WrapItems from '../../components/WrapItems';
import { apiSlice } from '../api/apiSlice';
import {
  useDeleteAccountMutation,
  useEditUserMutation,
  useGetUserInfoQuery,
  useRemoveExpoPushTokenMutation
} from '../api/usersApi';
import {
  reset as resetClassPages,
  clearUserData
} from '../classPages/classPagesSlice';
import { showToast } from '../../functions';

export default function SettingsPage({ navigation }) {
  const [settingsDisabled, setSettingsDisabled] = useState(false);
  const [editUser] = useEditUserMutation();
  const [removeExpoPushToken] = useRemoveExpoPushTokenMutation();
  const darkMode = useAppSelector(selectDarkMode);
  const useDeviceColorScheme = useAppSelector(
    (state) => state.appData.secure.useDeviceColorScheme
  );
  const expoPushToken = useAppSelector(
    (state) => state.appData.secure.deviceExpoPushToken
  );
  const userKey = useAppSelector(selectUserKey);
  const isGuest = useAppSelector(selectIsGuest);
  const colorScheme = useColorScheme();
  const { data: userData } = useGetUserInfoQuery(userKey, {
    skip: !userKey
  });
  const dispatch = useAppDispatch();
  const [showGuestModal, setShowGuestModal] = useState(false);
  const [deleteAccount] = useDeleteAccountMutation();

  const currentVersion =
    Constants?.manifest2?.extra?.expoClient?.version ??
    Constants?.manifest?.version;

  const logOut = async (willRemoveExpoPushToken: boolean) => {
    try {
      if (!isGuest && willRemoveExpoPushToken && expoPushToken) {
        await removeExpoPushToken({
          userKey,
          userName: userData.userName,
          expoPushToken
        }).unwrap();
      }

      dispatch(resetAppData());
      dispatch(clearUserData());
      dispatch(apiSlice.util.resetApiState());
    } catch (e) {
      showToast('Error logging out', 'red');
      console.warn('Error logging out: ', e);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteAccount({ userKey, userName: userData.userName }).unwrap();
      logOut(false);
    } catch (e) {
      console.warn('Error deleting account: ', e);
    }
  };

  const handleUseDeviceSettingToggled = (checked: boolean) => {
    dispatch(useDeviceColorSchemeSet(checked));
    if (checked) {
      dispatch(deviceDarkModeSet(colorScheme === 'dark'));
    }
  };

  const handleDarkModeToggled = (checked: boolean) => {
    dispatch(useDeviceColorSchemeSet(false));
    dispatch(deviceDarkModeSet(checked));
  };

  function handleSettingsChange(settingName, value) {
    setSettingsDisabled(true);
    const body = {
      settings: {}
    };
    body.settings[settingName] = value;
    editUser({
      userKey,
      userName: userData.userName,
      fields: JSON.stringify(body)
    })
      .unwrap()
      .then(() => setSettingsDisabled(false))
      .catch((e) => {
        setSettingsDisabled(false);
        console.warn(e.message);
      });
  }

  return (
    <Layout style={{ flex: 1 }}>
      <Layout
        style={{
          flexDirection: 'row',
          marginHorizontal: 20,
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1
        }}
      >
        <Layout>
          <Text category="h2">
            {userData?.realFirstName} {userData?.realLastName}
          </Text>
          <Text category="h6" status="success">
            @{userData?.userName}
          </Text>
        </Layout>
        <Layout>
          <Button
            onPress={() => {
              if (isGuest) {
                setShowGuestModal(true);
              } else {
                navigation.navigate('EditUser');
              }
            }}
          >
            Edit
          </Button>
        </Layout>
      </Layout>
      {showGuestModal && (
        <ModalContainer
          title="Wait!"
          children={
            <Text style={{ paddingHorizontal: 30 }}>
              This feature is not available to Guest Users. Please create a free
              account with us to take advantage this feature.
            </Text>
          }
          backDropVisible={showGuestModal}
          setBackDropVisible={setShowGuestModal}
        />
      )}

      <Divider />

      <Layout style={{ flex: 5 }}>
        <ScrollView
          bounces={false}
          contentContainerStyle={{ marginHorizontal: 20 }}
        >
          <Layout>
            <Text category="h6" style={{ textAlign: 'center', marginTop: 20 }}>
              Dark Mode
            </Text>
            <Layout
              style={{
                marginTop: 20,
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Text>Use Device Setting</Text>
              <Toggle
                onChange={(checked) => handleUseDeviceSettingToggled(checked)}
                checked={useDeviceColorScheme}
              />
            </Layout>
            <Layout
              style={{
                marginTop: 20,
                flexDirection: 'row',
                justifyContent: 'space-between',
                opacity: useDeviceColorScheme ? 0.4 : 1
              }}
            >
              <Text>Dark Mode</Text>
              <Toggle
                onChange={(checked) => handleDarkModeToggled(checked)}
                checked={darkMode}
              />
            </Layout>

            <Text category="h6" style={{ textAlign: 'center', marginTop: 20 }}>
              Push Notifications
            </Text>
            {userData.permissionLevel === 'admin' && (
              <Layout
                style={{
                  marginTop: 20,
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <Text>New Review Notification</Text>
                <Toggle
                  disabled={settingsDisabled}
                  onChange={(checked) =>
                    handleSettingsChange('newReview', checked)
                  }
                  checked={userData.settings.newReview}
                />
              </Layout>
            )}
            <Layout
              style={{
                marginTop: 20,
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Text>Alert Notifications</Text>
              <Toggle
                disabled={settingsDisabled}
                onChange={(checked) =>
                  handleSettingsChange('alertNotifications', checked)
                }
                checked={userData.settings.alertNotifications}
              />
            </Layout>

            <Layout
              style={{
                marginTop: 20,
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Text>Chat Notifications</Text>
              <Toggle
                disabled={settingsDisabled}
                onChange={(checked) =>
                  handleSettingsChange('chatNotifications', checked)
                }
                checked={userData.settings.chatNotifications}
              />
            </Layout>
            {false && (
              <Layout
                style={{
                  marginTop: 20,
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <Text>Class Notifications</Text>
                <Toggle
                  disabled={settingsDisabled}
                  onChange={(checked) =>
                    handleSettingsChange('classNotification', checked)
                  }
                  checked={userData.settings.classNotification}
                />
              </Layout>
            )}

            <Text category="h6" style={{ textAlign: 'center', marginTop: 20 }}>
              Other Settings
            </Text>

            <Layout
              style={{
                marginTop: 20,
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Text>Show Names in place of Usernames</Text>
              <Toggle
                disabled={settingsDisabled}
                onChange={(checked) =>
                  handleSettingsChange('displayNames', checked)
                }
                checked={userData.settings.displayNames}
              />
            </Layout>
          </Layout>

          <WrapItems
            childStyle={{ marginVertical: 3 }}
            style={{ alignItems: 'center', marginBottom: 10, marginTop: 100 }}
          >
            <Button
              onPress={() => logOut(true)}
              status="warning"
              appearance="outline"
            >
              Log Out
            </Button>
            <Button
              onPress={handleDeleteAccount}
              status="danger"
              appearance="ghost"
            >
              Delete Account
            </Button>
            <Text>{currentVersion ? `Version ${currentVersion}` : ''}</Text>
          </WrapItems>
        </ScrollView>
      </Layout>
    </Layout>
  );
}
