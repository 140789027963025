import { Layout, Spinner } from '@ui-kitten/components';

export default function FullPageSpinner({ opacity = 0.4 }) {
  return (
    <Layout
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: 'black',
        flex: 1,
        opacity,
        zIndex: 100
      }}
    >
      <Spinner size="large" />
    </Layout>
  );
}
