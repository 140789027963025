import {
  IndexPath,
  Layout,
  Select,
  SelectItem,
  Text
} from '@ui-kitten/components';
import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useLazyGetAllClassesQuery } from '../api/classPagesApi';
import { usersApiSlice } from '../api/usersApi';
import { currentSectionUpdated } from './classPagesSlice';
import { showToast } from '../../functions';
import FullPageSpinner from '../../components/FullPageSpinner';

export default function SelectTerm({ navigation }) {
  const dispatch = useAppDispatch();

  const availableSections = useAppSelector(
    (state) => state.appData.classPages.availableSections
  );
  const userKey = useAppSelector((state) => state.appData.secure.userKey);
  const currentSection = useAppSelector(
    (state) => state.appData.classPages.currentSection
  );

  const { data: userDataCache } =
    usersApiSlice.endpoints.getUserInfo.useQueryState(userKey, {
      skip: !userKey
    });
  const [getAllClasses, { isFetching }] = useLazyGetAllClassesQuery();

  const startIndexValue =
    availableSections.findIndex((term) => term == currentSection) || 0;

  const [selectedSectionIndex, setSelectedSectionIndex] = useState<IndexPath>(
    new IndexPath(startIndexValue)
  );

  const saveChanges = async () => {
    if (availableSections[selectedSectionIndex.row] !== currentSection) {
      try {
        await getAllClasses({
          userKey,
          collegeName: userDataCache.collegeName,
          courseTerm: availableSections[selectedSectionIndex.row]
        }).unwrap();

        dispatch(
          currentSectionUpdated(availableSections[selectedSectionIndex.row])
        );
        navigation.goBack();
      } catch (e) {
        console.warn('Changing section: could not fetch classes', e);
        showToast('Error switching terms', 'red');
      }
    }
  };

  return (
    <Layout style={{ flex: 1 }}>
      {isFetching && <FullPageSpinner />}
      <Layout
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          margin: 20,
          marginTop: 60
        }}
      >
        <TouchableOpacity
          onPress={() => {
            navigation.goBack();
          }}
        >
          <Text>Cancel</Text>
        </TouchableOpacity>

        <Text category="s1">Select Term</Text>

        <TouchableOpacity onPress={saveChanges}>
          <Text>Save</Text>
        </TouchableOpacity>
      </Layout>

      <Select
        style={{ margin: 20 }}
        label="Term"
        value={availableSections[selectedSectionIndex.row]}
        selectedIndex={selectedSectionIndex}
        onSelect={(index: IndexPath) => {
          setSelectedSectionIndex(index);
        }}
      >
        {availableSections.map((term, index) => (
          <SelectItem key={index} title={term} />
        ))}
      </Select>
    </Layout>
  );
}
