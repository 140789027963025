import { Layout, Text } from '@ui-kitten/components';

export default function Message({ title, item }) {
  return (
    <Layout
      style={{
        flex: 1,
        marginTop: 10,
        alignItems: 'center',
        backgroundColor: 'transparent'
      }}
    >
      <Text style={{ margin: 20, color: '#fff' }}>{title}</Text>
      {item}
    </Layout>
  );
}
