import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';

import ClassesList from '../../classPages/ClassesList';
import SingleClassPage from '../../classPages/SingleClassPage';

const Stack = createNativeStackNavigator();

export default function ClassListPage() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ClassesList"
        component={ClassesList}
        options={{
          headerShown: false,
          title: ''
        }}
      />
      <Stack.Screen
        name="SingleClassPage"
        component={SingleClassPage}
        options={{
          headerShown: false
        }}
      />
    </Stack.Navigator>
  );
}
