import {
  Button,
  Card,
  Divider,
  Layout,
  Modal,
  Spinner,
  Text,
  useTheme,
  withStyles
} from '@ui-kitten/components';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useRef, useState } from 'react';
import {
  FlatList,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity
} from 'react-native';
import openMap from 'react-native-open-maps';

import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import {
  selectDarkMode,
  selectIsGuest,
  selectUserKey
} from '../../../app/appDataSlice';
import { useAppSelector } from '../../../app/hooks';
import ModalContainer from '../../../components/ModalContainer';
import { base_URL } from '../../../constants/admin';
import { locations, removeNumbersAndSpaces } from '../../../constants/map';
import {
  useAddClassMutation,
  useAddStarredClassMutation,
  useGetReviewsByClassMutation,
  useIsAllowedToReviewQuery,
  useRemoveClassMutation,
  useRemoveStarredClassMutation
} from '../../api/classPagesApi';
import { useGetUserInfoQuery } from '../../api/usersApi';
import ExportModalClass from './exportModalClass';

function SingleClassPage({ eva, navigation, selectedClass = null }) {
  const [removeStarredClass] = useRemoveStarredClassMutation();
  const [showModal, setShowModal] = useState(false);
  const scrollViewRef = useRef(null);
  const [showCourseModal, setShowCourseModal] = useState(false);
  const theme = useTheme();
  const isGuest = useAppSelector(selectIsGuest);
  const [courseModalChildren, setCourseModalChildren] = useState([]);
  const userKey = useAppSelector(selectUserKey);
  const { data: userData } = useGetUserInfoQuery(userKey, {
    skip: !userKey
  });
  const [showGuestModal, setShowGuestModal] = useState(false);
  const darkMode = useAppSelector(selectDarkMode);
  const [professorReviewsObject, setProfessorReviewsObject] = useState({});
  const [averageRating, setAverageRating] = useState(null);
  const [averageDifficulty, setAverageDifficulty] = useState(null);
  const [addStarredClass] = useAddStarredClassMutation();
  const isHMC = useAppSelector((state) => state.appData.classPages.isHMC);
  if (!selectedClass) {
    selectedClass = useAppSelector(
      (state) => state.appData.classPages.selectedClass
    );
  }
  const [getReviewsByClass, { isLoading: reviewsLoading }] =
    useGetReviewsByClassMutation();

  const [reviews, setReviews] = useState([]);
  const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];
  const backgroundColor = darkMode ? eva.style.dark : eva.style.light;

  const { data: isAllowedToReviewRes } = useIsAllowedToReviewQuery({
    userKey,
    masterCourseCode: selectedClass.masterCourseCode
  });

  function isStarred(courseCode: string) {
    return userData.starredClasses.some(
      (course) =>
        course.courseCode === courseCode &&
        course.courseTerm === selectedClass.courseTerm
    );
  }

  function userHasCourse(courseCode: string): boolean {
    return (
      userData.addedClasses.classes[selectedClass.courseTerm] !== undefined &&
      userData.addedClasses.classes[selectedClass.courseTerm][
        courseCode.slice(0, -3)
      ] !== undefined
    );
  }

  const courseToggleChildren = (courseForToggle) => {
    const final = [
      <Layout>
        <Button
          style={{ marginVertical: 5 }}
          children={
            !userHasCourse(courseForToggle.courseCode)
              ? 'Add Course to Schedule'
              : 'Remove Course from Schedule'
          }
          onPress={() => {
            toggleAddOrRemoveCourse(courseForToggle.courseCode);
            setShowCourseModal(false);
          }}
        />
        <Button
          style={{ marginVertical: 5 }}
          children={
            !isStarred(courseForToggle.courseCode) ? 'Add Star' : 'Remove Star'
          }
          onPress={() => {
            if (isGuest) {
              setShowGuestModal(true);
            } else {
              toggleStarCourse(courseForToggle.courseCode);
              setShowCourseModal(false);
            }
          }}
        />
      </Layout>
    ];
    setCourseModalChildren(final);
    setShowCourseModal(true);
  };

  const getRatingFromReviews = (fetchedReviews) => {
    if (fetchedReviews.length === 0) {
      return null;
    }
    let totalAverage = 0;
    let counter = 0;
    for (const review of fetchedReviews) {
      if (review.rating) {
        totalAverage += review.rating;
        counter++;
      }
    }
    if (counter > 0) {
      return (totalAverage / counter).toFixed(2);
    }
    return null;
  };

  const getDifficultyFromReviews = (fetchedReviews) => {
    if (fetchedReviews.length === 0) {
      return null;
    }
    let totalAverage = 0;
    let counter = 0;
    for (const review of fetchedReviews) {
      if (review.difficulty) {
        totalAverage += review.difficulty;
        counter++;
      }
    }
    if (counter > 0) {
      return (totalAverage / counter).toFixed(2);
    }
    return null;
  };

  async function getReviews() {
    try {
      const reviews = await getReviewsByClass({
        masterCourseCode: selectedClass.masterCourseCode,
        userKey
      }).unwrap();

      if (reviews.length > 0) {
        setAverageRating(getRatingFromReviews(reviews));
        setAverageDifficulty(getDifficultyFromReviews(reviews));
        setReviews(reviews);
      }
    } catch (e) {
      console.warn('error getting reviews', e);
    }
  }

  useEffect(() => {
    setProfessorReviewsObject({});
    setAverageDifficulty(null);
    setAverageRating(null);
    setReviews([]);
    if (scrollViewRef?.current) {
      scrollViewRef.current.scrollTo({
        y: 0,
        animated: false
      });
    }
    if (selectedClass) {
      for (const time of selectedClass.times) {
        getReviewsFromProfessor(time);
      }
    }
    getReviews();
  }, [selectedClass]);

  async function getReviewsFromProfessor(time) {
    const res = await fetch(
      `${base_URL}/classes/reviews/getReviewsByProfessor` +
        `?professorName=${time.professorName}&collegeName=${userData.collegeName}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          userKey
        }
      }
    );
    if (res.ok) {
      const reviews = await res.json();
      if (reviews.length > 0) {
        const newObject = {
          ...professorReviewsObject
        };
        newObject[time.professorName] = reviews;
        setProfessorReviewsObject(newObject);
      }
    }
  }

  function buildTimeString(time) {
    // Compatibility with new course data layout for multiple times per day
    if (time.courseSchedule && time.courseSchedule.length > 0) {
      time = time.courseSchedule[0];
    }

    if (!time.startTime || time.startTime === '' || time.startTime === null) {
      return 'closed';
    }
    return `${convertTo12HourTime(time.startTime)} - ${convertTo12HourTime(
      time.endTime
    )}\n`;
  }

  function convertTo12HourTime(time) {
    if (time.length !== 5) {
      time = `0${time}`;
    }
    let hours = time.slice(0, 2);
    const minutes = time.slice(3, 5);
    let ampm = Number.parseInt(hours) > 12 ? 'pm' : 'am';
    hours %= 12;
    if (hours === 0) {
      hours = 12;
      ampm = 'pm';
    }
    return `${hours}:${minutes}${ampm}`;
  }

  function getProfessorReviewsList() {
    const final = [];
    for (const professor of Object.keys(professorReviewsObject)) {
      const reviews = professorReviewsObject[professor];
      final.push(
        <>
          <Text style={styles.subHeader}>
            {`All Reviews: `}
            {professor}
          </Text>
          {reviews.length === 0 ? (
            <Text
              style={{
                alignSelf: 'center',
                textAlign: 'center',
                marginBottom: 10
              }}
            >
              Something went wrong...
            </Text>
          ) : (
            reviews.map((review, index) => (
              <Card key={index} disabled>
                <Text>{`${review.text}\n\nPosted: ${review.date}`}</Text>
              </Card>
            ))
          )}
        </>
      );
    }
    return final;
  }

  const averageGradeMSGModal = (
    <Modal
      visible={showModal}
      backdropStyle={styles.backdrop}
      style={{ width: '75%' }}
      onBackdropPress={() => setShowModal(false)}
    >
      <Card disabled>
        <Text style={{ fontSize: 15, paddingTop: 5, paddingBottom: 10 }}>
          The Athena Network collects and aggregates the data anonymously
          submitted by the Course Review process and calculates what the average
          student's experience in this course will look like. Given that it is
          based on voluntarily submitted data, this information is to be used as
          a reference only. Please consult your advisor before making any
          impactful decisions.
        </Text>
      </Card>
    </Modal>
  );

  if (!selectedClass.times) {
    return null;
  }

  function getProfessorsList() {
    const list = [];
    for (const time of selectedClass.times) {
      if (!list.includes(time.professorName)) {
        list.push(time.professorName);
      }
    }
    return list;
  }

  const [schedulePopup, setSchedulePopup] = useState(false);
  const [allTheChildren, setAllTheChildren] = useState([]);

  function toggleSchedulePopUp(time: any) {
    const finalDays = {};
    for (const realTime of time.courseSchedule) {
      for (const day of daysOfWeek) {
        if (realTime.daysOfTheWeek.includes(day)) {
          const addition = `${convertTo12HourTime(
            realTime.startTime
          )} - ${convertTo12HourTime(realTime.endTime)}`;
          if (finalDays[day]?.length > 0) {
            finalDays[day].push(addition);
          } else {
            finalDays[day] = [addition];
          }
        }
      }
    }

    setAllTheChildren(generateTable(finalDays));
    setSchedulePopup(true);
  }
  const [addClass] = useAddClassMutation();
  const [removeClass] = useRemoveClassMutation();
  function toggleStarCourse(courseCode: string) {
    try {
      if (isStarred(courseCode)) {
        removeStarredClass({
          userKey,
          courseCode,
          courseTerm: selectedClass.courseTerm,
          userName: userData.userName
        }).unwrap();
      } else {
        addStarredClass({
          userKey,
          courseCode,
          courseTerm: selectedClass.courseTerm,
          userName: userData.userName
        }).unwrap();
      }
    } catch (e) {
      console.warn('Error starring class', e);
    }
  }

  function toggleAddOrRemoveCourse(courseCode: string) {
    if (userHasCourse(courseCode)) {
      removeClass({
        userKey,
        courseCode,
        courseTerm: selectedClass.courseTerm,
        userName: userData.userName,
        masterCourseCode: courseCode.slice(0, -3)
      }).unwrap();
    } else {
      addClass({
        userKey,
        courseCode,
        courseTerm: selectedClass.courseTerm,
        userName: userData.userName,
        masterCourseCode: courseCode.slice(0, -3)
      }).unwrap();
    }
  }
  function generateTable(daysObject: any): any {
    const daysActive = Object.keys(daysObject);
    const stringArrays = Object.values(daysObject);
    return (
      <Layout style={{ flexDirection: 'row' }}>
        <Layout>
          {daysActive.map((value) => {
            return (
              <Layout>
                <Text
                  style={{
                    height: 75,
                    borderWidth: 1,
                    borderColor: 'black',
                    padding: 10
                  }}
                >
                  {value}
                </Text>
              </Layout>
            );
          })}
        </Layout>
        <Layout>
          {stringArrays.map((value: string[]) => {
            return (
              <Layout>
                <Text
                  style={{
                    height: 75,
                    borderWidth: 1,
                    borderColor: 'black',
                    padding: 10
                  }}
                >
                  {value.join('\n')}
                </Text>
              </Layout>
            );
          })}
        </Layout>
      </Layout>
    );
  }

  function renderReviews() {
    let content = null;
    if (reviewsLoading) {
      content = (
        <Layout
          style={{ alignItems: 'center', backgroundColor: 'transparent' }}
        >
          <Spinner />
        </Layout>
      );
    } else if (reviews.length === 0) {
      content = (
        <Text style={{ textAlign: 'center' }}>No Class Reviews yet</Text>
      );
    } else {
      content = (
        <FlatList
          data={reviews}
          renderItem={({ item: review, index }) => (
            <Card key={index} disabled style={{ marginBottom: 5 }}>
              <Text>{review.text}</Text>
              <Divider style={{ marginVertical: 5 }} />
              <Text category="s2">
                Posted:
                {review.date}
              </Text>
              {review.professor && (
                <Text category="s2">{`Taught By: ${review.professor}`}</Text>
              )}
            </Card>
          )}
        />
      );
    }

    return (
      <Layout style={{ margin: 10, backgroundColor: 'transparent' }}>
        {content}
      </Layout>
    );
  }

  return (
    <LinearGradient
      colors={
        darkMode
          ? [theme['color-basic-1100'], theme['color-basic-1100']]
          : [theme['color-primary-500'], theme['color-success-500']]
      }
      style={{
        width: '100%',
        margin: 0,
        alignItems: 'center',
        flex: 1
      }}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0.4 }}
    >
      {showGuestModal && (
        <ModalContainer
          title="Wait!"
          children={
            <Text style={{ paddingHorizontal: 30 }}>
              This feature is locked to our Guest Users. Please create a free
              account with us to take advantage this feature.
            </Text>
          }
          backDropVisible={showGuestModal}
          setBackDropVisible={setShowGuestModal}
        />
      )}
      <ScrollView
        contentContainerStyle={{ alignSelf: 'center', maxWidth: 700 }}
        style={backgroundColor}
        showsVerticalScrollIndicator={false}
        ref={scrollViewRef}
      >
        {/* {userData.starredClasses.some((course) =>
          selectedClass.times.some(
            (time: { courseCode: any; courseTerm: any }) =>
              time.courseCode === course.courseCode &&
              selectedClass.courseTerm === course.courseTerm
          )
        ) ? (
          <Layout
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'center',
              backgroundColor
            }}
          >
            <FontAwesome
              style={{ marginLeft: 10, marginTop: 10 }}
              name="star"
              color="gold"
              size={20}
            />
            <ExportModalClass classData={undefined} />
          </Layout>
        ) : (
          <Layout
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'center',
              backgroundColor
            }}
          >
            <FontAwesome5
              style={{ marginLeft: 10, marginTop: 10 }}
              name="star"
              color="gold"
              size={20}
            />
            <ExportModalClass classData={undefined} />
          </Layout>
        )} */}

        <Layout style={[{ alignItems: 'center' }, backgroundColor]}>
          <Text style={styles.title}>{selectedClass.courseName}</Text>
          <Text style={{ paddingTop: 30 }}>
            {selectedClass.masterCourseCode}
          </Text>

          <Text style={styles.subtitle}>
            {`${selectedClass.courseTerm} \u2022 credits: ${
              isHMC ? selectedClass.creditWeightHM : selectedClass.creditWeight
            }`}
          </Text>
          {selectedClass.timeSpentOutOfClass && (
            <Layout
              style={[
                {
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  paddingRight: 5
                },
                backgroundColor
              ]}
            >
              <Text style={styles.subtitle}>Time Spent Per Week</Text>
              <Text style={{ fontSize: 12, lineHeight: 13 }}>i</Text>
              <Text style={styles.subtitle}>
                : {selectedClass.timeSpentOutOfClass} hours
              </Text>
            </Layout>
          )}
          {selectedClass.averageGrade && (
            <Layout
              style={[
                {
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  paddingRight: 5
                },
                backgroundColor
              ]}
            >
              <Text style={styles.subtitle}>Average Grade</Text>
              <Text style={{ fontSize: 12, lineHeight: 13 }}>i</Text>
              <Text style={styles.subtitle}>
                : {selectedClass.averageGrade}
              </Text>
            </Layout>
          )}
          {averageDifficulty && (
            <Layout
              style={[
                {
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  paddingRight: 5
                },
                backgroundColor
              ]}
            >
              <Text style={styles.subtitle}>Estimated Difficulty</Text>
              <Text style={{ fontSize: 12, lineHeight: 13 }}>i</Text>
              <Text style={styles.subtitle}>:{averageDifficulty} / 5</Text>
            </Layout>
          )}
          {averageRating && (
            <Layout
              style={[
                {
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  paddingRight: 5
                },
                backgroundColor
              ]}
            >
              <Text style={styles.subtitle}>Average Rating</Text>
              <Text style={{ fontSize: 12, lineHeight: 13 }}>i</Text>
              <Text style={styles.subtitle}>:{averageRating} / 5</Text>
            </Layout>
          )}
        </Layout>
        <Layout style={backgroundColor}>
          <Text style={styles.courseDescription}>{selectedClass.summary}</Text>
        </Layout>
        <Layout style={{ ...backgroundColor, ...styles.boxed }}>
          <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
            Other Stated Prerequisites:
          </Text>
          <Text style={{ fontSize: 15 }}>
            {selectedClass.prerequisites !== ''
              ? selectedClass.prerequisites
              : 'none'}
          </Text>

          <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
            Other Stated Corequisites:
          </Text>
          <Text style={{ fontSize: 15 }}>
            {selectedClass.corequisites !== ''
              ? selectedClass.corequisites
              : 'none'}
          </Text>
        </Layout>
        <Layout style={[{ alignItems: 'flex-end' }, backgroundColor]}>
          {(selectedClass.timeSpentOutOfClass ||
            selectedClass.averageGrade ||
            selectedClass.difficulty ||
            selectedClass.rating) && (
            <TouchableOpacity onPress={() => setShowModal(!showModal)}>
              <Layout
                style={[
                  {
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    paddingRight: 5
                  },
                  backgroundColor
                ]}
              >
                <Text style={{ fontSize: 12, lineHeight: 13 }}>i</Text>
                <Text style={styles.disclaimer}>How Do We Estimate This?</Text>
              </Layout>
            </TouchableOpacity>
          )}
        </Layout>
        <Layout style={{ backgroundColor }}>
          {selectedClass.times.length > 0 && (
            <Layout style={{ backgroundColor }}>
              <Text style={styles.subHeader}>Sections</Text>
              <Layout style={[styles.section, backgroundColor]}>
                <Text style={styles.headerText}>Course Code</Text>
                <Layout
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    backgroundColor
                  }}
                >
                  <Text style={{ fontWeight: 'bold', fontSize: 13 }}>
                    ST / SA
                  </Text>
                  <Text style={{ fontWeight: '900', fontSize: 13 }}>
                    {' '}
                    (PERM)
                  </Text>
                </Layout>
                <Text style={styles.headerText}>Professor</Text>
                <Text style={styles.headerText}>Time</Text>
                <Text style={styles.headerText}>Location</Text>
              </Layout>
            </Layout>
          )}
          {selectedClass.times.map((time) => {
            return (
              <Layout
                key={time.courseCode}
                style={{ backgroundColor, ...styles.section }}
              >
                {userData.starredClasses.some(
                  (course) =>
                    course.courseTerm === selectedClass.courseTerm &&
                    course.courseCode === time.courseCode
                ) && (
                  <FontAwesome
                    style={{ marginRight: 5 }}
                    name="star"
                    color="gold"
                    size={12}
                  />
                )}
                <TouchableOpacity
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  onPress={() => {
                    courseToggleChildren(time);
                  }}
                >
                  <Text
                    style={{
                      ...styles.sectionText,
                      color: 'rgba(55,81,211,0.84)',
                      textDecorationLine: 'underline'
                    }}
                  >
                    {time.courseCode}
                  </Text>
                </TouchableOpacity>
                <Layout
                  style={{ flex: 1, flexDirection: 'row', backgroundColor }}
                >
                  <Text>
                    {time.seatsFilled} /{time.seatsFilled + time.seatsAvailable}
                    {'  '}
                  </Text>
                  <Text style={{ fontWeight: 'bold' }}>
                    ({time.permCount ? time.permCount : 0})
                  </Text>
                </Layout>
                <Text style={styles.sectionText}>{time.professorName}</Text>
                <Layout style={{ backgroundColor, ...styles.sectionText }}>
                  {time?.courseSchedule?.length > 1 ? (
                    <TouchableOpacity
                      onPress={() => toggleSchedulePopUp(time)}
                      disabled={!(time?.courseSchedule?.length > 1)}
                    >
                      <Text
                        style={{
                          ...styles.sectionText,
                          color: 'rgba(55,81,211,0.84)',
                          textDecorationLine: 'underline'
                        }}
                      >
                        {Platform.OS === 'web' ? 'Click' : 'Press'} for
                        {'\n'}
                        schedule
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <Text style={styles.sectionText}>
                      {buildTimeString(time)}
                      {time?.courseSchedule?.length > 0
                        ? time.courseSchedule[0].daysOfTheWeek.join(', ')
                        : time.daysOfTheWeek.join(', ')}
                    </Text>
                  )}
                </Layout>
                <TouchableOpacity
                  disabled={
                    !locations[
                      removeNumbersAndSpaces(
                        time.courseSchedule[0].classLocation
                      )
                    ]
                  }
                  style={{ flex: 1 }}
                  onPress={() => {
                    openMap({
                      waypoints: [],
                      provider: 'google',
                      query: locations[
                        removeNumbersAndSpaces(
                          time.courseSchedule[0].classLocation
                        )
                      ]
                        .map((number) => number.toString())
                        .join(', '),
                      zoom: 18
                    });
                  }}
                >
                  <Text
                    style={
                      locations[
                        removeNumbersAndSpaces(
                          time.courseSchedule[0].classLocation
                        )
                      ]
                        ? {
                            ...styles.sectionText,
                            color: 'rgba(55,81,211,0.84)',
                            textDecorationLine: 'underline'
                          }
                        : { flex: 1 }
                    }
                  >
                    {time.courseSchedule[0].classLocation
                      ? time.courseSchedule[0].classLocation
                      : 'TBD'}
                  </Text>
                </TouchableOpacity>
              </Layout>
            );
          })}
          <ModalContainer
            backDropVisible={showCourseModal}
            setBackDropVisible={setShowCourseModal}
            title="Add/Remove Course"
            children={courseModalChildren}
          />
          {averageGradeMSGModal}
          <ModalContainer
            children={allTheChildren}
            backDropVisible={schedulePopup}
            setBackDropVisible={setSchedulePopup}
            title="Course Time Schedule"
          />
        </Layout>
        {isAllowedToReviewRes?.isAllowed ? (
          <Button
            style={{ margin: 10 }}
            onPress={() => {
              navigation.navigate('PostReview', {
                professorList: getProfessorsList()
              });
            }}
          >
            Post Review
          </Button>
        ) : null}
        <Text style={styles.subHeader}>Class Reviews</Text>
        {renderReviews()}
        {Object.keys(professorReviewsObject).length !== 0
          ? getProfessorReviewsList()
          : null}
      </ScrollView>
    </LinearGradient>
  );
}

export default withStyles(SingleClassPage, (theme) => ({
  dark: {
    backgroundColor: theme['color-basic-900']
  },
  light: {
    backgroundColor: theme['color-basic-200']
  }
}));

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    marginTop: 20,
    fontWeight: 'bold'
  },
  subtitle: {
    fontSize: 16
  },
  courseOpen: {
    backgroundColor: 'green',
    color: '#fff',
    padding: 5,
    marginRight: 10
  },
  courseClosed: {
    backgroundColor: 'red',
    color: '#fff',
    padding: 5,
    borderRadius: 5
  },
  header: {
    alignItems: 'center'
  },
  details: {
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  courseDescription: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 5,
    fontSize: 17
  },
  BodyContainer: {
    borderTopWidth: 1,
    borderTopColor: 'black',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    padding: 10,
    paddingTop: 20,
    paddingBottom: 20
  },
  boxed: {
    alignItems: 'center',
    margin: 8,
    borderColor: 'black',
    borderWidth: 1,
    padding: 7
  },
  documentStyle: {
    width: 150,
    height: 190,
    paddingTop: 100
  },
  imageLabel: {
    fontSize: 12,
    color: 'black',
    padding: 10,
    textAlign: 'center'
  },
  schedule: {
    paddingLeft: 20,
    paddingTop: 10
  },
  graphImage: {
    width: '100%',
    aspectRatio: 1,
    resizeMode: 'contain'
  },
  grade: {
    fontSize: 50,
    fontWeight: 'bold',
    color: 'green'
  },
  reviewText: {
    fontSize: 16,
    fontStyle: 'italic'
  },
  reviewBox: {
    width: '95%',
    padding: 10,
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 5,
    marginBottom: 10
  },
  section: {
    flexDirection: 'row',
    paddingLeft: 5,
    borderBottomWidth: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 13,
    flex: 1
  },
  sectionText: {
    flex: 1
  },
  disclaimer: {
    fontSize: 14,
    fontStyle: 'italic'
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  input: {
    height: 50,
    margin: 12,
    borderWidth: 1,
    padding: 10
  },
  containerDrop: {
    height: 42,
    margin: 12,
    borderWidth: 1
  },
  indentedInput: {
    height: 50,
    marginVertical: 12,
    borderWidth: 1,
    padding: 10,
    marginRight: 12,
    marginLeft: 30
  },
  text: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: 'white'
  },
  subHeader: {
    fontSize: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    paddingTop: 20,
    fontWeight: 'bold'
  }
});
