import { Layout, Text } from '@ui-kitten/components';
import Constants from 'expo-constants';

type HeaderProps = {
  accessoryLeft?: keyof JSX.IntrinsicElements | JSX.Element;
  accessoryRight?: keyof JSX.IntrinsicElements | JSX.Element;
  center?: keyof JSX.IntrinsicElements | JSX.Element;
  title: string;
  level?: '1' | '2' | '3' | '4';
};

export default function Header({
  accessoryLeft,
  accessoryRight,
  title,
  center,
  level = '1'
}: HeaderProps) {
  return (
    <Layout
      level={level}
      style={{
        paddingTop: Constants.statusBarHeight + 10,
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 20
      }}
    >
      <Layout level={level} style={{ flexDirection: 'row' }}>
        {accessoryLeft}
        <Text style={{ marginLeft: 10, fontSize: 20, fontWeight: '500' }}>
          {title}
        </Text>
      </Layout>
      {center}

      {accessoryRight}
    </Layout>
  );
}
