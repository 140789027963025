import Toast from 'react-native-root-toast';

import { Class, ClassTime, ClassesObject } from './app/types';
import { EMOJI_LIST, IMAGE_BUCKET_URL } from './constants/discover';
import { dayToICalAbbrev } from './constants/classPages';

export function getRndInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function getImageUrl(imageId: string) {
  return `${IMAGE_BUCKET_URL}/${imageId}.jpg`;
}

export function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

export function versionCompare(v1: string, v2: string, options?: any) {
  const lexicographical = options && options.lexicographical;
  const zeroExtend = options && options.zeroExtend;
  let v1parts = v1.split('.') as any[];
  let v2parts = v2.split('.') as any[];

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push('0');
    while (v2parts.length < v1parts.length) v2parts.push('0');
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number);
    v2parts = v2parts.map(Number);
  }

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] === v2parts[i]) {
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
}

export const stringToEmoji = (str: string) => {
  if (!str) {
    return '';
  }
  let dec = 0;
  for (let i = 0; i < str.length; ++i) {
    dec += str.charCodeAt(i) << i;
  }
  const emoji = EMOJI_LIST[dec % EMOJI_LIST.length];
  return emoji;
};

export function validURL(str: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

export function showToast(message: string, backgroundColor = '#000') {
  Toast.show(message, {
    duration: Toast.durations.LONG,
    opacity: 1,
    position: -100,
    backgroundColor,
    shadow: false
  });
}

// Format range with 12 hour time
export const FormatTimeRange = (start: string, end: string) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const startHours = startDate.getHours() % 12 || 12;
  const startMinutes = startDate.getMinutes();
  const startAmPm = startDate.getHours() >= 12 ? 'pm' : 'am';
  const endHours = endDate.getHours() % 12 || 12;
  const endMinutes = endDate.getMinutes();
  const endAmPm = endDate.getHours() >= 12 ? 'pm' : 'am';
  return `${startHours}:${startMinutes}${startAmPm} - ${endHours}:${endMinutes}${endAmPm}`;
};

const weekDayNumberToName = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
};

export const FormatDate = (date: string) => {
  const newDate = new Date(date);

  const weekDay = newDate.getDay();
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  return `${weekDayNumberToName[weekDay]}, ${month}/${day}`;
};

const dateStringtoICal = (dateStr: string) => {
  const dateObject = new Date(dateStr);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');
  return `${year}${month}${day}T`;
};

export const exportToICal = (
  events: ClassTime[],
  startDateStr: string,
  endDateStr: string
): string => {
  const until = dateStringtoICal(endDateStr) + '000000';
  const startDate = dateStringtoICal(startDateStr);
  const startDateTime = startDate + '000000';
  let iCalString =
    'BEGIN:VCALENDAR\nCALSCALE:GREGORIAN\nPRODID:AthenaCalendar\nVERSION:2.0';

  for (const [index1, courseSection] of events.entries()) {
    for (const [index2, time] of courseSection.courseSchedule.entries()) {
      const days = time.daysOfTheWeek
        .map((day) => dayToICalAbbrev[day])
        .join(',');
      const startTime = time.startTime.split(':').join('') + '00';
      const endTimeParts = time.endTime.split(':');
      const endTime = endTimeParts.join('') + endTimeParts[1];
      iCalString += `
BEGIN:VEVENT
UID:${index1}${index2}@default
CLASS:PUBLIC
DESCRIPTION:${courseSection.courseCode} ${courseSection.courseName}\\n${
        courseSection.professorName
      }
RRULE:FREQ=WEEKLY;UNTIL=${until};INTERVAL=1;BYDAY=${days}
DTSTAMP;VALUE=DATE-TIME:${startDateTime}
DTSTART;VALUE=DATE-TIME:${startDate + startTime}
DTEND;VALUE=DATE-TIME:${startDate + endTime}
LOCATION:${time.classLocation}
SUMMARY;LANGUAGE=en-us:${courseSection.courseName}
TRANSP:TRANSPARENT
END:VEVENT`;
    }
  }

  iCalString += '\nEND:VCALENDAR';

  return iCalString;
};

export const getMasterCourseCodeFromCourseCode = (courseCode: string) => {
  const courseCodeParts = courseCode.split('-');
  return courseCodeParts.slice(0, courseCodeParts.length - 1).join('-');
};

export const getTimeAsNumberOfMinutes = (time: string) => {
  var timeParts = time.split(':');

  var timeInMinutes = parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);

  return timeInMinutes;
};

export function isValidEmail(email: string) {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export function addCourseInjections(courses: ClassesObject) {
  const addKeyWords = (course: Class): string => {
    let keywords = [];

    try {
      const masterCourseCodeParts = course.masterCourseCode.split(' ');
      // if (MASTERCOURSECODE_KEYWORDS[masterCourseCodeParts[0]]) {
      //   keywords.push(MASTERCOURSECODE_KEYWORDS[masterCourseCodeParts[0]])
      // }
      const editedNumberPart = masterCourseCodeParts[1].replace(/^0+/i, '');
      // unknown number of total parts
      masterCourseCodeParts[1] = editedNumberPart;

      const combined = masterCourseCodeParts.join(' ');

      keywords.push(combined);
    } catch (e) {}

    return keywords.join(', ');
  };

  const getProfessorNames = (times) => {
    const professorNames = [];
    for (const time of times) {
      if (time.professorName) {
        professorNames.push(time.professorName);
      }
    }
    return professorNames.join(', ');
  };
  return Object.fromEntries(
    Object.entries(courses).map(([key, value]) => {
      return [
        key,
        {
          ...value,
          professorNames: getProfessorNames(value.times),
          keywords: addKeyWords(value)
        }
      ];
    })
  );
}

//Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350;

export const scale = (size: number, width: number) =>
  (width / guidelineBaseWidth) * size;
