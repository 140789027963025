import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

interface DiscoverState {
  searchTerm: string;
  submittedSearchTerm: string;
  tag: string;
  recentSearches: string[];
}
const initialState: DiscoverState = {
  searchTerm: '',
  submittedSearchTerm: '',
  tag: '',
  recentSearches: []
};

export const discoverSlice = createSlice({
  name: 'discover',
  initialState,
  reducers: {
    reset: () => initialState,
    searchTermSubmitted: (state, action: PayloadAction<string>) => {
      state.submittedSearchTerm = action.payload;
      if (action.payload && !state.recentSearches.includes(action.payload)) {
        state.recentSearches.unshift(action.payload);
      }
    },
    searchTermChanged: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
      if (action.payload === '') {
        state.submittedSearchTerm = '';
      }
    },
    recentSearchTermSelected: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
      state.submittedSearchTerm = action.payload;
    },
    tagSelected: (state, action: PayloadAction<string>) => {
      state.tag = action.payload;
    },
    recentSearchTermRemoved: (state, action: PayloadAction<string>) => {
      state.recentSearches = state.recentSearches.filter(
        (term) => term !== action.payload
      );
    }
  }
});

export const {
  reset,
  searchTermChanged,
  searchTermSubmitted,
  tagSelected,
  recentSearchTermSelected,
  recentSearchTermRemoved
} = discoverSlice.actions;

export const selectSearchTerm = (state: RootState) => state.appData.discover.searchTerm;
export const selectSubmittedSearchTerm = (state: RootState) => state.appData.discover.submittedSearchTerm;
export const selectTag = (state: RootState) => state.appData.discover.tag;
export const selectRecentSearches = (state: RootState) => state.appData.discover.recentSearches;

export default discoverSlice.reducer;
