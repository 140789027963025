// This is going to take forever...
import { Region } from 'react-native-maps';

export const locations = {
  ClaremontColleges: [34.10042611094802, -117.71002008183225],
  'Steele Hall': [34.10484995720829, -117.71210324868221],
  'Adams Hall': [32.76290904884907, -117.0662288298868],
  'Andrew Building': [34.0998138672686, -117.7139029825534],
  'Andrew Hall': [34.0998138672686, -117.7139029825534],
  'Andrew Science Hall': [34.0998138672686, -117.7139029825534],
  'Andrew Science Building': [34.0998138672686, -117.7139029825534],
  'Hahn Social Science Bldg': [34.098126371324604, -117.71603190000098],
  'Avery Hall': [34.10402559390241, -117.7059215470358],
  'Bauer Center': [34.10625935916369, -117.70861091635064],
  'Broad Center': [34.10494505589454, -117.70672332698616],
  'Bernard Hall': [34.10435220581155, -117.70632440303753],
  'Broad Hall': [34.10462192392436, -117.7058712693149],
  'Beckman Hall': [34.106128887593115, -117.71251107536439],
  'Balch Hall': [34.10345253373929, -117.71128438546123],
  Brackett: [34.09834700926052, -117.71012299276501],
  'Brackett Observatory': [34.09834700926052, -117.71012299276501],
  'Baxter Hall': [34.10466728950935, -117.71187640303745],
  'Biszantz Tennis Center': [34.10095490591795, -117.70630717402211],
  'Carnegie Building': [34.09813556541387, -117.7153908567008],
  Carnegie: [34.09813556541387, -117.7153908567008],
  'Carnegie Bldg': [34.09813556541387, -117.7153908567008],
  'Richardson Dance Studio': [34.10315111680064, -117.7099604470359],
  Edmunds: [34.0998600356232, -117.71319481917635],
  'Fletcher Hall': [34.104073077554126, -117.70639700470716],
  'Galileo Hall': [34.10625489220558, -117.71180151450122],
  'Honnold/Mudd Library': [34.10217613878248, -117.71228898240652],
  Honnold: [34.10217613878248, -117.71228898240652],
  'Claremont Colleges Library': [34.10217613878248, -117.71228898240652],
  'Mudd Library': [34.10217613878248, -117.71228898240652],
  'Humanities Building': [34.103735329211226, -117.71001297587144],
  'Jacobs Science Center': [34.105831090704534, -117.7117513875151],
  'Keck Laboratories': [34.09325008938014, -117.72347845495186],
  'Keck Graduate Institute': [34.09325008938014, -117.72347845495186],
  'Kravis Center': [34.10187820435941, -117.71108730125341],
  'The Kravis Center': [34.10187820435941, -117.71108730125341],
  'The Kravis Center LC': [34.10187820435941, -117.71108730125341],
  'Kravis Center LC': [34.10187820435941, -117.71108730125341],
  'Keck Science Center': [34.10308083314274, -117.70782900285776],
  'Bridges Hall of Music': [34.09741077681766, -117.71380091635093],
  'Lebus Court': [34.097133818486924, -117.7138019163509],
  'LeBus Court': [34.097133818486924, -117.7138019163509],
  Lincoln: [34.09436321343312, -117.71791431635106],
  'McGregor CompSci Center': [34.105735661459065, -117.71277261820012],
  'Mudd Science Library': [34.10235157034289, -117.71228630285779],
  'Oldenborg Center': [34.0972862770658, -117.71197834518651],
  Online: null,
  'Parsons Engineering Building': [34.106763408455166, -117.71201133169328],
  'Performing Arts Center': [34.103841861577266, -117.71263855867934],
  PAC: [34.103841861577266, -117.71263855867934],
  Pearsons: [34.09909136806275, -117.71531797402218],
  'Pearsons Hall': [34.09909136806275, -117.71531797402218],
  'Rains Center': [34.09962247726175, -117.7116976875152],
  'Rembrandt Hall': [34.09714703734707, -117.71436354518652],
  'Roberts North': [34.10243304031955, -117.71058173613733],
  'Roberts South': [34.1018600288952, -117.7105736895106],
  'Roberts Pavilion': [34.100334255091674, -117.70640981635093],
  'Robert Redford Conservcy': [34.11077818412942, -117.71047418566549],
  'Robert Redford Conservancy': [34.11077818412942, -117.71047418566549],
  'Smith Campus Center': [34.09962026169959, -117.71350964518648],
  'Seaver South Laboratory': [34.100042028976105, -117.71531142101722],
  'Seaver North Laboratory': [34.100042028976105, -117.71531142101722],
  'Seaman Hall': [34.10199315160771, -117.71010893169331],
  'Shanahan Center': [34.10656042476094, -117.71087576052881],
  'Drinkward Recital Hall': [34.10656042476094, -117.71087576052881],
  Stauffer: [34.102953598099226, -117.71334231635072],
  'Stauffer Hall': [34.102953598099226, -117.71334231635072],
  'RC Seaver Biology Bldg': [34.10023161864675, -117.71627928465743],
  'Seaver Biology Building': [34.10023161864675, -117.71627928465743],
  'Seaver Theater': [34.096747978139696, -117.7104344010084],
  'Seaver Theatre': [34.096747978139696, -117.7104344010084],
  'Thatcher Music Bldg': [34.09754703461027, -117.71440025414981],
  KSPC: [34.09754703461027, -117.71440025414981],
  'Thatcher Music Building': [34.09754703461027, -117.71440025414981],
  'Vita Nova Hall': [34.10300376863079, -117.70952371635069],
  'Scott Hall': [34.104311246807875, -117.70661596406609],
  TBD: null
};

export const claremontRegion: Region = {
  latitude: 34.1,
  longitude: -117.71,
  latitudeDelta: 0.015,
  longitudeDelta: 0.015
};

export const americaRegion: Region = {
  latitude: 30.116386,
  longitude: -97.299591,
  latitudeDelta: 80,
  longitudeDelta: 80
};

export function removeNumbersAndSpaces(name: string): string {
  let final = '';
  for (const char of name) {
    if (char.match(/[a-z]/i) || char === ' ') {
      final += char;
    }
  }
  return final.trim();
}
