import { apiSlice } from './apiSlice';

export const sponsorsApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    refreshSponsors: builder.query({
      query: (userKey) => ({
        url: '/sponsors',
        headers: {
          userKey
        }
      }),
      transformResponse: (sponsors: any[]) => {
        sponsors = sponsors.sort((a, b) => {
          const textA = a.sponsorName.toUpperCase();
          const textB = b.sponsorName.toUpperCase();
          return textA < textB ? 1 : textA > textB ? -1 : 0;
        });

        return [
          sponsors.find((sponsor) => sponsor.sponsorName === 'Big Mo\'s'),
          ...sponsors.filter((sponsor) => sponsor.sponsorName !== 'Big Mo\'s')
        ];
      }
    }),
    postSponsorVisit: builder.mutation({
      query: ({ userKey, sponsorId }) => ({
        url: '/analytics/sponsor/ping',
        method: 'POST',
        headers: {
          userKey
        },
        body: {
          sponsorId
        },
        responseHandler: (response) => response.text()
      })
    })
  })
});

export const { useLazyRefreshSponsorsQuery, usePostSponsorVisitMutation } = sponsorsApiSlice;
