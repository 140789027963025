import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from './store';
import { classPagesApi } from '../features/api/classPagesApi';
import { usersApiSlice } from '../features/api/usersApi';

// Define a type for the slice state
interface AppDataState {
  userKey: string;
  isGuest: boolean;
  isMobile: boolean;
  darkMode: boolean;
  useDeviceColorScheme: boolean;
  deviceExpoPushToken?: string;
  connectedToInternet?: boolean;
}

// Define the initial state using that type
const initialState: AppDataState = {
  userKey: '',
  isGuest: false,
  isMobile: false,
  darkMode: false,
  useDeviceColorScheme: false,
  deviceExpoPushToken: undefined,
  connectedToInternet: undefined
};

export const appDataSlice = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    reset: () => initialState,
    userKeyUpdated: (state, action: PayloadAction<string>) => {
      state.userKey = action.payload;
    },
    setIsGuest: (state, action: PayloadAction<boolean>) => {
      state.isGuest = action.payload;
    },
    deviceDarkModeSet: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
    },
    useDeviceColorSchemeSet: (state, action: PayloadAction<boolean>) => {
      state.useDeviceColorScheme = action.payload;
    },
    mobileSiteSet: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
    deviceExpoPushTokenSet: (state, action: PayloadAction<string>) => {
      state.deviceExpoPushToken = action.payload;
    },
    netInfoChecked: (state, action: PayloadAction<boolean>) => {
      state.connectedToInternet = action.payload;
    },
    deleteAllAppData: (state) => {
      state.userKey = '';
      state.isGuest = false;
      state.darkMode = false;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      classPagesApi.endpoints.addClass.matchRejected,
      (state, action) => {
        usersApiSlice.endpoints.getUserInfo.initiate(state.userKey);
      }
    );
  }
});

// Selectors
export const selectDarkMode = (state: RootState) =>
  state.appData.secure.darkMode;

export const selectUserKey = (state: RootState) => state.appData.secure.userKey;

export const selectIsGuest = (state: RootState) => state.appData.secure.isGuest;

export const selectMobile = (state: RootState) => state.appData.secure.isMobile;

// Actions
export const {
  userKeyUpdated,
  setIsGuest,
  deleteAllAppData,
  reset,
  deviceDarkModeSet,
  useDeviceColorSchemeSet,
  mobileSiteSet,
  deviceExpoPushTokenSet,
  netInfoChecked
} = appDataSlice.actions;

export default appDataSlice.reducer;
