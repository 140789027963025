import { createNativeStackNavigator } from '@react-navigation/native-stack';

import EnterCodePage from './EnterCodePage';
import EnterEmailPage from './EnterEmailPage';

const Stack = createNativeStackNavigator();

export default function ForgotPassword() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      <Stack.Screen name="EnterEmailPage" component={EnterEmailPage} />
      <Stack.Screen name="EnterCodePage" component={EnterCodePage} />
    </Stack.Navigator>
  );
}
