import { TouchableOpacity } from '@gorhom/bottom-sheet';
import { Button, Card, Layout, Modal, Text } from '@ui-kitten/components';
import React, { useState } from 'react';
import { Platform, ScrollView, StyleSheet } from 'react-native';

import { selectDarkMode } from '../../app/appDataSlice';
import { useAppSelector } from '../../app/hooks';
import ExportModalClass from '../classPages/SingleClassPage/exportModalClass';

export default function SingleClassPage({ selectedClass }) {
  const [showModal, setShowModal] = useState(false);
  const darkMode = useAppSelector(selectDarkMode);
  const filters = useAppSelector((state) => state.appData.classPages.filters);
  const backgroundColor = darkMode
    ? { backgroundColor: '#1F1F1F' }
    : { backgroundColor: '#F5F5F5' };

  function buildTimeString(time) {
    if (!time.startTime || time.startTime === '' || time.startTime === null) {
      return 'closed';
    }
    return `${convertTo12HourTime(time.startTime)} - ${convertTo12HourTime(
      time.endTime
    )}\n`;
  }

  function convertTo12HourTime(time) {
    if (time.length !== 5) {
      time = `0${time}`;
    }
    let hours = time.slice(0, 2);
    const minutes = time.slice(3, 5);
    let ampm = Number.parseInt(hours) > 12 ? 'pm' : 'am';
    hours %= 12;
    if (hours === 0) {
      hours = 12;
      ampm = 'pm';
    }
    return `${hours}:${minutes}${ampm}`;
  }

  const averageGradeMSGModal = (
    <Modal
      visible={showModal}
      backdropStyle={styles.backdrop}
      style={{ width: '75%' }}
      onBackdropPress={() => setShowModal(false)}
    >
      <Card disabled>
        <Text style={{ fontSize: 15, paddingTop: 5, paddingBottom: 10 }}>
          The Athena Network collects and aggregates the data anonymously
          submitted by the Course Review process and calculates what the average
          student's experience in this course will look like. Given that it is
          based on voluntarily submitted data, this information is to be used as
          a reference only. Please consult your advisor before making any
          impactful decisions.
        </Text>
        <Button onPress={() => setShowModal(false)}>Dismiss</Button>
      </Card>
    </Modal>
  );

  if (!selectedClass?.times) {
    return null;
  }

  return (
    <ScrollView style={backgroundColor}>
      <Layout style={[{ alignItems: 'center' }, backgroundColor]}>
        {Platform.OS === 'web' && (
          <ExportModalClass classData={selectedClass} />
        )}
        <Text style={styles.title}>{selectedClass.courseName}</Text>
        <Text style={{ paddingTop: 30 }}>{selectedClass.masterCourseCode}</Text>

        <Text style={styles.subtitle}>
          {`${selectedClass.courseTerm} \u2022 credits: ${
            filters.isHMC
              ? selectedClass.creditWeightHM
              : selectedClass.creditWeight
          }`}
        </Text>
        {selectedClass.timeSpentOutOfClass && (
          <Layout
            style={[
              {
                flexDirection: 'row',
                alignItems: 'flex-start',
                paddingRight: 5
              },
              backgroundColor
            ]}
          >
            <Text style={styles.subtitle}>Time Spent Per Week</Text>
            <Text style={{ fontSize: 12, lineHeight: 13 }}>i</Text>
            <Text style={styles.subtitle}>
              : {selectedClass.timeSpentOutOfClass} hours
            </Text>
          </Layout>
        )}
        {selectedClass.averageGrade && (
          <Layout
            style={[
              {
                flexDirection: 'row',
                alignItems: 'flex-start',
                paddingRight: 5
              },
              backgroundColor
            ]}
          >
            <Text style={styles.subtitle}>Average Grade</Text>
            <Text style={{ fontSize: 12, lineHeight: 13 }}>i</Text>
            <Text style={styles.subtitle}>:{selectedClass.averageGrade}</Text>
          </Layout>
        )}
        {selectedClass.difficulty && (
          <Layout
            style={[
              {
                flexDirection: 'row',
                alignItems: 'flex-start',
                paddingRight: 5
              },
              backgroundColor
            ]}
          >
            <Text style={styles.subtitle}>Estimated Difficulty</Text>
            <Text style={{ fontSize: 12, lineHeight: 13 }}>i</Text>
            <Text style={styles.subtitle}>
              : {selectedClass.difficulty} / 5
            </Text>
          </Layout>
        )}
        {selectedClass.rating && (
          <Layout
            style={[
              {
                flexDirection: 'row',
                alignItems: 'flex-start',
                paddingRight: 5
              },
              backgroundColor
            ]}
          >
            <Text style={styles.subtitle}>Average Rating</Text>
            <Text style={{ fontSize: 12, lineHeight: 13 }}>i</Text>
            <Text style={styles.subtitle}>:{selectedClass.rating} / 5</Text>
          </Layout>
        )}
      </Layout>
      <Layout style={backgroundColor}>
        <Text style={styles.courseDescription}>{selectedClass.summary}</Text>
      </Layout>
      <Layout style={{ ...backgroundColor, ...styles.boxed }}>
        <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
          Other Stated Prerequisites:
        </Text>
        <Text style={{ fontSize: 15 }}>
          {selectedClass.prerequisites !== ''
            ? selectedClass.prerequisites
            : 'none'}
        </Text>

        <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
          Other Stated Corequisites:
        </Text>
        <Text style={{ fontSize: 15 }}>
          {selectedClass.corequisites !== ''
            ? selectedClass.corequisites
            : 'none'}
        </Text>

        <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
          Number of PERMs:
        </Text>
        <Text style={{ fontSize: 15 }}>
          {selectedClass.permCount ? selectedClass.permCount.toString() : '0'}
        </Text>
      </Layout>
      <Layout style={[{ alignItems: 'flex-end' }, backgroundColor]}>
        {(selectedClass.timeSpentOutOfClass ||
          selectedClass.averageGrade ||
          selectedClass.difficulty ||
          selectedClass.rating) && (
          <TouchableOpacity onPress={() => setShowModal(!showModal)}>
            <Layout
              style={[
                {
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  paddingRight: 5
                },
                backgroundColor
              ]}
            >
              <Text style={{ fontSize: 12, lineHeight: 13 }}>i</Text>
              <Text style={styles.disclaimer}>How Do We Estimate This?</Text>
            </Layout>
          </TouchableOpacity>
        )}
      </Layout>
      <Layout style={backgroundColor}>
        {selectedClass.times.length > 0 && (
          <Layout style={backgroundColor}>
            <Text style={styles.subHeader}>Sections</Text>
            <Layout style={[styles.section, backgroundColor]}>
              <Text style={styles.headerText}>Course Code</Text>
              <Text style={styles.headerText}>Seats</Text>
              <Text style={styles.headerText}>Professor</Text>
              <Text style={styles.headerText}>Time</Text>
              <Text style={styles.headerText}>Location</Text>
            </Layout>
          </Layout>
        )}
        {selectedClass.times.flatMap((time) => {
          return time.courseSchedule.map((schedule) => {
            return (
              <Layout
                key={schedule.courseCode}
                style={{ backgroundColor, ...styles.section }}
              >
                <Text style={styles.sectionText}>{time.courseCode}</Text>
                <Text style={styles.sectionText}>
                  {time.seatsFilled} /{time.seatsFilled + time.seatsAvailable}
                </Text>
                <Text style={styles.sectionText}>{time.professorName}</Text>
                <Text style={styles.sectionText}>
                  {buildTimeString(time)}
                  {'\n'}
                  {schedule.daysOfTheWeek.join(', ')}
                </Text>
                <Text style={styles.sectionText}>
                  {schedule.classLocation ? time.classLocation : 'TBD'}
                </Text>
              </Layout>
            );
          });
        })}
        {averageGradeMSGModal}
      </Layout>
      <Text style={styles.subHeader}>Reviews</Text>
      {selectedClass.reviews.length === 0 ? (
        <Text style={{ alignSelf: 'center', marginBottom: 10 }}>
          No Reviews yet
        </Text>
      ) : (
        selectedClass.reviews.map((review, index) => (
          <Card key={index} disabled>
            <Text>{`${review.text}\n\nPosted: ${review.date}`}</Text>
            {review.professor && (
              <Text>{`Taught By: ${review.professor}`}</Text>
            )}
          </Card>
        ))
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  subtitle: {
    fontSize: 16
  },
  courseOpen: {
    backgroundColor: 'green',
    color: '#fff',
    padding: 5,
    marginRight: 10
  },
  courseClosed: {
    backgroundColor: 'red',
    color: '#fff',
    padding: 5,
    borderRadius: 5
  },
  header: {
    alignItems: 'center'
  },
  details: {
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  courseDescription: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 5,
    fontSize: 17
  },
  BodyContainer: {
    borderTopWidth: 1,
    borderTopColor: 'black',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    padding: 10,
    paddingTop: 20,
    paddingBottom: 20
  },
  boxed: {
    alignItems: 'center',
    margin: 8,
    borderColor: 'black',
    borderWidth: 1,
    padding: 7
  },
  documentStyle: {
    width: 150,
    height: 190,
    paddingTop: 100
  },
  imageLabel: {
    fontSize: 12,
    color: 'black',
    padding: 10,
    textAlign: 'center'
  },
  schedule: {
    paddingLeft: 20,
    paddingTop: 10
  },
  graphImage: {
    width: '100%',
    aspectRatio: 1,
    resizeMode: 'contain'
  },
  grade: {
    fontSize: 50,
    fontWeight: 'bold',
    color: 'green'
  },
  reviewText: {
    fontSize: 16,
    fontStyle: 'italic'
  },
  reviewBox: {
    width: '95%',
    padding: 10,
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 5,
    marginBottom: 10
  },
  section: {
    flexDirection: 'row',
    paddingLeft: 5,
    borderBottomWidth: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 13,
    flex: 1
  },
  sectionText: {
    flex: 1
  },
  disclaimer: {
    fontSize: 14,
    fontStyle: 'italic'
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  input: {
    height: 50,
    margin: 12,
    borderWidth: 1,
    padding: 10
  },
  containerDrop: {
    height: 42,
    margin: 12,
    borderWidth: 1
  },
  indentedInput: {
    height: 50,
    marginVertical: 12,
    borderWidth: 1,
    padding: 10,
    marginRight: 12,
    marginLeft: 30
  },
  text: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: 'white'
  },
  subHeader: {
    fontSize: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    paddingTop: 20,
    fontWeight: 'bold'
  }
});
