import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useTheme } from '@ui-kitten/components';

import ForgotPassword from './ForgotPassword/index';
import Login from './Login';
import Signup from './Signup/index';
import { selectDarkMode } from '../../app/appDataSlice';
import { useAppSelector } from '../../app/hooks';
import BackButton from '../../components/BackButton';

const Stack = createNativeStackNavigator();

export default function Auth() {
  const theme = useTheme();
  const darkMode = useAppSelector(selectDarkMode);

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: darkMode ? theme['color-basic-800'] : '#fff'
        }
      }}
    >
      <Stack.Screen
        name="Login"
        component={Login}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Signup"
        component={Signup}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ForgotPassword"
        component={ForgotPassword}
        options={{ title: 'Reset Password' }}
      />
    </Stack.Navigator>
  );
}
