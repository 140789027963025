import { Autocomplete, AutocompleteItem } from '@ui-kitten/components';
import { useEffect, useState } from 'react';

import { selectDarkMode, selectUserKey } from '../app/appDataSlice';
import { useAppSelector } from '../app/hooks';
import { base_URL } from '../features/notifications/constants';

/**
 * Renders a dropdown with the names filtered as user types
 * @param setHasChosen set function of a useState which contains a boolean determining whether a user has been chosen
 * @param setRecipient set function of a useState which contains is either: 1. a string containing the userName of the user when one has been chosen, 2. null when a user has not been chosen
 * @constructor
 */
export default function UserNameSelectionDropDown({
  setHasChosen,
  setRecipient,
  placeholder
}) {
  const userKey = useAppSelector(selectUserKey);
  const darkMode = useAppSelector(selectDarkMode);
  const [names, setNames] = useState([]); // filtered list of names
  const [users, setUsers] = useState([]); // clean list of users
  const [value, setValue] = useState(''); // typed name
  const myStyles = {
    modal: {
      fontSize: 18,
      paddingBottom: 20,
      fontWeight: 'bold',
      textAlign: 'center',
      width: '100%',
      color: darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'
    },
    backdrop: {
      backgroundColor: darkMode ? 'rgba(141,141,141,0.5)' : 'rgba(0, 0, 0, 0.5)'
    },
    containerDrop: {
      minWidth: 200,
      paddingBottom: 15
    }
  };

  useEffect(() => {
    fetch(`${base_URL}/users/userNamesAndNames`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        userKey
      }
    }).then(async (data) => {
      if (data.ok) {
        const userList = await data.json();
        setNames(
          userList.map((user) => {
            if (user) {
              return `${user.realName} (${user.userName})`;
            }
          })
        );
        setUsers(userList);
      }
    });
  }, []);

  function renderEachDropItem() {
    const final = [];
    for (const option in names) {
      final.push(<AutocompleteItem title={names[option]} key={option} />);
    }
    return final;
  }

  const onChangeText = (query) => {
    setNames(
      freshNames().filter(
        (name) =>
          name?.toLowerCase().includes(query.toLowerCase()) ||
          userNameContainsString(name, query)
      )
    );
    setValue(query);
  };

  function freshNames() {
    return users.map((user) => {
      if (user) {
        return `${user.realName} (${user.userName})`;
      }
    });
  }

  const userNameContainsString = (name, query) => {
    const user = users.find(
      (auser) =>
        auser?.userName.toLowerCase().includes(query.toLowerCase()) &&
        auser?.realName === name
    );
    return user !== undefined;
  };

  function handleSelection(index) {
    if (index === 0) {
      setHasChosen(false);
      setRecipient(null);
      // @ts-ignore
      setValue('');
    } else {
      setHasChosen(true);
      setValue(names[index - 1]);
      setRecipient(findUserNameFromName(names[index - 1]));
    }
  }

  function findUserNameFromName(name) {
    const firstPar = name.indexOf('(');
    const secondPar = name.indexOf(')');
    return name.slice(firstPar + 1, secondPar);
  }

  return (
    <Autocomplete
      style={myStyles.containerDrop}
      value={value}
      placeholder={placeholder ? 'Recipient' : null}
      onChangeText={onChangeText}
      onSelect={handleSelection}
    >
      <AutocompleteItem title={' '} />
      {renderEachDropItem()}
    </Autocomplete>
  );
}
