import { Class } from '../../app/types';

export default class CalendarFunctions {
  convertTo12HourTime(time) {
    if (time.length !== 5) {
      time = `0${time}`;
    }
    let hours = time.slice(0, 2);
    const minutes = time.slice(3, 5);
    let ampm = Number.parseInt(hours) > 12 ? 'pm' : 'am';
    hours %= 12;
    if (hours === 0) {
      hours = 12;
      ampm = 'pm';
    }
    return `${hours}:${minutes}${ampm}`;
  }

  getColor(string: string) {
    let final = 0;
    for (let charIndex = 0; charIndex < string.length; charIndex++) {
      final += string.charCodeAt(charIndex);
    }
    final -= Number(final.toString()[0]) * 10 ** (final.toString().length - 1);
    final /= 10 ** final.toString().length;
    return `hsl(${180 * final + 90},${30 + 65 * final}%,${50 + 15 * final}%)`;
  }

  stringToColour(str: string) {
    return this.getColor(str);
  }

  getMinutesDiff(startTime, endTime, minuteHeight) {
    if (endTime > startTime) {
      return (endTime - startTime) * minuteHeight;
    }
    if (startTime === endTime) {
      return 0;
    }
    return (startTime - endTime) * -minuteHeight;
  }

  getFullClassData(courseCode: string, allClassData: any): Class {
    const APIdata = allClassData;
    const generalCourseCode = courseCode.split('-').slice(0, -1).join('-');
    return APIdata[generalCourseCode];
  }
}
