import { Button, Layout, Text } from '@ui-kitten/components';

export default function EnterCodePage({ navigation }) {
  return (
    <Layout style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Text
        style={{
          margin: 10
        }}
        category="s1"
      >
        An email has been sent to you with a temporary password. Login to set
        your new password.
      </Text>
      <Button
        style={{ margin: 10 }}
        onPress={() => {
          navigation.reset({ index: 0, routes: [{ name: 'Login' }] });
        }}
      >
        Back to Login
      </Button>
    </Layout>
  );
}
