import { Image, View } from 'react-native';

export default function WebSplashScreen() {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Image
        source={require('../../assets/logos/splash-logo.png')}
        style={{ width: 300, height: 300 }}
      />
    </View>
  );
}
